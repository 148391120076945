/** @format */

import { useEffect, useRef, useState } from "react";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import { formatInvoiceMoney } from "../../../utils/price/formatMoney";
import { approveReferral, fetchReferrals } from "../api/referralsApi";
import ReferralsTable from "../../../tables/referralsTable";
import { Referral } from "../../../models/referral";
import Results from "../../../models/results";
import { useNotification } from "../../../utils/notification/notificationContext";
import { useSort } from "../../../hooks/useSort";
import LoadingWheel from "../../../components/loadingWheel";
import { useClient } from "../../../utils/client/clientContext";
import { UserIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { ClientFilterPopup } from "../clients/filters/clientFilter";
import { useSearchParams } from "react-router-dom";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import ClientDrawer from "../clients/clientDrawer";

export const initialAdminReferralsColumnOrder = [
    {
        key: "credit_approved",
        label: "Credit Status",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "created_at",
        label: "Referral Date",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "referred_email",
        label: "Referred Email",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "referred_name",
        label: "Referred Name",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "referred_by",
        label: "Referred By",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "credit_amount",
        label: "Credit Amount",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "actions",
        label: "Actions",
        isSortable: false,
        className: "sm:min-w-24 min-w-24 text-right",
    },
];

interface ReferralCards {
    total_approved_credits: number;
    total_pending_credits: number;
    total_referrals: number;
}

function AdminReferralsPage() {
    const { showNotification } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [referrals, setReferrals] = useState<Referral[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [referralCards, setReferralCards] = useState<ReferralCards | null>(
        null
    );

    const [loading, setLoading] = useState(false);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const fetchData = async () => {
        const data = await fetchReferrals(user_id, searchParams, sortConfig);
        if (data.status === "success") {
            setReferrals(data.data.referrals);
            setReferralCards(data.data.referral_cards);
            setResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [refetchData, user_id]);

    const handleApprove = async (referral_id: string) => {
        const data = await approveReferral(referral_id);
        if (data.status === "success") {
            handleRefetch();
        } else {
            showNotification(
                "Failed approving referral",
                data.message,
                "error"
            );
        }
    };

    const clientFilterRef = useRef<HTMLButtonElement>(null);

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const toggleFilter = () => {
        setIsFilterOpen((prevState) => !prevState);
    };

    const [clientDrawerOpen, setClientDrawerOpen] = useState(false);

    const stats = [
        { name: "Total Referrals", stat: referralCards?.total_referrals },
        {
            name: "Total Approved Credits",
            stat: formatInvoiceMoney(referralCards?.total_approved_credits),
        },
        {
            name: "Total Pending Credits",
            stat: formatInvoiceMoney(referralCards?.total_pending_credits),
        },
    ];

    return (
        <div className="flex flex-row">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header />

                <span className="text-2xl font-bold">Referrals</span>

                <div className="flex flex-row items-center space-x-4 pt-5 pb-2">
                    <div className="relative">
                        <button
                            ref={clientFilterRef}
                            onClick={toggleFilter}
                            className="text-xs text-gray-600 hover:bg-gray-50 flex flex-row items-center rounded-md shadow-sm border border-gray-300 px-2 h-7 divide-x gap-x-2"
                        >
                            <div className="flex flex-row items-center space-x-2">
                                {!user_id ? (
                                    <PencilSquareIcon className="w-4 h-4" />
                                ) : (
                                    <XCircleIcon
                                        className="w-4 h-4 text-red-500 hover:text-red-400"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            searchParams.delete("user_id");
                                            window.history.replaceState(
                                                null,
                                                "",
                                                `?${searchParams.toString()}`
                                            );
                                            setUserId(null);
                                        }}
                                    />
                                )}
                                <span>Client ID</span>
                            </div>
                            {user_id && (
                                <span className="font-medium text-accent pl-2">
                                    {user_id} -{" "}
                                    <span className="text-gray-600">
                                        {client &&
                                            client.first_name +
                                                " " +
                                                client.last_name}
                                    </span>
                                </span>
                            )}
                        </button>
                        <ClientFilterPopup
                            isOpen={isFilterOpen}
                            onClose={() => setIsFilterOpen(false)}
                            taxButtonRef={clientFilterRef}
                            user_id={user_id}
                            setUserId={setUserId}
                        />
                    </div>
                    {user_id && (
                        <button
                            onClick={() => setClientDrawerOpen(true)}
                            className="flex h-7 flex-row items-center text-xs px-2 border border-gray-300 shadow-sm rounded-md text-black"
                        >
                            <UserIcon className="w-4 h-4" />{" "}
                            <span className="pl-1.5">View client</span>
                        </button>
                    )}
                </div>

                {!loading ? (
                    <>
                        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3 pb-10">
                            {stats.map((item) => (
                                <div
                                    key={item.name}
                                    className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                                >
                                    <dt className="truncate text-sm font-medium text-gray-500">
                                        {item.name}
                                    </dt>
                                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                        {item.stat}
                                    </dd>
                                </div>
                            ))}
                        </dl>

                        <ReferralsTable
                            referrals={referrals}
                            results={results}
                            sortLoading={sortLoading}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            handleRefetch={handleRefetch}
                            emptyTableText={"No records found for this filter."}
                            columnOrder={initialAdminReferralsColumnOrder}
                            handleApprove={handleApprove}
                        />
                    </>
                ) : (
                    <div className="flex flex-grow items-center pt-44 justify-center">
                        <LoadingWheel />
                    </div>
                )}
            </div>

            <ClientDrawer
                user_id={user_id || ""}
                open={clientDrawerOpen}
                setOpen={setClientDrawerOpen}
                client={client}
                setClient={setClient}
            />
        </div>
    );
}

export default AdminReferralsPage;
