/** @format */

import { useState } from "react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import useScanDetection from "../../utils/useScanDetection";
import { useNotification } from "../../utils/notification/notificationContext";
import { sendContactRequest } from "../../pages/website/api/contactApi";
import LoadingWheel from "../loadingWheel";

const navigation = {
    company: [
        { name: "Home", href: "#" },
        { name: "Contact", href: "/contact" },
        { name: "Terms and Conditions", href: "/terms-and-conditions" },
    ],
    services: [
        { name: "Pricing", href: "/pricing" },
        { name: "Order Fulfillment", href: "/order-fulfillment" },
    ],
    social: [
        {
            name: "Instagram",
            href: "https://www.instagram.com/urva/",
            icon: FaInstagram,
        },
        {
            name: "LinkedIn",
            href: "https://www.linkedin.com/company/urvafreight",
            icon: FaLinkedin,
        },
        {
            name: "Twitter",
            href: "https://x.com/urvafreight",
            icon: FaXTwitter,
        },
    ],
};

export default function Footer() {
    const currentYear = new Date().getFullYear(); // Get the current year
    const { showNotification } = useNotification();

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleContact = async () => {
        setLoading(true);
        if (email === "") return;

        const data = await sendContactRequest("", "", email, "", "", "");
        if (data.status === "success") {
            showNotification("Succesfully sent contact request", "", "success");
            setEmail("");
        } else {
            showNotification(
                "Failed sending contact request. Try again.",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };
    return (
        <footer aria-labelledby="footer-heading" className="bg-white pt-12">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="w-full h-px bg-gray-200"></div>
            <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="space-y-8">
                        <p className="text-3xl font-semibold">URVA</p>
                        <p className="text-sm leading-6 text-gray-600">
                            Let us handle your operations, so you can focus on
                            growing your business.
                        </p>
                        <p className="text-sm leading-6 text-gray-600">
                            <span className="font-semibold">
                                Delaware Warehouse | 100% Sales Tax Free
                            </span>
                            <br></br>
                            Bear, Delaware
                            <br></br>
                            contact@urvafreight.com
                        </p>
                    </div>
                    <div className="mt-16 grid grid-cols-3 gap-8 xl:col-span-2 xl:mt-0">
                        <div className="xl:mx-auto ml-0">
                            <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                Follow Us
                            </h3>
                            {/* <div className="flex space-x-6 pt-6"> */}
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-y-4 sm:gap-x-6 pt-6">
                                {navigation.social.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="text-gray-400 hover:text-gray-500"
                                    >
                                        <span className="sr-only">
                                            {item.name}
                                        </span>
                                        <item.icon
                                            aria-hidden="true"
                                            className="h-6 w-6"
                                        />
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="xl:mx-auto ml-0">
                            <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                Company
                            </h3>
                            <ul role="list" className="mt-6 space-y-4">
                                {navigation.company.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="xl:mx-auto ml-0">
                            <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                Services
                            </h3>
                            <ul role="list" className="mt-6 space-y-4">
                                {navigation.services.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">
                    <div>
                        <h3 className="text-sm/6 font-semibold text-gray-900">
                            Request more information
                        </h3>
                        <p className="mt-2 text-sm/6 text-gray-600">
                            Discover how our services can support and grow your
                            business.
                        </p>
                    </div>
                    <div className="mt-6 sm:flex sm:max-w-md lg:mt-0">
                        <label htmlFor="email-address" className="sr-only">
                            Email address
                        </label>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            id="email-address"
                            name="email-address"
                            type="email"
                            required
                            placeholder="Enter your email"
                            autoComplete="email"
                            className="w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:w-56 sm:text-sm/6"
                        />
                        <div className="mt-4 sm:ml-4 sm:mt-0 sm:shrink-0">
                            <button
                                onClick={() => handleContact()}
                                className="flex w-full items-center justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                            >
                                {loading ? (
                                    <LoadingWheel color="white" />
                                ) : (
                                    <>Learn More</>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mt-8 border-t border-gray-900/10 pt-8">
                    <p className="text-xs leading-5 text-gray-500">
                        &copy; {currentYear} URVA Fulfillment, Inc. All rights
                        reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
}
