/** @format */

import { useEffect, useRef, useState } from "react";
import {
    deleteReceivedOrders,
    fetchClientReceivedOrders,
    updateReceivedOrderStatus,
} from "../api/receivedApi";
import { Order, ReceivedOrder } from "../../../models/order";
import Results from "../../../models/results";
import { getTableHeaders } from "../../../tables/ordersTable";
import { useSort } from "../../../hooks/useSort";
import { useClient } from "../../../utils/client/clientContext";
import { useSearchParams } from "react-router-dom";
import ReceivedOrdersTable, {
    initialReceivedOrdersColumnOrder,
} from "../../../tables/receivedOrdersTable";
import { Column } from "../../../tables/models";
import SmallSelector from "../../../components/dropdowns/smallSelector";
import { orderTypes } from "../../../models/data";
import { PencilSquareIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { ClientFilterPopup } from "../clients/filters/clientFilter";
import ColumnOrderDropdown from "../../../tables/columnDragPopup";
import { UserIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../components/loadingWheel";
import {
    ActionButton,
    DeleteButton,
    SecondaryButton,
} from "../../../components/buttons";
import { useNotification } from "../../../utils/notification/notificationContext";
import EditReceivedOrderModal from "./editReceivedOrderModal";
import AdminSearchBar from "../../../components/admin/searchBar";
import ClientDrawer from "../clients/clientDrawer";
import ConfirmDeleteOrderModal from "./confirmDeleteModal";

function ReceivedOrders({ selectedTab }: { selectedTab: string }) {
    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const [orders, setOrders] = useState<ReceivedOrder[]>([]);
    const [results, setResults] = useState<Results | null>(null);

    const [loading, setLoading] = useState(false);

    const [selectedOrders, setSelectedOrders] = useState<ReceivedOrder[]>([]);
    useEffect(() => {
        setSelectedOrders([]);
    }, [selectedTab]);

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };

    const fetchData = async () => {
        const data = await fetchClientReceivedOrders(
            user_id,
            searchParams.get("offset") ? Number(searchParams.get("offset")) : 0,
            searchParams.get("limit") ? Number(searchParams.get("limit")) : -1,
            sortConfig,
            selectedTab
        );
        if (data.status === "success") {
            console.log(data.data.received_orders);
            setOrders(data.data.received_orders);
            setResults(data.data.results);
        } else {
        }
        setLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [user_id, sortConfig, refetchData, selectedTab]);

    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        initialReceivedOrdersColumnOrder
    );

    const clientFilterRef = useRef<HTMLButtonElement>(null);

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const toggleFilter = () => {
        setIsFilterOpen((prevState) => !prevState);
    };

    const [clientDrawerOpen, setClientDrawerOpen] = useState(false);

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[0]);

    const [updateLoading, setUpdateLoading] = useState(false);
    const handleUpdate = async (check_in: boolean) => {
        setUpdateLoading(true);
        const receivedOrderIds = selectedOrders.map((o) => o.received_order_id);
        const data = await updateReceivedOrderStatus(
            receivedOrderIds,
            check_in
        );
        if (data.status === "success") {
            showNotification(data.message, "", "success");
            setSelectedOrders([]);
            handleRefetch();
        } else {
            showNotification(
                "Failed to delete orders.",
                data.message,
                "success"
            );
        }
        setUpdateLoading(false);
    };

    const [selectedOrder, setSelectedOrder] = useState<ReceivedOrder | null>(
        null
    );

    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");

    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);

    const [openConfirmDeleteOrderModal, setOpenConfirmDeleteOrderModal] =
        useState(false);

    return (
        <div>
            <div className="flex flex-row items-center space-x-4 justify-between pt-4 pb-2">
                <div className="flex flex-row items-center space-x-4">
                    <div className="relative">
                        <button
                            ref={clientFilterRef}
                            onClick={toggleFilter}
                            className="text-xs text-gray-600 hover:bg-gray-50 flex flex-row items-center rounded-md shadow-sm border border-gray-300 px-2 h-7 divide-x gap-x-2"
                        >
                            <div className="flex flex-row items-center space-x-2">
                                {!user_id ? (
                                    <PencilSquareIcon className="w-4 h-4" />
                                ) : (
                                    <XCircleIcon
                                        className="w-4 h-4 text-red-500 hover:text-red-400"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            searchParams.delete("user_id");
                                            window.history.replaceState(
                                                null,
                                                "",
                                                `?${searchParams.toString()}`
                                            );
                                            setUserId(null);
                                        }}
                                    />
                                )}
                                <span>Client ID</span>
                            </div>
                            {user_id && (
                                <span className="font-medium text-accent pl-2">
                                    {user_id} -{" "}
                                    <span className="text-gray-600">
                                        {client &&
                                            client.first_name +
                                                " " +
                                                client.last_name}
                                    </span>
                                </span>
                            )}
                        </button>
                        <ClientFilterPopup
                            isOpen={isFilterOpen}
                            onClose={() => setIsFilterOpen(false)}
                            taxButtonRef={clientFilterRef}
                            user_id={user_id}
                            setUserId={setUserId}
                        />
                    </div>
                    {user_id && (
                        <button
                            onClick={() => setClientDrawerOpen(true)}
                            className="flex h-7 flex-row items-center text-xs px-2 border border-gray-300 shadow-sm rounded-md text-black"
                        >
                            <UserIcon className="w-4 h-4" />{" "}
                            <span className="pl-1.5">View client</span>
                        </button>
                    )}
                    <ColumnOrderDropdown
                        columnOrder={unitsColumnOrder}
                        setColumnOrder={setUnitsColumnOrder}
                        localStorageKey="receivedOrdersColumnOrder"
                        selectedOrderType={selectedOrderType}
                        initialColumnOrder={initialReceivedOrdersColumnOrder}
                    />
                </div>
                <div className="flex flex-row items-center space-x-2 divide-x divide-gray-200">
                    {totalSum !== "" && (
                        <span className="text-gray-700 text-sm">
                            Sum: {totalSum}
                        </span>
                    )}
                    {selectedOrders && selectedOrders?.length > 0 && (
                        <span className="text-gray-700 text-sm pl-2">
                            {selectedOrders.length} shipment
                            {selectedOrders.length > 1 && "s"} selected
                        </span>
                    )}
                    {selectedOrders.length > 0 && (
                        <span className="pl-2">
                            <DeleteButton
                                label="Delete"
                                handleClick={() =>
                                    setOpenConfirmDeleteOrderModal(true)
                                }
                                viewDisabled={selectedOrders.length < 1}
                            />
                        </span>
                    )}
                    {selectedOrders.length > 0 && (
                        <span className="pl-2">
                            <SecondaryButton
                                label="Bulk edit"
                                handleClick={() => setOpenBulkEditModal(true)}
                                viewDisabled={selectedOrders.length < 1}
                            />
                        </span>
                    )}
                    <span className="pl-2">
                        <ActionButton
                            label="Mark checked in"
                            handleClick={() => handleUpdate(true)}
                            viewDisabled={selectedOrders.length < 1}
                            loading={updateLoading}
                        />
                    </span>
                </div>
            </div>
            <AdminSearchBar
                setData={setOrders}
                setLoading={setLoading}
                handleRefetch={handleRefetch}
                page="received"
                sub_page={selectedTab}
                user_id={user_id}
                keywords={keywords}
                setKeywords={setKeywords}
                inputValue={inputValue}
                setInputValue={setInputValue}
                refetchSearchedData={false}
            />

            {!loading ? (
                <ReceivedOrdersTable
                    orders={orders}
                    setOrders={setOrders}
                    results={results}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                    sortLoading={sortLoading}
                    sortConfig={sortConfig}
                    toggleSort={toggleSort}
                    handleRefetch={() => {}}
                    setTotalSum={setTotalSum}
                    columnOrder={unitsColumnOrder}
                    hiddenHeaders={[]}
                    onRowClick={() => {}}
                    client_type="admin"
                />
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <LoadingWheel />
                </div>
            )}

            <EditReceivedOrderModal
                receivedOrder={selectedOrder}
                setOrders={setOrders}
                openEditReceivedOrderModal={openBulkEditModal}
                setOpenEditReceivedOrderModal={setOpenBulkEditModal}
                handleRefetch={handleRefetch}
                bulkEdit={true}
                selectedOrders={selectedOrders}
            />

            <ClientDrawer
                user_id={user_id || ""}
                open={clientDrawerOpen}
                setOpen={setClientDrawerOpen}
                client={client}
                setClient={setClient}
            />

            <ConfirmDeleteOrderModal
                openConfirmDeleteOrderModal={openConfirmDeleteOrderModal}
                setOpenConfirmDeleteOrderModal={setOpenConfirmDeleteOrderModal}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default ReceivedOrders;
