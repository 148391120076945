/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon, TrashIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../components/loadingWheel";
import { useEffect, useState } from "react";
import {
    addAdditionalCostToInvoice,
    addDiscountToInvoice,
    closeInvoicePeriod,
    deleteAdditionalCostToInvoice,
    deleteDiscountToInvoice,
    fetchAdditionalCostsForInvoice,
    fetchDiscountsForInvoice,
} from "../../api/billingApi";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { InputField } from "../../../../components/inputField";
import { setDatasets } from "react-chartjs-2/dist/utils";
import {
    AdditionalCost,
    AdditionalDiscount,
} from "../../../../models/invoices";
import { formatMoney } from "../../../../utils/price/formatMoney";

function DiscountsModal({
    openDiscountsModal,
    setOpenDiscountsModal,
    invoice_id,
    handleRefetch,
}: {
    openDiscountsModal: boolean;
    setOpenDiscountsModal: React.Dispatch<React.SetStateAction<boolean>>;
    invoice_id: string | undefined;
    handleRefetch: () => void;
}) {
    const { user_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState("");

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [total, setTotal] = useState("");

    const [discounts, setDiscounts] = useState<AdditionalDiscount[]>([]);

    const { showNotification } = useNotification();

    const handleAdd = async () => {
        setLoading(true);
        const data = await addDiscountToInvoice(
            invoice_id,
            title,
            description,
            total
        );
        if (data.status === "success") {
            setOpenDiscountsModal(false);
            showNotification("Added discount", "", "success");
            handleRefetch();
        } else {
            showNotification("Failed to add discount", data.message, "error");
        }
        setLoading(false);
    };

    const handleDelete = async (cost_id: string) => {
        setDeleteLoading(cost_id);
        const data = await deleteDiscountToInvoice(cost_id);
        if (data.status === "success") {
            setOpenDiscountsModal(false);
            showNotification("Deleted discount", "", "success");
            handleRefetch();
        } else {
            showNotification(
                "Failed to delete discount",
                data.message,
                "error"
            );
        }
        setDeleteLoading("");
    };

    const fetchDiscounts = async () => {
        setDataLoading(true);
        const data = await fetchDiscountsForInvoice(invoice_id);
        if (data.status === "success") {
            setDiscounts(data.data.discounts);
        } else {
            showNotification(
                "Failed to retrieve additional discounts",
                data.message,
                "error"
            );
        }
        setDataLoading(false);
    };

    useEffect(() => {
        if (openDiscountsModal) {
            fetchDiscounts();
        } else {
            setTitle("");
            setDescription("");
            setTotal("");
        }
    }, [openDiscountsModal]);

    return (
        <Dialog
            open={openDiscountsModal}
            onClose={() => setOpenDiscountsModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenDiscountsModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Manage discounts
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            <InputField
                                label="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder=""
                            />
                            <InputField
                                label="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder=""
                            />
                            <InputField
                                label="Total discount"
                                value={total}
                                onChange={(e) => setTotal(e.target.value)}
                                placeholder=""
                            />

                            <div className="pt-4"></div>
                            <span className="text-base font-semibold">
                                Current discounts
                            </span>
                            {!dataLoading ? (
                                <>
                                    {discounts.length > 0 ? (
                                        <>
                                            {discounts.map((item) => (
                                                <div className="flex flex-row items-center justify-between">
                                                    <div className="flex flex-col text-sm">
                                                        <span className="font-semibold">
                                                            {item.title}
                                                        </span>
                                                        <span>
                                                            {item.description}
                                                        </span>
                                                        <span>
                                                            {formatMoney(
                                                                item.discount
                                                            )}
                                                        </span>
                                                    </div>
                                                    <button
                                                        onClick={() =>
                                                            handleDelete(
                                                                String(item.id)
                                                            )
                                                        }
                                                    >
                                                        {deleteLoading !==
                                                        String(item.id) ? (
                                                            <TrashIcon className="w-6 h-6 text-red-500 hover:text-red-400 cursor-pointer" />
                                                        ) : (
                                                            <LoadingWheel />
                                                        )}
                                                    </button>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <span className="flex flex-col text-sm text-gray-600">
                                            No discounts added
                                        </span>
                                    )}
                                </>
                            ) : (
                                <div className="flex flex-grow items-center pt-10 justify-center">
                                    <LoadingWheel />
                                </div>
                            )}
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                disabled={loading}
                                onClick={() => handleAdd()}
                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                            >
                                {loading ? (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                ) : (
                                    <></>
                                )}
                                Add discount
                            </button>
                            <button
                                type="button"
                                onClick={() => setOpenDiscountsModal(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default DiscountsModal;
