/** @format */

"use client";

import { useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { requestFBAShipments } from "../api/inventoryApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import LoadingWheel from "../../../../components/loadingWheel";

interface ConfirmRequestShipmentsModalProps {
    openConfirmRequestShipmentsModal: boolean;
    setOpenConfirmRequestShipmentsModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

export default function ConfirmRequestShipmentsModal({
    openConfirmRequestShipmentsModal,
    setOpenConfirmRequestShipmentsModal,
}: ConfirmRequestShipmentsModalProps) {
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);
    const handleRequest = async () => {
        setLoading(true);
        const data = await requestFBAShipments();
        if (data.status === "success") {
            setOpenConfirmRequestShipmentsModal(false);
            showNotification("Requested FBA shipments.", "", "success");
        } else {
            showNotification(
                "Failed to request shipments.",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openConfirmRequestShipmentsModal}
            onClose={setOpenConfirmRequestShipmentsModal}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:size-10">
                                <CheckCircleIcon
                                    aria-hidden="true"
                                    className="size-6 text-green-600"
                                />
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle
                                    as="h3"
                                    className="text-base font-semibold text-gray-900"
                                >
                                    Request FBA Shipments
                                </DialogTitle>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Confirm you'd like the warehouse to
                                        package and ship out your current
                                        inventory.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                                onClick={() => handleRequest()}
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto items-center"
                            >
                                {loading && (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                )}
                                Confirm
                            </button>
                            <button
                                type="button"
                                data-autofocus
                                onClick={() =>
                                    setOpenConfirmRequestShipmentsModal(false)
                                }
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
