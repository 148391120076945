/** @format */

import Footer from "../../components/website/footer";
import Header from "../../components/website/header";

export default function TermsAndConditions() {
    return (
        <div className="w-screen">
            <Header />

            <div className="pt-32 flex flex-col items-center justify-center">
                <div className="w-full sm:px-0 px-5 sm:max-w-4xl text-left">
                    <h1 className="text-2xl font-bold">Terms and Conditions</h1>
                    <div className="pt-2">
                        <h3 className="text-lg font-semibold">
                            Account and Prep Fees
                        </h3>
                        <p className="">
                            Account and prep fees will be charged based on the
                            plan selected by the client. The client is
                            responsible for all shipping costs, either through
                            their Seller Central account or as direct charges
                            incurred by Urva Freight LLC. These include, but are
                            not limited to, fees for returns, hazardous material
                            shipments, or any additional shipments requested by
                            the client.
                        </p>
                    </div>
                    <div className="pt-5">
                        <h3 className="text-lg font-semibold">Billing</h3>
                        <p className="">
                            We will bill on a bi-weekly basis, every Friday, for
                            shipments of units that leave our warehouse.
                            Exceptions to billing policies may be made at Urva
                            Freight LLC's discretion, based on agreements with
                            individual clients
                        </p>
                        <p className=" pt-3">
                            Clients have 3 business days to resolve payment
                            issues. If payment is not made within this period,
                            prep services will be suspended, and a 10% late
                            payment fee will be applied. Invoices overdue by
                            more than 3 business days will incur a late payment
                            fee of 10% per month
                        </p>
                        <p className=" pt-3">
                            Urva Freight LLC reserves a lien on the goods
                            tendered by the client and any property in our
                            possession, custody, or control for any outstanding
                            charges, including but not limited to prep,
                            processing, storage, handling, and transportation
                            fees.
                        </p>
                        <p className=" pt-3">
                            If payment remains unpaid for 30 days after demand,
                            Urva Freight LLC may sell the goods through public
                            auction, private sale, or other reasonable means.
                            Proceeds will be applied to the outstanding balance,
                            and the client remains liable for any deficiency.
                        </p>
                    </div>
                    <div className="pt-5">
                        <h3 className="text-lg font-semibold">Disposal</h3>
                        <p className="">
                            Urva Freight LLC will handle the disposal of any
                            product upon the client's request at no additional
                            cost. Once a disposal request is submitted,
                            ownership of the product transfers to Urva Freight
                            LLC, and disposal will occur at our discretion.
                        </p>
                    </div>
                    <div className="pt-5">
                        <h3 className="text-lg font-semibold">
                            Processing Time
                        </h3>
                        <p className="">
                            Processing times may vary based on the inventory
                            received, but we strive to ensure a 24-hour
                            turnaround and at least one shipment per week.
                            During peak seasons, such as May-July and
                            September-December, processing times may be longer.
                            Our team observes several holidays, including New
                            Year's Day, Memorial Day, Independence Day, Labor
                            Day, Thanksgiving, and Christmas, with limited hours
                            or closures on Christmas Eve and New Year's Eve.
                        </p>
                    </div>
                    <div className="pt-5">
                        <h3 className="text-lg font-semibold">Liability</h3>
                        <p className="">
                            Urva Freight LLC's duty of care aligns with that of
                            a reasonably careful person under similar
                            circumstances. We are not liable for loss, damage,
                            or injury to goods unless resulting from a failure
                            to exercise reasonable care. This excludes losses
                            caused by:
                        </p>
                        <p className=" pt-3">
                            Acts of God, public authorities, strikes, weather,
                            equipment failures, and other uncontrollable events.
                            Fragile items damaged unless packed/unpacked by Urva
                            Freight LLC. Concealed damage or theft not resulting
                            from our negligence.
                        </p>
                        <p className=" pt-3">
                            Urva Freight LLC does not provide temperature- or
                            humidity-controlled storage unless agreed to in
                            writing. Losses due to temperature fluctuations or
                            perishable qualities are not our responsibility.
                        </p>
                        <p className=" pt-3">
                            In cases where Urva Freight LLC is legally liable,
                            compensation is limited to the cost of goods paid by
                            the client at purchase. We will not cover
                            shipment-level, box-level, or product-level errors
                            unless caused by our lack of care.
                        </p>
                        <p className=" pt-3">
                            LIMITATION OF LIABILITY: Urva Freight LLC is not
                            responsible for special, incidental, consequential,
                            statutory, or punitive damages, including but not
                            limited to loss of profits, income, or market value.
                        </p>
                    </div>
                    <div className="pt-5">
                        <h3 className="text-lg font-semibold">Damaged Goods</h3>
                        <p className="">
                            If goods arrive damaged, we will document the
                            condition upon receipt and notify the client. The
                            client then has 30 days to provide
                            instructions—whether to return, store, ship, or
                            dispose of the product—before any additional storage
                            fees are applied.
                        </p>
                        <p className=" pt-3">
                            The client is responsible for contacting the
                            retailer for returns or replacements. Labor fees for
                            returns or related actions will apply
                        </p>
                    </div>
                    <div className="pt-5">
                        <h3 className="text-lg font-semibold">
                            Unprocessable Items
                        </h3>
                        <p className="">
                            Items deemed unprocessable (e.g., damaged upon
                            arrival, retailer errors, unauthorized listings,
                            hazmat restrictions) must be resolved within 30
                            days. Storage fees and labor charges will apply
                            after this period.
                        </p>
                    </div>
                    <div className="pt-5">
                        <h3 className="text-lg font-semibold">Storage</h3>
                        <p className="">
                            Urva Freight LLC does offer long-term storage, and
                            the client agrees to all associated terms and fees
                            as outlined in their agreement.
                        </p>
                    </div>
                    <div className="pt-5 text-left">
                        <h3 className="text-lg font-semibold text-left">
                            Agreement to Terms
                        </h3>
                        <p className="">
                            By sending items to Urva Freight LLC, the client
                            acknowledges and agrees to all terms outlined in
                            this document.
                        </p>
                    </div>
                    <div className="text-left pb-20 items-left justify-start">
                        <p className="pt-5">
                            For any questions or further clarification, please
                            contact contact@urvafreight.com.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
