/** @format */
import { useState, useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../components/loadingWheel";
import { InputField } from "../../../../components/inputField";
import dayjs, { Dayjs } from "dayjs";
import { useNotification } from "../../../../utils/notification/notificationContext";
import Client from "../../../../models/clients";
import { updateAccountDetails } from "../../../client/settings/api/settingsApi";
import AccountData from "../../../../models/accountData";
import { updateClientData } from "../../api/clientsApi";
import { useParams } from "react-router-dom";
import { formatMoney } from "../../../../utils/price/formatMoney";

export function EditClientModal({
    client,
    openEditClientModal,
    setOpenEditClientModal,
    handleRefetch,
}: {
    client: Client;
    openEditClientModal: boolean;
    setOpenEditClientModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [amazonStoreName, setAmazonStoreName] = useState("");
    const [inventoryLabsEmail, setInventoryLabsEmail] = useState("");
    const [inventoryLabsPassword, setInventoryLabsPassword] = useState("");
    const [boxemEmail, setBoxemEmail] = useState("");
    const [boxemPassword, setBoxemPassword] = useState("");
    const [merchantUnitPrice, setMerchantUnitPrice] = useState("");
    const [amazonUnitPrice, setAmazonUnitPrice] = useState("");
    const [reshippingUnitPrice, setReshippingUnitPrice] = useState("");
    const [taxRate, setTaxRate] = useState("");
    const [maxShippingNames, setMaxShippingNames] = useState("");

    const [missingFields, setMissingFields] = useState(false);

    const canSubmit = () => {
        if (
            email === "" ||
            firstName === "" ||
            lastName === "" ||
            phone === ""
        ) {
            return false;
        }
        return true;
    };

    const handleUpdateUser = async () => {
        setLoading(true);
        const data = await updateClientData(
            client.user_id,
            email,
            firstName,
            lastName,
            phone,
            amazonStoreName,
            inventoryLabsEmail,
            inventoryLabsPassword,
            boxemEmail,
            boxemPassword,
            merchantUnitPrice,
            amazonUnitPrice,
            reshippingUnitPrice,
            taxRate,
            maxShippingNames
        );
        if (data.status === "success") {
            handleRefetch();
            setOpenEditClientModal(false);
            showNotification("Client details updated", "", "success");
        } else {
            showNotification("An error occurred", data.message, "error");
        }
        setLoading(false);
    };

    useEffect(() => {
        if (openEditClientModal) {
            setEmail(client?.email || "");
            setFirstName(client?.first_name || "");
            setLastName(client?.last_name || "");
            setPhone(client?.phone_number || "");
            setAmazonStoreName(client?.amazon_store_name || "");
            setInventoryLabsEmail(client?.inventory_labs_email || "");
            setInventoryLabsPassword(client?.inventory_labs_password || "");
            setBoxemEmail(client?.boxem_email || "");
            setBoxemPassword(client?.boxem_password || "");
            setMerchantUnitPrice(
                client?.merchant_unit_price
                    ? formatMoney(client?.merchant_unit_price) || ""
                    : ""
            );
            setAmazonUnitPrice(
                client?.amazon_unit_price
                    ? formatMoney(client?.amazon_unit_price) || ""
                    : ""
            );
            setReshippingUnitPrice(
                client?.reshipping_unit_price
                    ? formatMoney(client?.reshipping_unit_price) || ""
                    : ""
            );
            setTaxRate(client?.tax_rate || "");
            setMaxShippingNames(String(client?.max_shipping_names) || "");
        }
    }, [openEditClientModal]);

    return (
        <Dialog
            open={openEditClientModal}
            onClose={() => setOpenEditClientModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenEditClientModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Edit client
                            {missingFields && (
                                <span className="text-red-500 pl-5">
                                    Missing fields, fill required fields.
                                </span>
                            )}
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 flex-row flex space-x-10">
                            <div className="space-y-4 w-full">
                                <InputField
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder=""
                                />
                                <InputField
                                    label="First name"
                                    value={firstName}
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                    placeholder=""
                                />
                                <InputField
                                    label="Last name"
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                    placeholder=""
                                />
                                <InputField
                                    label="Phone number"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder=""
                                />
                                <InputField
                                    label="Amazon store name"
                                    value={amazonStoreName}
                                    onChange={(e) =>
                                        setAmazonStoreName(e.target.value)
                                    }
                                    placeholder=""
                                />
                                <InputField
                                    label="Merchant fulfilled unit price"
                                    value={merchantUnitPrice}
                                    onChange={(e) =>
                                        setMerchantUnitPrice(e.target.value)
                                    }
                                    placeholder=""
                                />
                                <InputField
                                    label="Reshipping fulfilled unit price"
                                    value={reshippingUnitPrice}
                                    onChange={(e) =>
                                        setReshippingUnitPrice(e.target.value)
                                    }
                                    placeholder=""
                                />
                            </div>
                            <div className="space-y-4 w-full">
                                <InputField
                                    label="Inventory labs email"
                                    value={inventoryLabsEmail}
                                    onChange={(e) =>
                                        setInventoryLabsEmail(e.target.value)
                                    }
                                    placeholder=""
                                />
                                <InputField
                                    label="Inventory labs pasword"
                                    value={inventoryLabsPassword}
                                    onChange={(e) =>
                                        setInventoryLabsPassword(e.target.value)
                                    }
                                    placeholder=""
                                />
                                <InputField
                                    label="Boxem email"
                                    value={boxemEmail}
                                    onChange={(e) =>
                                        setBoxemEmail(e.target.value)
                                    }
                                    placeholder=""
                                />
                                <InputField
                                    label="Boxem password"
                                    value={boxemPassword}
                                    onChange={(e) =>
                                        setBoxemPassword(e.target.value)
                                    }
                                    placeholder=""
                                />
                                <InputField
                                    label="Tax rate"
                                    value={taxRate}
                                    onChange={(e) => setTaxRate(e.target.value)}
                                    placeholder=""
                                />
                                <InputField
                                    label="Amazon fulfilled unit price"
                                    value={amazonUnitPrice}
                                    onChange={(e) =>
                                        setAmazonUnitPrice(e.target.value)
                                    }
                                    placeholder=""
                                />
                                <InputField
                                    label="Max shipping names"
                                    value={maxShippingNames}
                                    onChange={(e) =>
                                        setMaxShippingNames(e.target.value)
                                    }
                                    placeholder=""
                                />
                            </div>
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse justify-between">
                            <button
                                type="button"
                                onClick={
                                    canSubmit()
                                        ? () => handleUpdateUser()
                                        : () => setMissingFields(true)
                                }
                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                            >
                                {loading ? (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                ) : (
                                    <></>
                                )}
                                Save changes
                            </button>
                            <div className="space-x-2">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setOpenEditClientModal(false)
                                    }
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
