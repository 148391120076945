/** @format */
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { serviceOptions, ShipmentService } from "../../../../models/shipments";
import { ShipmentQuantities } from "../../../../models/order";

interface ServicesPopupProps {
    selectedServices: ShipmentService[];
    setSelectedServices: React.Dispatch<
        React.SetStateAction<ShipmentService[]>
    >;
}

export default function ServicesPopup({
    selectedServices,
    setSelectedServices,
}: ServicesPopupProps) {
    const toggleService = (service: ShipmentService) => {
        setSelectedServices((prevSelectedServices) => {
            const existingService = prevSelectedServices.find(
                (selected) => selected.name === service.name
            );

            if (existingService) {
                // Remove the service if already selected
                return prevSelectedServices.filter(
                    (selected) => selected.name !== service.name
                );
            } else {
                // Calculate total quantity
                const totalQuantity = prevSelectedServices.reduce(
                    (acc, svc) => acc + (svc.quantity || 0),
                    0
                );

                return [
                    ...prevSelectedServices,
                    { ...service, quantity: totalQuantity },
                ];
            }
        });
    };

    const handleServiceQuantityChange = (
        serviceName: string,
        quantity: number
    ) => {
        setSelectedServices((prevSelectedServices) =>
            prevSelectedServices.map((service) =>
                service.name === serviceName
                    ? { ...service, quantity }
                    : service
            )
        );
    };

    return (
        <div className="flex flex-col">
            <Listbox value={selectedServices} onChange={() => {}}>
                <div className="relative w-full pb-1">
                    <ListboxButton className="relative cursor-default flex flex-row pt-1">
                        <span className="font-medium text-sm">
                            Add services
                        </span>
                        <PlusCircleIcon className="w-6 h-6 text-accent cursor-pointer hover:text-accenthighlight ml-2" />
                    </ListboxButton>

                    <ListboxOptions
                        transition
                        className="absolute z-20 mt-1 max-h-60 w-72 overflow-auto rounded-md bg-white border border-gray-300 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                    >
                        {serviceOptions.map((option) => (
                            <ListboxOption
                                key={option.name}
                                value={option}
                                className="group relative cursor-default select-none py-2 pl-3 pr-2 text-gray-900"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div
                                    onClick={(e) => e.stopPropagation()}
                                    className="flex flex-row items-center space-x-2"
                                >
                                    <input
                                        id={option.label}
                                        type="checkbox"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleService(option);
                                        }}
                                        onChange={() => {}}
                                        checked={
                                            selectedServices &&
                                            selectedServices.some(
                                                (selected) =>
                                                    selected.name ===
                                                    option.name
                                            )
                                        }
                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0"
                                    />
                                    <span className="block truncate font-normal">
                                        {option.label}
                                    </span>
                                </div>
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                </div>
            </Listbox>
            <div className="space-y-2">
                {selectedServices &&
                    selectedServices.map((service) => (
                        <div
                            key={service.name}
                            className="flex flex-row items-center"
                        >
                            <span className="text-sm text-gray-800 min-w-64">
                                {service.label}
                            </span>
                            <input
                                type="number"
                                className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                min="1"
                                value={service.quantity || ""}
                                onChange={(e) =>
                                    handleServiceQuantityChange(
                                        service.name,
                                        parseInt(e.target.value, 10)
                                    )
                                }
                                onWheel={(e) => {
                                    const target = e.target as HTMLInputElement;
                                    target.blur();
                                    setTimeout(() => target.focus(), 0);
                                }}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
}
