import Cookies from "js-cookie";
import { ScannedBarcode } from "../../../models/scannedBarcode";
import { buildUrl } from "../../../utils/url/buildUrl";
import { SortConfig } from "../../../hooks/useSort";

export const fetchTrackings = async (
    user_id: string | undefined | null,
    offset: number,
    limit: number,
    type: string | undefined,
    sortConfig: SortConfig
) => {
    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/trackings`;
        const params = {
            user_id: user_id ? user_id : null,
            offset: String(offset),
            limit: limit && limit != 0 ? String(limit) : "-1",
            type: type ? type : 'inventory',
            sort_key: sortConfig ? sortConfig.key : "created_at",
            sort_order: sortConfig ? sortConfig.order : "desc"
        };

        const url = buildUrl(baseUrl, params);
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                }
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}
