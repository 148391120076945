import { SortConfig } from "../../../hooks/useSort";
import { apiRequest } from "../../../utils/api/apiRequest"
import { buildUrl } from "../../../utils/url/buildUrl";


export const fetchReferrals = async (user_id: string | null | undefined, searchParams: URLSearchParams, sortConfig: SortConfig) => {

    const baseUrl = `/admin/referrals`;
    const params = {
        user_id: user_id ? user_id : null,
        offset: searchParams.get('offset'),
        limit: searchParams.get('limit'),
        sort_key: sortConfig ? sortConfig.key : "created_at",
        sort_order: sortConfig ? sortConfig.order : "desc"
    };

    const url = buildUrl(baseUrl, params);
    
    return apiRequest(url, "GET")
}

export const approveReferral = async (referral_id: string) => {
    return apiRequest("/admin/referrals/approve", "POST", {referral_id})
}