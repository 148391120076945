/** @format */

import { useEffect, useState, useRef } from "react";

function useScrollTranslation() {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const [scrollOffset, setScrollOffset] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollContainerRef.current) {
                console.log("scrolled");
                setScrollOffset(scrollContainerRef.current.scrollLeft);
            }
        };

        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return { scrollContainerRef, scrollOffset };
}

export default useScrollTranslation;
