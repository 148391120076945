/** @format */

import { useState, useEffect } from "react";
import Header from "../../../components/header";
import DashboardCards from "./statistics/dashboardCards";
import {
    AdminDashboardModel,
    Invoices,
    AdminNotification,
} from "../../../models/adminDashboard";
import { fetchDashboardData } from "./api/dashboardApi";
import LoadingWheel from "../../../components/loadingWheel";
import AdminSideBar from "../../../components/admin/sideBar";
import { useNotification } from "../../../utils/notification/notificationContext";
import Client from "../../../models/clients";
import BillingStats, {
    dateValues,
    invoiceStatuses,
} from "./statistics/billingStats";
import { useClient } from "../../../utils/client/clientContext";

export default function AdminDashboardPage() {
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const [loading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] =
        useState<AdminDashboardModel | null>(null);

    const [topSpendClients, setTopSpendClients] = useState<Client[]>([]);
    const [invoicesData, setInvoicesData] = useState<Invoices | null>(null);
    const [notifications, setNotifications] = useState<AdminNotification[]>([]);

    const [selectedDate, setSelectedDate] = useState(dateValues[3]);

    const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState(
        invoiceStatuses[0]
    );

    const { showNotification } = useNotification();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchDashboardData(selectedDate.name);
            if (data.status === "success") {
                setDashboardData(data.data.cards);
                setTopSpendClients(data.data.top_spend_clients);
                setInvoicesData(data.data.invoices_data);
                setNotifications(data.data.notifications);
            } else {
                showNotification("Failed to fetch data", data.message, "error");
            }
            setLoading(false);
        };
        fetchData();
    }, [selectedDate]);

    return (
        <div className="flex flex-row">
            <AdminSideBar orderCount={orderCount} />
            <div className="flex flex-col flex-grow overflow-x-auto">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="sm:mx-10 mx-4 pt-10 flex flex-col">
                    <span className="text-2xl font-bold">Dashboard</span>

                    {!loading ? (
                        <div>
                            <DashboardCards dashboardData={dashboardData} />
                            {/* <span className="text-xl font-bold">Overview</span> */}

                            <BillingStats
                                topSpendClients={topSpendClients}
                                invoicesData={invoicesData}
                                notificationsData={notifications}
                                setNotificationsData={setNotifications}
                                selected={selectedDate}
                                setSelected={setSelectedDate}
                                selectedInvoiceStatus={selectedInvoiceStatus}
                                setSelectedInvoiceStatus={
                                    setSelectedInvoiceStatus
                                }
                            />
                        </div>
                    ) : (
                        <div className="flex flex-grow items-center pt-44 justify-center">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
