/** @format */

import { AdminDashboardModel } from "../../../../models/adminDashboard";
import { formatNumberWithCommas } from "../../../../utils/numbers/formatNumber";

export default function DashboardCards({
    dashboardData,
}: {
    dashboardData: AdminDashboardModel | null;
}) {
    return (
        <div className="pb-10">
            <div className="grid grid-cols-1 gap-px bg-gray-300 sm:grid-cols-2 md:grid-cols-4 mt-10">
                <div className="bg-white px-4 py-6 sm:px-6 lg:px-8">
                    <p className="text-sm font-medium leading-6 text-gray-600">
                        Total inbound units
                    </p>
                    <p className="mt-2 flex items-baseline gap-x-2">
                        <span className="text-4xl font-semibold tracking-tight text-black">
                            {formatNumberWithCommas(dashboardData?.inbound)}
                        </span>
                    </p>
                </div>
                <div className="bg-white px-4 py-6 sm:px-6 lg:px-8">
                    <p className="text-sm font-medium leading-6 text-gray-600">
                        Total inventory units
                    </p>
                    <p className="mt-2 flex items-baseline gap-x-2">
                        <span className="text-4xl font-semibold tracking-tight text-black">
                            {formatNumberWithCommas(dashboardData?.inventory)}
                        </span>
                    </p>
                </div>
                <div className="bg-white px-4 py-6 sm:px-6 lg:px-8">
                    <p className="text-sm font-medium leading-6 text-gray-600">
                        Total outbound units
                    </p>
                    <p className="mt-2 flex items-baseline gap-x-2">
                        <span className="text-4xl font-semibold tracking-tight text-black">
                            {formatNumberWithCommas(dashboardData?.outbound)}
                        </span>
                    </p>
                </div>
                <div className="bg-white px-4 py-6 sm:px-6 lg:px-8">
                    <p className="text-sm font-medium leading-6 text-gray-600">
                        Total shipments
                    </p>
                    <p className="mt-2 flex items-baseline gap-x-2">
                        <span className="text-4xl font-semibold tracking-tight text-black">
                            {formatNumberWithCommas(dashboardData?.shipments)}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}
