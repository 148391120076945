

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const validateInvitationToken = async (invitation_token: string) => {
    try {
        const response = await fetch(
            `${baseUrl}/api/v1/team/invitation/validate-token/` + invitation_token,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}

export const acceptInvitation = async (invitation_token: string, password: string) => {
    try {
        const response = await fetch(
            `${baseUrl}/api/v1/team/invitation/accept`,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({invitation_token, password})
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}