/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { InputField } from "../../../../components/inputField";
import LoadingWheel from "../../../../components/loadingWheel";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { createShippingName } from "../api/inboundApi";

interface AddShippingNameModalProps {
    openAddShippingNameModal: boolean;
    setOpenAddShippingNameModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}

function AddShippingNameModal({
    openAddShippingNameModal,
    setOpenAddShippingNameModal,
    handleRefetch,
}: AddShippingNameModalProps) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");

    const handleCreate = async () => {
        setLoading(true);
        const data = await createShippingName(name);
        if (data.status === "success") {
            setOpenAddShippingNameModal(false);
            handleRefetch();
            showNotification("Added new shipping name.", "", "success");
            setName("");
        } else {
            showNotification("Failed action.", data.message, "error");
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openAddShippingNameModal}
            onClose={() => setOpenAddShippingNameModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-2/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-2/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenAddShippingNameModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Add Shipping Name
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            <InputField
                                label="Shipping Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>

                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse">
                            <button
                                onClick={() => handleCreate()}
                                type="button"
                                disabled={name === ""}
                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                            >
                                {loading && (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                )}
                                Add Name
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default AddShippingNameModal;
