/** @format */

import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

interface TimeSelectorProps {
    selectedHour: string;
    setSelectedHour: React.Dispatch<React.SetStateAction<string>>;
    selectedMinute: string;
    setSelectedMinute: React.Dispatch<React.SetStateAction<string>>;
    selectedPeriod: string;
    setSelectedPeriod: React.Dispatch<React.SetStateAction<string>>;
}

export const formatSelectorTimeToUTC = (
    selectedHour: string,
    selectedMinute: string,
    selectedDate: Dayjs
): string => {
    if (selectedHour && selectedMinute && selectedDate) {
        // Get the selected date's year, month, and day
        const year = selectedDate.year();
        const month = selectedDate.month(); // 0-indexed in JavaScript Date
        const day = selectedDate.date();

        // Split the hour and period (AM/PM)
        const [hourValue, period] = selectedHour.split(" ");

        // Convert hour to 24-hour format
        let hour = parseInt(hourValue, 10);
        if (period === "PM" && hour < 12) {
            hour += 12; // Convert PM hour to 24-hour format
        } else if (period === "AM" && hour === 12) {
            hour = 0; // Convert 12 AM to 0 hours
        }

        // Create a new Date object with the selected date and time
        const customDate = new Date(
            year,
            month,
            day,
            hour,
            parseInt(selectedMinute, 10),
            0,
            0
        );

        // Convert to UTC string
        return customDate.toISOString(); // Returns the UTC date in ISO format
    }
    return "";
};

function TimeSelector({
    selectedHour,
    setSelectedHour,
    selectedMinute,
    setSelectedMinute,
    selectedPeriod,
    setSelectedPeriod,
}: TimeSelectorProps) {
    const fullHours = Array.from({ length: 12 }, (_, i) => {
        const hour = i + 1;
        return `${hour}`;
    });

    const minutes = Array.from({ length: 60 }, (_, i) =>
        String(i).padStart(2, "0")
    );

    const periods = ["AM", "PM"];

    useEffect(() => {
        const currentDate = new Date();
        let currentHour = currentDate.getHours(); // Get the hour in 24-hour format
        const currentMinute = String(currentDate.getMinutes()).padStart(2, "0");

        const period = currentHour >= 12 ? "PM" : "AM";
        currentHour = currentHour % 12 || 12;

        setSelectedHour(`${currentHour}`);
        setSelectedPeriod(period);
        setSelectedMinute(currentMinute);
    }, []);

    return (
        <div className="flex flex-row items-center space-x-2">
            <Listbox>
                <div className="relative">
                    <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                        <span className="block truncate text-sm">
                            {selectedHour}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-gray-400"
                            />
                        </span>
                    </ListboxButton>

                    <ListboxOptions
                        transition
                        className="absolute z-20 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                    >
                        <div className="flex flex-row items-start overflow-auto">
                            <div className="flex flex-col overflow-auto">
                                {fullHours.map((hour) => (
                                    <ListboxOption
                                        key={hour}
                                        value={hour}
                                        onClick={() => setSelectedHour(hour)}
                                        className={`group relative cursor-default select-none py-2 pl-4 pr-4 text-gray-900 hover:bg-accent hover:text-white transition-all ${
                                            selectedHour === hour &&
                                            "bg-accent text-white"
                                        }`}
                                    >
                                        <span className="block truncate font-normal text-sm">
                                            {hour}
                                        </span>
                                    </ListboxOption>
                                ))}
                            </div>
                        </div>
                    </ListboxOptions>
                </div>
            </Listbox>
            <Listbox>
                <div className="relative">
                    <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                        <span className="block truncate text-sm">
                            {selectedMinute}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-gray-400"
                            />
                        </span>
                    </ListboxButton>

                    <ListboxOptions
                        transition
                        className="absolute z-20 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                    >
                        <div className="flex flex-row items-start overflow-auto">
                            <div className="flex flex-col overflow-auto">
                                {minutes.map((minute) => (
                                    <ListboxOption
                                        key={minute}
                                        value={minute}
                                        onClick={() =>
                                            setSelectedMinute(minute)
                                        }
                                        className={`group relative cursor-default select-none py-2 pl-4 pr-4 text-gray-900 hover:bg-accent hover:text-white transition-all ${
                                            selectedMinute === minute &&
                                            "bg-accent text-white"
                                        }`}
                                    >
                                        <span className="block truncate font-normal">
                                            {minute}
                                        </span>
                                    </ListboxOption>
                                ))}
                            </div>
                        </div>
                    </ListboxOptions>
                </div>
            </Listbox>
            <Listbox>
                <div className="relative">
                    <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                        <span className="block truncate text-sm">
                            {selectedPeriod}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-gray-400"
                            />
                        </span>
                    </ListboxButton>

                    <ListboxOptions
                        transition
                        className="absolute z-20 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                    >
                        <div className="flex flex-row items-start overflow-auto">
                            <div className="flex flex-col overflow-auto">
                                {periods.map((period) => (
                                    <ListboxOption
                                        key={period}
                                        value={period}
                                        onClick={() =>
                                            setSelectedPeriod(period)
                                        }
                                        className={`group relative cursor-default select-none py-2 pl-4 pr-4 text-gray-900 hover:bg-accent hover:text-white transition-all ${
                                            selectedPeriod === period &&
                                            "bg-accent text-white"
                                        }`}
                                    >
                                        <span className="block truncate font-normal">
                                            {period}
                                        </span>
                                    </ListboxOption>
                                ))}
                            </div>
                        </div>
                    </ListboxOptions>
                </div>
            </Listbox>
        </div>
    );
}

export default TimeSelector;
