

export function formatMoney(amount: number | undefined) {
    if (amount !== undefined && amount !== null) {
        const dollars = (amount / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return `$${dollars}`;
    }
    if (amount === 0) {
        return `$0.00`;
    }
    return null;
}

export function formatInvoiceMoney(amount: number | undefined) {
    if (amount === 0) {
        return "$0.00";
    }
    if (amount && amount < 0) {
        const dollars = (amount*-1 / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return `-$${dollars}`;
    }
    if (amount) {
        const dollars = (amount / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return `$${dollars}`;
    }
    return null;
}

export function removeDollarSign(amount: string) {
    return amount.replace('$', '');
}
