/** @format */

import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import RequestQuote from "./requestQuote";

function FurtherContactModal({
    openFurtherContactModal,
    setOpenFurtherContactModal,
    email,
}: {
    openFurtherContactModal: boolean;
    setOpenFurtherContactModal: React.Dispatch<React.SetStateAction<boolean>>;
    email: string;
}) {
    return (
        <Dialog
            open={openFurtherContactModal}
            onClose={() => setOpenFurtherContactModal(false)}
            className="relative z-40"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-40 w-screen">
                <div className="h-[90%] sm:h-4/5 flex min-h-full justify-center p-2 text-center items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] sm:h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-1 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-3xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4 z-40">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenFurtherContactModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <div className="flex-1 overflow-y-auto sm:mt-3 mt-1 space-y-4">
                            <div className="sm:pb-0 pb-10">
                                <RequestQuote
                                    removeTopPadding={true}
                                    emailToPrefill={email}
                                    setOpenFurtherContactModal={
                                        setOpenFurtherContactModal
                                    }
                                />
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default FurtherContactModal;
