/** @format */

import { useRef, useState } from "react";

// /** @format */

// import { useState, useRef } from "react";

// interface SelectedCell {
//     rowIndex: number;
//     columnIndex: number;
//     cellValue: number;
// }

// const useCellSelection = (initialSelectedCells: SelectedCell[] = []) => {
//     const [selectedCells, setSelectedCells] =
//         useState<SelectedCell[]>(initialSelectedCells);
//     const [isDragging, setIsDragging] = useState<boolean>(false);
//     const dragStartRef = useRef<{
//         rowIndex: number;
//         columnIndex: number;
//     } | null>(null);

//     const handleSingleClick = (
//         rowIndex: number,
//         columnIndex: number,
//         cellValue: number
//     ) => {
//         setSelectedCells((prevSelectedCells) => {
//             const isCellSelected = prevSelectedCells.some(
//                 (cell) =>
//                     cell.rowIndex === rowIndex &&
//                     cell.columnIndex === columnIndex
//             );

//             // If the cell is already selected, remove it
//             if (isCellSelected) {
//                 return prevSelectedCells.filter(
//                     (cell) =>
//                         cell.rowIndex !== rowIndex ||
//                         cell.columnIndex !== columnIndex
//                 );
//             }

//             // Otherwise, select it
//             return [{ rowIndex, columnIndex, cellValue }];
//         });
//     };

//     const handleMouseDown = (
//         rowIndex: number,
//         columnIndex: number,
//         cellValue: number
//     ) => {
//         dragStartRef.current = { rowIndex, columnIndex };
//         setIsDragging(true);
//         setSelectedCells([{ rowIndex, columnIndex, cellValue }]);
//     };

//     const handleMouseEnter = (
//         rowIndex: number,
//         columnIndex: number,
//         cellValue: number,
//         inventoryOrders: any[]
//     ) => {
//         if (isDragging && dragStartRef.current) {
//             const startRowIndex = dragStartRef.current.rowIndex;
//             const startColumnIndex = dragStartRef.current.columnIndex;

//             const newSelectedCells: SelectedCell[] = [];
//             for (
//                 let i = Math.min(startRowIndex, rowIndex);
//                 i <= Math.max(startRowIndex, rowIndex);
//                 i++
//             ) {
//                 newSelectedCells.push({
//                     rowIndex: i,
//                     columnIndex: startColumnIndex,
//                     cellValue: inventoryOrders[i].quantity_remaining,
//                 });
//             }

//             setSelectedCells(newSelectedCells);
//         }
//     };

//     const handleMouseUp = () => {
//         setIsDragging(false);
//         dragStartRef.current = null;
//     };

//     const sumSelectedQuantities = selectedCells
//         .filter((cell) => cell.columnIndex === 3) // This is specific to column index 3, you can adjust it
//         .reduce((total, cell) => total + cell.cellValue, 0);

//     return {
//         selectedCells,
//         handleSingleClick,
//         handleMouseDown,
//         handleMouseEnter,
//         handleMouseUp,
//         sumSelectedQuantities,
//     };
// };

// export default useCellSelection;

interface SelectedCell {
    rowIndex: number;
    columnIndex: number;
    cellValue: number;
}

const useCellSelection = (
    initialSelectedCells: SelectedCell[] = [],
    getCellValue: (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => number, // Function to get cell value
    columnsToSum: number[] // List of column indexes to sum
) => {
    const [selectedCells, setSelectedCells] =
        useState<SelectedCell[]>(initialSelectedCells);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const dragStartRef = useRef<{
        rowIndex: number;
        columnIndex: number;
    } | null>(null);

    // Handle single-click selection
    const handleSingleClick = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const cellValue = getCellValue(rowIndex, columnIndex, data);

        setSelectedCells((prevSelectedCells) => {
            const isCellSelected = prevSelectedCells.some(
                (cell) =>
                    cell.rowIndex === rowIndex &&
                    cell.columnIndex === columnIndex
            );

            // If the cell is already selected, remove it
            if (isCellSelected) {
                return prevSelectedCells.filter(
                    (cell) =>
                        cell.rowIndex !== rowIndex ||
                        cell.columnIndex !== columnIndex
                );
            }

            // Otherwise, select it
            return [...prevSelectedCells, { rowIndex, columnIndex, cellValue }];
        });
    };

    // Handle mouse down to start dragging
    const handleMouseDown = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const cellValue = getCellValue(rowIndex, columnIndex, data);

        dragStartRef.current = { rowIndex, columnIndex };
        setIsDragging(true);
        setSelectedCells([{ rowIndex, columnIndex, cellValue }]);
    };

    // Handle mouse enter to select multiple cells during drag
    const handleMouseEnter = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        if (isDragging && dragStartRef.current) {
            const startRowIndex = dragStartRef.current.rowIndex;
            const startColumnIndex = dragStartRef.current.columnIndex;

            const newSelectedCells: SelectedCell[] = [];
            for (
                let i = Math.min(startRowIndex, rowIndex);
                i <= Math.max(startRowIndex, rowIndex);
                i++
            ) {
                newSelectedCells.push({
                    rowIndex: i,
                    columnIndex: startColumnIndex,
                    cellValue: getCellValue(i, startColumnIndex, data),
                });
            }

            setSelectedCells(newSelectedCells);
        }
    };

    // Handle mouse up to stop dragging
    const handleMouseUp = () => {
        setIsDragging(false);
        dragStartRef.current = null;
    };

    // Calculate the sum of the selected cells in the specified columns
    const sumSelectedQuantities = columnsToSum.map((columnIndex) => ({
        columnIndex,
        sum: selectedCells
            .filter((cell) => cell.columnIndex === columnIndex) // Sum only cells in this column
            .reduce((total, cell) => total + cell.cellValue, 0),
    }));

    return {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    };
};

export default useCellSelection;
