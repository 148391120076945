/** @format */
import { PlusIcon } from "@heroicons/react/20/solid";

export function EmptyInventoryTable() {
    return (
        <div className="text-center">
            <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
                className="mx-auto h-12 w-12 text-gray-400"
            >
                <path
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    strokeWidth={2}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
                No inventory units
            </h3>
            <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new inbound shipment.
            </p>
            <div className="mt-6">
                <a href="/inbound/units">
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight "
                    >
                        <PlusIcon
                            aria-hidden="true"
                            className="-ml-0.5 mr-1.5 h-5 w-5"
                        />
                        Go to inbound units
                    </button>
                </a>
            </div>
        </div>
    );
}

export function EmptyInboundTable({
    setOpenAddOrderModal,
}: {
    setOpenAddOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    return (
        <div className="text-center">
            <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
                className="mx-auto h-12 w-12 text-gray-400"
            >
                <path
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    strokeWidth={2}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
                No inbound units
            </h3>
            <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new inbound shipment.
            </p>
            <div className="mt-6">
                <button
                    onClick={() => setOpenAddOrderModal(true)}
                    type="button"
                    className="inline-flex items-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                >
                    <PlusIcon
                        aria-hidden="true"
                        className="-ml-0.5 mr-1.5 h-5 w-5"
                    />
                    New shipment
                </button>
            </div>
        </div>
    );
}

export function EmptyOutboundTable() {
    return (
        <div className="text-center">
            <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
                className="mx-auto h-12 w-12 text-gray-400"
            >
                <path
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    strokeWidth={2}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
                No outbound shipments
            </h3>
            <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new inbound shipment.
            </p>
            <div className="mt-6">
                <a href="/inbound/units">
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight "
                    >
                        <PlusIcon
                            aria-hidden="true"
                            className="-ml-0.5 mr-1.5 h-5 w-5"
                        />
                        Go to inbound units
                    </button>
                </a>
            </div>
        </div>
    );
}

export function EmptyTrackingsTable() {
    return (
        <div className="text-center">
            <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
                className="mx-auto h-12 w-12 text-gray-400"
            >
                <path
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    strokeWidth={2}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
                No trackings added
            </h3>
            <p className="mt-1 text-sm text-gray-500">
                You'll see the trackings that arrive to the warehouse here.
            </p>
        </div>
    );
}
