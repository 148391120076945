/** @format */

import { CheckIcon, ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

export default function CopyButton({ textToCopy }: { textToCopy: string }) {
    const [copied, setCopied] = useState(false);

    const copyUrl = () => {
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <button onClick={() => copyUrl()} className="px-2">
            {copied ? (
                <CheckIcon className="w-4 h-4 text-green-500" />
            ) : (
                <ClipboardDocumentIcon className="w-4 h-4" />
            )}
        </button>
    );
}

export function CopyWithTextButton({
    label,
    textToCopy,
}: {
    label: string;
    textToCopy: string;
}) {
    const [copied, setCopied] = useState(false);

    const copyUrl = () => {
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <button
            onClick={() => copyUrl()}
            className={`flex h-7 items-center gap-2 px-4 text-xs border border-gray-300 shadow-sm rounded-md text-black hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-accent whitespace-nowrap`}
        >
            {copied ? (
                <CheckIcon className="w-4 h-4 text-green-500" />
            ) : (
                <ClipboardDocumentIcon className="w-4 h-4" />
            )}
            {label}
        </button>
    );
}

export function SmallCopyButton({ textToCopy }: { textToCopy: string }) {
    const [copied, setCopied] = useState(false);

    const copyUrl = () => {
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <button onClick={() => copyUrl()} className="px-2">
            {copied ? (
                <CheckIcon className="w-4 h-4 text-green-500" />
            ) : (
                <ClipboardDocumentIcon className="w-4 h-4" />
            )}
        </button>
    );
}
