/** @format */

import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
} from "react";
import Client, { ClientOrderCount } from "../../models/clients";
import {
    fetchClientData,
    fetchTotalOrderCount,
} from "../../pages/admin/api/clientsApi";
import { useSearchParams } from "react-router-dom";
import { getAdminType } from "../adminTypes/actions";
import { fetchClientOrdersData } from "../../pages/client/api/clientApi";
import Cookies from "js-cookie";
import { getClientType } from "../clientTypes/actions";

interface ClientContextType {
    client: Client | null;
    setClient: React.Dispatch<React.SetStateAction<Client | null>>;
    clientLoading: boolean;
    user_id: string | null;
    setUserId: React.Dispatch<React.SetStateAction<string | null>>;
    orderCount: ClientOrderCount | null;
    handleOrderCountRefetch: () => void;
}

// Create the ClientContext
const ClientContext = createContext<ClientContextType | undefined>(undefined);

// Custom hook to use ClientContext
export const useClient = (): ClientContextType => {
    const context = useContext(ClientContext);
    if (!context) {
        throw new Error("useClient must be used within a ClientProvider");
    }
    return context;
};

// Props for the ClientProvider component
interface ClientProviderProps {
    children: ReactNode;
}

export const ClientProvider: React.FC<ClientProviderProps> = ({ children }) => {
    const isAdmin =
        ["owner", "employee"].includes(getClientType() || "") ||
        ["owner", "employee"].includes(getAdminType() || "");
    const [searchParams] = useSearchParams();

    const [client, setClient] = useState<Client | null>(null);
    const [clientLoading, setClientLoading] = useState(true);
    const [user_id, setUserId] = useState<string | null>(
        searchParams.get("user_id")
    );

    const [orderCount, setOrderCount] = useState<ClientOrderCount | null>(null);

    const [totalOrderCount, setTotalOrderCount] =
        useState<ClientOrderCount | null>(null);

    const [refetchData, setRefetchData] = useState(0);
    const handleOrderCountRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };

    // Fetch client data when user_id changes
    const fetchTotalData = async () => {
        try {
            const data = await fetchTotalOrderCount();
            if (data.status === "success") {
                console.log(data.data.orders_count);
                setOrderCount(data.data.orders_count);
            }
        } catch (error) {
            console.error("Failed to fetch client data:", error);
        }
    };

    const fetchData = async (user_id: string) => {
        try {
            const data = await fetchClientData(user_id);
            if (data.status === "success") {
                setClient(data.data.client_data);
                setOrderCount(data.data.orders_count);
            }
        } catch (error) {
            console.error("Failed to fetch client data:", error);
        } finally {
            setClientLoading(false);
        }
    };

    const fetchClientSideData = async () => {
        try {
            const data = await fetchClientOrdersData();
            if (data.status === "success") {
                setOrderCount(data.data.orders_count);
            }
        } catch (error) {
            console.error("Failed to fetch client data:", error);
        } finally {
            setClientLoading(false);
        }
    };

    useEffect(() => {
        if (isAdmin) {
            if (user_id && user_id !== "") {
                setOrderCount(null);
                setClientLoading(true);
                fetchData(user_id);
            } else {
                fetchTotalData();
            }
        } else if (Cookies.get("jwt")) {
            fetchClientSideData();
        }
    }, [user_id, refetchData]);

    // useEffect(() => {
    //     if (refetchData > 0) {
    //         if (isAdmin) {
    //             if (user_id && user_id !== "") {
    //                 setOrderCount(null);
    //                 setClientLoading(true);
    //                 fetchData(user_id);
    //             } else {
    //                 fetchTotalData();
    //             }
    //         } else if (Cookies.get("jwt")) {
    //             fetchClientSideData();
    //         }
    //     }
    // }, [refetchData]);

    // Check query param for user_id and set it
    useEffect(() => {
        const queryUserId = searchParams.get("user_id");
        if (queryUserId && queryUserId !== user_id) {
            setUserId(queryUserId);
        }
    }, [searchParams]);

    return (
        <ClientContext.Provider
            value={{
                client,
                setClient,
                clientLoading,
                user_id,
                setUserId,
                orderCount,
                handleOrderCountRefetch,
            }}
        >
            {children}
        </ClientContext.Provider>
    );
};
