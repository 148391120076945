/** @format */

import Client from "../../../../models/clients";
import { formatMoney } from "../../../../utils/price/formatMoney";
import { useState } from "react";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { capitalizeFirstLetter } from "../../../../utils/strings/string";
import { useNavigate } from "react-router-dom";
import { Invoices, AdminNotification } from "../../../../models/adminDashboard";
import { removeNotification } from "../api/dashboardApi";
import LoadingWheel from "../../../../components/loadingWheel";
import NotificationsModal from "../../../../components/modals/notificationsModal";
import { convertDateToEST } from "../../../../utils/date/date_formatter";
import { useNotification } from "../../../../utils/notification/notificationContext";
import SmallSelector from "../../../../components/dropdowns/smallSelector";
import { getAdminType } from "../../../../utils/adminTypes/actions";
import { useClient } from "../../../../utils/client/clientContext";

export const dateValues = [
    { id: 1, name: "Today" },
    { id: 2, name: "Last 4 weeks" },
    { id: 3, name: "Month to date" },
    { id: 4, name: "All time" },
];

export const invoiceStatuses = [
    { id: 1, name: "Paid", value: "paid" },
    { id: 2, name: "Unpaid", value: "unpaid" },
    { id: 3, name: "Open", value: "open" },
    { id: 4, name: "All", value: "all" },
];

function DateSelector({
    selected,
    setSelected,
}: {
    selected: {
        id: number;
        name: string;
    };
    setSelected: React.Dispatch<
        React.SetStateAction<{
            id: number;
            name: string;
        }>
    >;
}) {
    return (
        <Listbox value={selected} onChange={setSelected}>
            <div className="relative">
                <ListboxButton className="relative cursor-default rounded-md bg-white py-1 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6">
                    <span className="block truncate text-xs font-medium">
                        {selected.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                        />
                    </span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute right-0 z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-xs"
                >
                    {dateValues.map((value) => (
                        <ListboxOption
                            key={value.id}
                            value={value}
                            // className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-accent data-[focus]:text-white flex flex-row items-center"
                            className={({ active, selected }) =>
                                `group relative cursor-default select-none py-2 pl-3 pr-9
                            ${
                                active
                                    ? "bg-accent text-white"
                                    : selected
                                    ? "text-accent font-semibold"
                                    : "text-gray-900"
                            }
                            `
                            }
                        >
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-accent group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon
                                    aria-hidden="true"
                                    className="h-3 w-3"
                                />
                            </span>
                            <span className="block truncate font-normal group-data-[selected]:font-semibold group-data-[focus]:text-white">
                                {value.name}
                            </span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    );
}

function BillingStats({
    topSpendClients,
    invoicesData,
    notificationsData,
    setNotificationsData,
    selected,
    setSelected,
    selectedInvoiceStatus,
    setSelectedInvoiceStatus,
}: {
    topSpendClients: Client[];
    invoicesData: Invoices | null;
    notificationsData: AdminNotification[];
    setNotificationsData: React.Dispatch<
        React.SetStateAction<AdminNotification[]>
    >;
    selected: {
        id: number;
        name: string;
    };
    setSelected: React.Dispatch<
        React.SetStateAction<{
            id: number;
            name: string;
        }>
    >;
    selectedInvoiceStatus: {
        id: number;
        name: string;
        value: string;
    };
    setSelectedInvoiceStatus: React.Dispatch<
        React.SetStateAction<{
            id: number;
            name: string;
            value: string;
        }>
    >;
}) {
    const isOwner = getAdminType() === "owner";
    const { showNotification } = useNotification();
    const navigate = useNavigate();
    const invoices = [
        {
            label: "open",
            value: invoicesData?.open_invoices_count,
            color: "bg-gray-400",
        },
        {
            label: "closed",
            value: invoicesData?.closed_unpaid_invoices_count,
            color: "bg-yellow-400",
        },
        {
            label: "paid",
            value: invoicesData?.closed_paid_invoices_count,
            color: "bg-green-400",
        },
    ];

    const [deletingNotifications, setDeletingNotifications] = useState<
        string[]
    >([]);

    const [totalNotificationsCount, setTotalNotificationsCount] = useState(
        notificationsData.length > 0 ? notificationsData[0].total_count : 0
    );

    const handleRemove = async (
        e: React.MouseEvent<SVGSVGElement, MouseEvent>,
        notificationId: string
    ) => {
        e.stopPropagation();
        setDeletingNotifications((prev) => [...prev, notificationId]);
        const data = await removeNotification(notificationId);
        if (data.status === "success") {
            setNotificationsData((prevNotifications) =>
                prevNotifications.filter(
                    (notification) =>
                        notification.notification_id !== notificationId
                )
            );
            setTotalNotificationsCount((prev) => prev - 1);
        }
    };

    const [deleteAllLoading, setDeleteAllLoading] = useState(false);
    const handleRemoveAllNotifications = async () => {
        setDeleteAllLoading(true);
        const data = await removeNotification("delete_all", true);
        if (data.status === "success") {
            setTotalNotificationsCount(0);
            setNotificationsData([]);
        } else {
            showNotification(
                "Failed to delete all notifications",
                data.message,
                "error"
            );
        }
        setDeleteAllLoading(false);
    };

    const [openNotificationsModal, setOpenNotificationsModal] = useState(false);

    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    return (
        <div className="flex flex-col sm:flex-row sm:space-x-20 pb-10 sm:pb-0">
            <div className="sm:w-2/5 w-full flex flex-col">
                <div className="flex flex-row justify-between w-full items-center mb-2">
                    <h2 className="font-bold text-xl">Notifications</h2>
                    <div className="bg-red-500 h-5 px-1 text-white text-xs items-center flex justify-center font-medium rounded-md">
                        {totalNotificationsCount}
                    </div>
                </div>
                <div className="w-full h-px bg-gray-200"></div>
                {notificationsData.length > 0 ? (
                    <div className="divide-y pt-6">
                        {notificationsData.slice(0, 5).map((notification) => (
                            <div
                                onClick={() => {
                                    setUserId(
                                        new URLSearchParams(
                                            notification.href.split("?")[1]
                                        ).get("user_id")
                                    );
                                    navigate(notification.href);
                                }}
                                className="flex flex-row justify-between py-2 items-center hover:bg-gray-100 cursor-pointer transition-all"
                            >
                                <div className="flex flex-col">
                                    <span className="text-xs font-semibold">
                                        {notification.title}
                                    </span>
                                    <span className="text-xs text-gray-600">
                                        {notification.subtitle}
                                    </span>
                                    <span className="text-xs text-gray-600">
                                        {convertDateToEST(
                                            notification.created_at
                                        )}
                                    </span>
                                </div>
                                {deletingNotifications.includes(
                                    notification.notification_id
                                ) ? (
                                    <LoadingWheel color="black" size="small" />
                                ) : (
                                    <XMarkIcon
                                        onClick={(e) =>
                                            handleRemove(
                                                e,
                                                notification.notification_id
                                            )
                                        }
                                        className="w-4 h-4 text-gray-600 hover:text-gray-500"
                                    />
                                )}
                            </div>
                        ))}
                        <div className="flex flex-row justify-between pt-1">
                            <button
                                onClick={() => setOpenNotificationsModal(true)}
                                className="text-xs text-accent font-medium"
                            >
                                View all
                            </button>
                            <button
                                disabled={deleteAllLoading}
                                onClick={() => handleRemoveAllNotifications()}
                                className="text-xs text-gray-900 font-medium flex flex-row items-center"
                            >
                                {deleteAllLoading && (
                                    <span className="pr-2">
                                        <LoadingWheel />
                                    </span>
                                )}
                                Clear all
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="h-full w-full border border-dashed border-gray-300 mt-2 flex justify-center items-center rounded-md">
                        <p className="text-xs text-gray-600">
                            Nothing new here.
                        </p>
                    </div>
                )}
            </div>
            {isOwner && (
                <div className="w-full sm:pt-0 pt-10">
                    <div className="flex flex-row justify-between items-center mb-2">
                        <span className="text-xl font-bold">Overview</span>
                        <DateSelector
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </div>
                    <div className="w-full h-px bg-gray-200"></div>

                    <div className="flex flex-col sm:flex-row sm:space-x-20 pt-6">
                        <div className="w-full flex flex-col justify-between">
                            <div className="flex flex-col space-y-3">
                                <div className="flex flex-row items-center justify-between">
                                    <h2 className="font-semibold text-sm">
                                        Invoices
                                    </h2>
                                </div>
                                <div className="flex flex-row items-end space-x-1">
                                    <h2 className="font-medium text-2xl">
                                        {formatMoney(
                                            invoicesData?.total_invoices_sum
                                        )}
                                    </h2>
                                    <p className="text-sm text-gray-400 pb-0.5">
                                        in revenue
                                    </p>
                                </div>
                            </div>
                            <div className="min-h-24"></div>
                            <div className="divide-y">
                                {invoices.map((invoice) => (
                                    <div className="flex flex-row justify-between py-2 items-center">
                                        <div className="flex flex-row items-center space-x-2">
                                            <div
                                                className={`h-4 w-4 ${invoice.color} rounded-md`}
                                            ></div>
                                            <span className="text-xs font-semibold">
                                                {capitalizeFirstLetter(
                                                    invoice.label
                                                )}
                                            </span>
                                        </div>
                                        <span className="text-gray-800 text-xs font-medium">
                                            {invoice.value}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="w-full flex flex-col sm:pt-0 pt-10">
                            <h2 className="font-semibold text-sm">
                                Top customers by spend
                            </h2>
                            <div className="divide-y">
                                {topSpendClients.map((client, index) => (
                                    <div
                                        key={index}
                                        onClick={() =>
                                            navigate(
                                                `/admin/billing?user_id=${client.user_id}`
                                            )
                                        }
                                        className="flex flex-row justify-between py-2 items-center hover:bg-gray-100 cursor-pointer"
                                    >
                                        <div className="flex flex-col">
                                            <span className="text-xs font-semibold">
                                                {client.first_name}{" "}
                                                {client.last_name}
                                            </span>
                                            <span className="text-xs text-gray-600">
                                                {client.email}
                                            </span>
                                        </div>
                                        <span className="text-gray-800 text-xs font-medium">
                                            {formatMoney(client.total_spent)}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <NotificationsModal
                openNotificationsModal={openNotificationsModal}
                setOpenNotificationsModal={setOpenNotificationsModal}
            />
        </div>
    );
}

export default BillingStats;
