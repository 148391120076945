import { NavigateFunction } from "react-router-dom";
import Cookies from "js-cookie";
import { setAdminType } from "../../../utils/adminTypes/actions";
import { setClientType } from "../../../utils/clientTypes/actions";
import { apiRequest } from "../../../utils/api/apiRequest";

export const loginRequest = async (email: string, password: string, client_type: string) => {
    return apiRequest("/login", "POST", {email, password, client_type})
}


export const signupRequest = async (
    email: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    password: string,
    client_type: string,
    referral_code: string | null,
) => {
    return apiRequest("/signup", "POST", {email, first_name, last_name, phone_number, password, client_type, referral_code})
}  

export const forgotPasswordRequest = async (
    email: string,
    navigate: NavigateFunction,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    setLoading(true)
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/forgot-password",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email })
            }
        )

        const data = await response.json();

        if (data.status === "success") {
            setLoading(false)

            navigate(`/reset-password?email=${encodeURIComponent(email)}`)

        } else {
            setLoading(false)
        }
    } catch(error) {
        setLoading(false)
    }
}

export const resetPasswordRequest = async (
    email: string | null,
    password: string,
    code: string,
    navigate: NavigateFunction,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setVerificationCodeIncorrect: React.Dispatch<React.SetStateAction<boolean>>,
    showNotification: (title: string, subtitle?: string, type?: "success" | "error") => void
) => {
    setLoading(true)
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/reset-password",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email, password, code })
            }
        )

        const data = await response.json();

        if (data.status === "success") {
            setLoading(false)

            navigate(`/reset-password/success`)

        } else if (data.message === "verification code is incorrect") {
            setLoading(false)
            setVerificationCodeIncorrect(true)
        } else {
            showNotification("Failed to change password", data.message, "error")
            setLoading(false)
        }
    } catch(error) {
        setLoading(false)
        showNotification("Failed to change password", String(error), "error")
    }
}

export const validateReferralCode = async (referral_code: string) => {
    return apiRequest("/referrals/validate", "POST", {referral_code})
}