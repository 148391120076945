/** @format */

"use client";

import { useState } from "react";
import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

interface MediumSelectorProps {
    selected: {
        id: string;
        name: string;
        value: string;
    };
    setSelected: React.Dispatch<
        React.SetStateAction<{
            id: string;
            name: string;
            value: string;
        }>
    >;
    selectorData: {
        id: string;
        name: string;
        value: string;
    }[];
}

export default function MediumSelector({
    selected,
    setSelected,
    selectorData,
}: MediumSelectorProps) {
    return (
        <Listbox value={selected} onChange={setSelected}>
            <div className="relative">
                <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                    <span className="block truncate text-sm">
                        {selected.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                        />
                    </span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute z-30 mt-1 max-h-60 w-44 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                    {selectorData.map((type) => (
                        <ListboxOption
                            key={type.id}
                            value={type}
                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-accent data-[focus]:text-white"
                        >
                            <span className="block truncate font-normal group-data-[selected]:font-semibold text-sm">
                                {type.name}
                            </span>

                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-accent group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                />
                            </span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    );
}
