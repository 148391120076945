/** @format */

import { useState, useEffect, useCallback } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../components/loadingWheel";
import { createBulkInboundOrder, createInboundOrder } from "./api/inboundApi";
import { FileUpload } from "../../../utils/files/fileUpload";
import {
    InputField,
    InputFileField,
    InputNumberField,
} from "../../../components/inputField";
import SingleCalendar from "../../../components/singleCalendar";
import dayjs, { Dayjs } from "dayjs";
import { formatDateFromObject } from "../../../utils/date/date_formatter";
import { useNotification } from "../../../utils/notification/notificationContext";
import MultiTrackingInput from "../../../components/multipleTrackingInput";
import FulfilledBySelector from "../../../components/fulfilledBySelector";
import { fulfilledByOptions } from "../../../models/order";
import { ActionButton, SecondaryButton } from "../../../components/buttons";
import { TrackingInfo } from "../../../models/trackings";
import { useSearchParams } from "react-router-dom";
import { apiRequest } from "../../../utils/api/apiRequest";
import { debounce } from "lodash";
import { SuggestedNamesDropDown } from "../../admin/received/checkInOrder";

export function AddOrderModal({
    openAddOrderModal,
    setOpenAddOrderModal,
    handleRefetch,
}: {
    openAddOrderModal: boolean;
    setOpenAddOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);

    const [fulfilledBy, setFulfilledBy] = useState<{
        id: number;
        value: string;
    }>({ id: 1, value: "-- select an option --" });
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
    const [retailer, setRetailer] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [itemName, setItemName] = useState("");
    const [color, setColor] = useState("");
    const [brandSKU, setBrandSKU] = useState("");
    const [size, setSize] = useState("");
    const [quantity, setQuantity] = useState("");
    const [asin, setAsin] = useState("");
    const [amazonSKU, setAmazonSKU] = useState("");
    const [cogs, setCogs] = useState("");
    const [listPrice, setListPrice] = useState("");
    const [notes, setNotes] = useState("");

    const [trackings, setTrackings] = useState<TrackingInfo[]>([]);

    const [missingFields, setMissingFields] = useState(false);

    const canSubmit = () => {
        if (
            retailer === "" ||
            orderNumber === "" ||
            itemName === "" ||
            color === "" ||
            size === "" ||
            quantity === "" ||
            cogs === "" ||
            fulfilledBy.value == "-- select an option --"
        ) {
            return false;
        }
        return true;
    };

    const clearFields = () => {
        setSelectedDate(dayjs());
        setRetailer("");
        setOrderNumber("");
        setItemName("");
        setColor("");
        setBrandSKU("");
        setSize("");
        setQuantity("");
        setAsin("");
        setAmazonSKU("");
        setCogs("");
        setListPrice("");
        setNotes("");
        setTrackings([]);
        setSelectedShippingLabelFiles([]);
    };

    useEffect(() => {
        if (!openAddOrderModal) {
            setMissingFields(false);
            setFulfilledBy({ id: 1, value: "-- select an option --" });
        } else {
            const openedModal = searchParams.get("openModal");
            if (openedModal === "new_inbound_shipment") {
                const matchedOption = fulfilledByOptions.find(
                    (option) =>
                        option.value.toLowerCase() ===
                            searchParams.get("fulfilled_by") || "".toLowerCase()
                );

                if (matchedOption) {
                    setFulfilledBy(matchedOption);
                }
                setItemName(searchParams.get("item_name") || "");
                setOrderNumber(searchParams.get("order_number") || "");
                setSize(searchParams.get("size") || "");
                setRetailer(searchParams.get("retailer") || "");
                setQuantity("0");
                setColor(searchParams.get("color") || "");
                setCogs(searchParams.get("unit_cost") || "");
                setListPrice(searchParams.get("list_price") || "");
                setBrandSKU(searchParams.get("brand_sku") || "");
                setAsin(searchParams.get("asin") || "");
                setAmazonSKU(searchParams.get("amazon_sku") || "");
                setSearchParams([]);
            }
        }
    }, [openAddOrderModal]);

    const handleCreateOrder = async () => {
        setLoading(true);
        const data = await createInboundOrder(
            fulfilledBy.value.toLowerCase(),
            formatDateFromObject(selectedDate),
            retailer,
            orderNumber,
            itemName,
            color,
            brandSKU,
            size,
            quantity,
            asin,
            amazonSKU,
            cogs,
            listPrice,
            notes,
            trackings.map((t) => t.tracking),
            selectedShippingLabelFiles
        );
        if (data.status === "success") {
            handleRefetch();
            setOpenAddOrderModal(false);
            showNotification("Your order has been created", "", "success");
            clearFields();
        } else {
            showNotification("An error occurred", data.message, "error");
        }
        setLoading(false);
    };

    const [selectedShippingLabelFiles, setSelectedShippingLabelFiles] =
        useState<File[]>([]);
    const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
        {}
    );
    const setColumnLoading = (column_name: string, isLoading: boolean) => {
        setLoadingStates((prev) => ({
            ...prev,
            [column_name]: isLoading,
        }));
    };
    const isColumnLoading = (column_name: string) =>
        loadingStates[column_name] || false;

    const [focusedField, setFocusedField] = useState<null | {
        column: string;
        index: number;
    }>(null);
    const [columnSuggestions, setColumnSuggestions] = useState<
        Record<string, string[]>
    >({});

    const [itemsLoading, setItemsLoading] = useState(false);
    const searchItemNames = async (name: string, column_name: string) => {
        if (!name || name === "") {
            setColumnSuggestions((prev) => ({
                ...prev,
                [column_name]: [], // Clear suggestions for the column
            }));
            setColumnLoading(column_name, false);
            return;
        }
        setColumnLoading(column_name, true);

        const data = await apiRequest(
            `/inbound/search-item-names?name=${name}&column_name=${column_name}`
        );
        if (data.status === "success") {
            setColumnSuggestions((prev) => ({
                ...prev,
                [column_name]: data.data || [], // Update suggestions for the column
            }));
        }
        setColumnLoading(column_name, false);
    };

    const debouncedSearch = useCallback(
        debounce(
            (value: string, column_name: string) =>
                searchItemNames(value, column_name),
            200
        ),
        []
    );

    const getSuggestionsForColumn = (column_name: string) =>
        columnSuggestions[column_name] || [];

    return (
        <Dialog
            open={openAddOrderModal}
            onClose={() => setOpenAddOrderModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-5/6 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenAddOrderModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Enter shipment
                            {missingFields ? (
                                <span className="text-red-500 pl-5">
                                    Missing fields, fill required fields.
                                </span>
                            ) : (
                                <></>
                            )}
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4">
                            <div>
                                <span className="font-medium text-sm">
                                    Fulfillment method
                                </span>
                                <FulfilledBySelector
                                    selected={fulfilledBy}
                                    setSelected={setFulfilledBy}
                                    options={fulfilledByOptions}
                                />
                            </div>

                            <div className="flex-row flex space-x-10 pt-4">
                                <div className="space-y-4 w-full -mt-0.5">
                                    <SingleCalendar
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                    />
                                    <InputField
                                        label="Item name"
                                        value={itemName}
                                        onChange={(e) =>
                                            setItemName(e.target.value)
                                        }
                                        placeholder=""
                                    />
                                    {/* <SuggestedNamesDropDown
                                        show={
                                            focusedField?.index === 0 &&
                                            itemName !== "" &&
                                            getSuggestionsForColumn("item_name")
                                                .length > 0 &&
                                            focusedField?.column === "item_name"
                                        }
                                        itemsList={getSuggestionsForColumn(
                                            "item_name"
                                        )}
                                        index={0}
                                        updateOrder={updateOrder}
                                        setIsFocused={setFocusedField}
                                        columnName="item_name"
                                    /> */}
                                    <InputField
                                        label="Order #"
                                        value={orderNumber}
                                        onChange={(e) =>
                                            setOrderNumber(e.target.value)
                                        }
                                        placeholder=""
                                    />
                                    <InputField
                                        label="Size"
                                        value={size}
                                        onChange={(e) =>
                                            setSize(e.target.value)
                                        }
                                        placeholder=""
                                    />
                                    <InputField
                                        label="Brand SKU"
                                        value={brandSKU}
                                        onChange={(e) =>
                                            setBrandSKU(e.target.value)
                                        }
                                        placeholder=""
                                        optional={true}
                                    />
                                    {fulfilledBy.value.toLowerCase() !==
                                        "reshipping" && (
                                        <InputField
                                            label="ASIN"
                                            value={asin}
                                            onChange={(e) =>
                                                setAsin(e.target.value)
                                            }
                                            placeholder=""
                                            optional={true}
                                        />
                                    )}
                                    <MultiTrackingInput
                                        trackings={trackings}
                                        setTrackings={setTrackings}
                                    />
                                </div>
                                <div className="space-y-4 w-full">
                                    <InputField
                                        label="Supplier"
                                        value={retailer}
                                        onChange={(e) =>
                                            setRetailer(e.target.value)
                                        }
                                        placeholder=""
                                    />
                                    <InputNumberField
                                        label="Quantity Ordered"
                                        value={quantity}
                                        onChange={(e) =>
                                            setQuantity(e.target.value)
                                        }
                                        placeholder=""
                                    />
                                    <InputField
                                        label="Color"
                                        value={color}
                                        onChange={(e) =>
                                            setColor(e.target.value)
                                        }
                                        placeholder=""
                                    />
                                    <InputField
                                        label="Unit cost"
                                        value={cogs}
                                        onChange={(e) =>
                                            setCogs(e.target.value)
                                        }
                                        placeholder=""
                                        extraText={`Order total: $${(
                                            Number(quantity) * Number(cogs)
                                        ).toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}`}
                                    />
                                    {fulfilledBy.value.toLowerCase() !==
                                        "reshipping" && (
                                        <InputField
                                            label="List price"
                                            value={listPrice}
                                            onChange={(e) =>
                                                setListPrice(e.target.value)
                                            }
                                            placeholder=""
                                            optional={true}
                                        />
                                    )}
                                    {fulfilledBy.value.toLowerCase() !==
                                        "reshipping" && (
                                        <InputField
                                            label="Amazon SKU"
                                            value={amazonSKU}
                                            onChange={(e) =>
                                                setAmazonSKU(e.target.value)
                                            }
                                            placeholder=""
                                            optional={true}
                                        />
                                    )}
                                    <InputField
                                        label="Notes"
                                        value={notes}
                                        onChange={(e) =>
                                            setNotes(e.target.value)
                                        }
                                        placeholder=""
                                        optional={true}
                                    />
                                    {fulfilledBy.value.toLowerCase() ===
                                        "reshipping" && (
                                        <InputFileField
                                            label="Shipping Label"
                                            value={""}
                                            onChange={() => {}}
                                            placeholder=""
                                            optional={true}
                                            selectedFiles={
                                                selectedShippingLabelFiles
                                            }
                                            setSelectedFiles={
                                                setSelectedShippingLabelFiles
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse justify-between">
                            <ActionButton
                                label="Add shipment"
                                handleClick={() => handleCreateOrder()}
                                height={8}
                                loading={loading}
                            />
                            <div className="space-x-2 flex flex-row items-center">
                                <SecondaryButton
                                    label="Cancel"
                                    handleClick={() =>
                                        setOpenAddOrderModal(false)
                                    }
                                    height={8}
                                />
                                <SecondaryButton
                                    label="Clear fields"
                                    handleClick={() => clearFields()}
                                    height={8}
                                />
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export function AddBulkOrderModal({
    openAddBulkOrderModal,
    setOpenAddBulkOrderModal,
    handleRefetch,
}: {
    openAddBulkOrderModal: boolean;
    setOpenAddBulkOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    useEffect(() => {
        setSelectedFile(null);
    }, [openAddBulkOrderModal]);

    const handleCreateBulkOrder = async () => {
        setLoading(true);
        const { success, message } = await createBulkInboundOrder(
            selectedFile,
            showNotification
        );
        if (success) {
            handleRefetch();
            setOpenAddBulkOrderModal(false);
            showNotification("Your order has been created", "", "success");
        } else {
            showNotification("An error occurred", message, "error");
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openAddBulkOrderModal}
            onClose={() => setOpenAddBulkOrderModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenAddBulkOrderModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Upload CSV
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4">
                            <div className="flex flex-col w-full justify-between">
                                <span className="">
                                    1. Download the CSV template here:
                                </span>
                                <div className="flex flex-row items-center space-x-3 pt-1">
                                    <a
                                        download={"fba_fbm_template.csv"}
                                        href="/resources/fba_fbm_template.csv"
                                    >
                                        <button className="flex flex-row items-center border border-gray-300 hover:border-gray-400 rounded-md px-2 py-1 hover:bg-gray-50">
                                            <MdOutlineFileDownload />
                                            <span className="pl-2 text-sm">
                                                FBA/FBM template
                                            </span>
                                        </button>
                                    </a>
                                    <a
                                        download={"reshipping_template.csv"}
                                        href="/resources/reshipping_template.csv"
                                    >
                                        <button className="flex flex-row items-center border border-gray-300 hover:border-gray-400 rounded-md px-2 py-1 hover:bg-gray-50">
                                            <MdOutlineFileDownload />
                                            <span className="pl-2 text-sm">
                                                Reshipping template
                                            </span>
                                        </button>
                                    </a>
                                </div>
                            </div>

                            <div className="pt-5">
                                <span className="w-full">
                                    2. Fill one row for each item you are
                                    shipping.
                                </span>
                            </div>

                            <div className="pt-5">
                                <span className="w-full">
                                    3. Upload the csv file here:
                                </span>
                            </div>

                            <FileUpload
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                            />

                            <div className=" inline-flex space-x-2 pt-2">
                                <InformationCircleIcon className="text-gray-500 w-4 h-4" />
                                <span className="text-xs text-gray-500">
                                    To add multiple tracking numbers, separate
                                    them by a colon: tracking1:tracking2
                                </span>
                            </div>
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                disabled={loading}
                                onClick={() => handleCreateBulkOrder()}
                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                            >
                                {loading ? (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                ) : (
                                    <></>
                                )}
                                Add CSV
                            </button>
                            <button
                                type="button"
                                onClick={() => setOpenAddBulkOrderModal(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
