/** @format */

import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import Header from "../../components/website/header";
import Footer from "../../components/website/footer";
import { useEffect, useState } from "react";
import { sendContactRequest } from "./api/contactApi";
import { useNotification } from "../../utils/notification/notificationContext";
import LoadingWheel from "../../components/loadingWheel";
import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import {
    CheckCircleIcon,
    CheckIcon,
    ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import { motion } from "framer-motion";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";

const shipmentSizes = [
    { id: 1, name: "0-499" },
    { id: 2, name: "500-999" },
    { id: 3, name: "1,000-1,999" },
    { id: 4, name: "2,000+" },
];

function RequestQuote({
    disable,
    title = "Request a Quote",
    subTitle = "Looking for cost-effective solutions? Request a quote today and see how we can deliver value to your business.",
    removeTopPadding = false,
    emailToPrefill = null,
    setOpenFurtherContactModal,
}: {
    disable?: () => void;
    title?: string;
    subTitle?: string;
    removeTopPadding?: boolean;
    emailToPrefill?: string | null;
    setOpenFurtherContactModal?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();
    const [fullName, setFullName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");

    const [selectedShipmentSize, setSelectedShipmentSize] = useState(
        shipmentSizes[0]
    );

    const [submittedForm, setSubmittedForm] = useState(false);

    const validForm = () => {
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(email)) {
            showNotification(
                "Email appears to be invalid, please check it.",
                "",
                "error"
            );
            return;
        }
        if (email === "" || fullName === "" || phoneNumber === "") {
            showNotification(
                "Please fill out the name, email and phone fields.",
                "",
                "error"
            );
            return false;
        }
        return true;
    };

    const handleContact = async () => {
        disable && disable();
        setLoading(true);
        if (!validForm()) {
            setLoading(false);
            return;
        }
        const data = await sendContactRequest(
            fullName,
            companyName,
            email,
            phoneNumber,
            message,
            selectedShipmentSize.name
        );
        if (data.status === "success") {
            showNotification("Succesfully sent contact request", "", "success");
            setSubmittedForm(true);
            clearFields();

            if (setOpenFurtherContactModal) setOpenFurtherContactModal(false);
        } else {
            showNotification(
                "Failed sending contact request. Try again.",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    const clearFields = () => {
        setFullName("");
        setCompanyName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
        setSelectedShipmentSize(shipmentSizes[0]);
    };

    useEffect(() => {
        if (emailToPrefill) {
            setEmail(emailToPrefill);
        }
        console.log("we are checking email");
    }, []);

    return (
        <div
            id="request-quote"
            className="relative isolate bg-white overflow-x-hidden"
        >
            <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                <motion.div
                    className={`relative px-6 ${
                        !removeTopPadding ? "pt-36 sm:pt-40" : ""
                    } lg:static lg:px-8`}
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                >
                    <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                        <motion.h2
                            className={`${
                                removeTopPadding && "hidden sm:flex"
                            } text-3xl font-bold tracking-tight text-gray-900 sm:text-4`}
                            initial={{ opacity: 0, y: -20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            viewport={{ once: true }}
                        >
                            {title}
                        </motion.h2>
                        <motion.p
                            className={`${
                                removeTopPadding && "hidden sm:flex"
                            } mt-6 text-lg leading-8 text-gray-600`}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            viewport={{ once: true }}
                        >
                            {subTitle}
                        </motion.p>
                        {/* Additional animations for email, phone, and promises */}
                        <dl
                            className={`${
                                !removeTopPadding && "mt-5"
                            } space-y-4 text-base leading-7 text-gray-600`}
                        >
                            <motion.div
                                className="flex gap-x-4 flex-col space-y-4 "
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.6 }}
                                viewport={{ once: true }}
                            >
                                <div
                                    className={`${
                                        removeTopPadding && "hidden sm:flex"
                                    } flex flex-row items-center space-x-4`}
                                >
                                    <dt className="flex-none">
                                        <span className="sr-only">Email</span>
                                        <EnvelopeIcon
                                            aria-hidden="true"
                                            className="h-7 w-6 text-gray-400"
                                        />
                                    </dt>
                                    <dd>
                                        <a
                                            href="mailto:contact@urvafreight.com"
                                            className="hover:text-gray-900"
                                        >
                                            contact@urvafreight.com
                                        </a>
                                    </dd>
                                </div>

                                <div
                                    className={`${
                                        removeTopPadding && "hidden sm:flex"
                                    } flex flex-row items-center space-x-4`}
                                >
                                    <dt className="flex-none">
                                        <span className="sr-only">Email</span>
                                        <PhoneIcon
                                            aria-hidden="true"
                                            className="h-7 w-6 text-gray-400"
                                        />{" "}
                                    </dt>
                                    <dd>
                                        <a
                                            href="mailto:contact@urvafreight.com"
                                            className="hover:text-gray-900"
                                        >
                                            (302) 685-2116
                                        </a>
                                    </dd>
                                </div>
                                <div className="pt-3"></div>
                                <div className="w-full border border-gray-200 sm:px-8 px-4 py-4 rounded-md">
                                    <h1 className="text-xl font-semibold">
                                        We promise
                                    </h1>
                                    <div className="grid grid-cols-2 sm:text-sm text-xs text-gray-800 pt-3 gap-y-4">
                                        <p>• $0 Setup</p>
                                        <p>• $0 Software</p>
                                        <p>• $0 Receiving</p>
                                        <p>• No MOQ</p>
                                        <p>• 24-Hour Turnaround</p>
                                        <p>•⁠ Sales Tax-Free Address</p>
                                    </div>
                                </div>
                            </motion.div>
                        </dl>
                    </div>
                </motion.div>
                <motion.div
                    className={`px-6 ${
                        !removeTopPadding ? "pt-16 sm:pt-36" : "pt-4"
                    } lg:px-8`}
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                >
                    <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                        <motion.div
                            className="grid grid-cols-2 gap-x-8 gap-y-6 sm:grid-cols-2"
                            initial={{ opacity: 0, scale: 0.9 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            viewport={{ once: true }}
                        >
                            <div>
                                <div className="flex flex-row items-center">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Name
                                    </label>
                                    <div className="text-red-500">*</div>
                                </div>
                                <div className="mt-2.5">
                                    <input
                                        id="full-name"
                                        name="full-name"
                                        type="text"
                                        autoComplete="given-name"
                                        value={fullName}
                                        onChange={(e) =>
                                            setFullName(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="">
                                <label
                                    htmlFor="company-name"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Company Name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        value={companyName}
                                        onChange={(e) =>
                                            setCompanyName(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2 col-span-2">
                                <div className="flex flex-row items-center">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Email
                                    </label>

                                    <div className="text-red-500">*</div>
                                </div>
                                <div className="mt-2.5">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="">
                                <div className="flex flex-row items-center">
                                    <label
                                        htmlFor="phone-number"
                                        className="block text-sm font-semibold leading-6 text-gray-900"
                                    >
                                        Phone number
                                    </label>

                                    <div className="text-red-500">*</div>
                                </div>
                                <div className="mt-2.5">
                                    <input
                                        id="phone-number"
                                        name="phone-number"
                                        type="tel"
                                        autoComplete="tel"
                                        value={phoneNumber}
                                        onChange={(e) =>
                                            setPhoneNumber(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="mt-0.5">
                                <Listbox
                                    value={selectedShipmentSize}
                                    onChange={setSelectedShipmentSize}
                                >
                                    <Label className="block text-sm font-semibold leading-6 text-gray-900">
                                        Units / month
                                    </Label>
                                    <div className="relative mt-2">
                                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                                            <span className="block truncate">
                                                {selectedShipmentSize.name}
                                            </span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon
                                                    aria-hidden="true"
                                                    className="h-5 w-5 text-gray-400"
                                                />
                                            </span>
                                        </ListboxButton>

                                        <ListboxOptions
                                            transition
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                        >
                                            {shipmentSizes.map(
                                                (shipmentSize) => (
                                                    <ListboxOption
                                                        key={shipmentSize.id}
                                                        value={shipmentSize}
                                                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-accent data-[focus]:text-white"
                                                    >
                                                        <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                                            {shipmentSize.name}
                                                        </span>

                                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-accent group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                            <CheckIcon
                                                                aria-hidden="true"
                                                                className="h-5 w-5"
                                                            />
                                                        </span>
                                                    </ListboxOption>
                                                )
                                            )}
                                        </ListboxOptions>
                                    </div>
                                </Listbox>
                            </div>
                            <div className="sm:col-span-2 col-span-2">
                                <label
                                    htmlFor="message"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Tell us about your business
                                </label>
                                <div className="mt-2.5">
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows={4}
                                        value={message}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        defaultValue={""}
                                    />
                                </div>
                            </div>
                        </motion.div>
                        <div className="mt-8 flex justify-end">
                            <button
                                disabled={loading || submittedForm}
                                onClick={handleContact}
                                className={`${
                                    submittedForm
                                        ? "bg-green-200 text-green-500 cursor-default"
                                        : "bg-accent text-white hover:bg-accenthighlight"
                                } rounded-md items-center flex flex-row justify-center w-20 h-10 text-center text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent`}
                            >
                                {submittedForm ? (
                                    <CheckCircleIcon className="w-5 h-5 items-center flex" />
                                ) : !loading ? (
                                    <>Send</>
                                ) : (
                                    <LoadingWheel size="small" color="white" />
                                )}
                            </button>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}

export default RequestQuote;
