/** @format */

import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/authentication/login/page";
import PageNotFound from "./pages/notfound/page";
import ClientsPage from "./pages/admin/clients/page";
import PrivateRoute from "./utils/auth/privateRoute";
import SignUpPage from "./pages/authentication/sign-up/page";
import UnauthorizedPage from "./pages/unauthorized/page";
import SettingsPage from "./pages/client/settings/page";
import DashboardPage from "./pages/client/dashboard/page";
import AdminDashboardPage from "./pages/admin/dashboard/page";
import ForgotPasswordPage from "./pages/authentication/forgot-password/page";
import ResetPasswordPage from "./pages/authentication/forgot-password/reset-password/page";
import ResetPasswordSuccessPage from "./pages/authentication/forgot-password/reset-password/resetPasswordSuccess";
import { NotificationProvider } from "./utils/notification/notificationContext";
import { StatusNotification } from "./components/successNotification";
import BillingPage from "./pages/client/billing/page";
import HomePage from "./pages/website/home";
import ContactPage from "./pages/website/contact";
import OrderFulfillmentPage from "./pages/website/orderFulfillment";
import EcommerceManagementPage from "./pages/website/ecommerceManagement";
import InvoiceDataPage from "./pages/client/billing/invoice-data/page";
import ClientInvoiceDataPage from "./pages/admin/invoices/page";
import InboundUnitsPage from "./pages/client/inbound/units/page";
import InboundTrackingsPage from "./pages/client/inbound/trackings/page";
import InventoryUnitsPage from "./pages/client/inventory/units/page";
import OutboundShipmentsPage from "./pages/client/outbound/shipments/page";
import OutboundRequestedPage from "./pages/client/outbound/requested/page";
import OutboundTrackingsPage from "./pages/client/outbound/trackings/page";
import AcceptInvitationPage from "./pages/client/team_invitation/accept/page";
import RequestQuotePage from "./pages/website/requestQuote";
import AdminInboundPage from "./pages/admin/inbound/page";
import AdminInventoryPage from "./pages/admin/inventory/page";
import AdminShipmentsShippedPage from "./pages/admin/outbound/page";
import ClientSettingsPage from "./pages/admin/settings/page";
import AdminBillingPage from "./pages/admin/billing/page";
import AdminEmployeesPage from "./pages/admin/employees/page";
import { ClientProvider } from "./utils/client/clientContext";
import PricingPage from "./pages/website/pricing";
import ReferralsPage from "./pages/client/referrals/page";
import AdminReferralsPage from "./pages/admin/referrals/page";
import ShippingNamesPage from "./pages/client/inbound/shipping-names/page";
import AdminReceivedPage from "./pages/admin/received/page";
import ReceivedPage from "./pages/client/received/checked-in/page";
import NeedsCheckInPage from "./pages/client/received/needs-check-in/page";
import TermsAndConditions from "./pages/website/termsAndConditions";
import ShippingDetailsPage from "./pages/client/outbound/shipping-details/page";

function DashboardRoutes() {
    return (
        <BrowserRouter>
            <ClientProvider>
                <Routes>
                    {/* CLIENT ROUTES Addition */}
                    <Route
                        path="/"
                        element={
                            <PrivateRoute
                                element={DashboardPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />
                    <Route
                        path="/inbound/units"
                        element={
                            <PrivateRoute
                                element={InboundUnitsPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />
                    <Route
                        path="/inbound/trackings"
                        element={
                            <PrivateRoute
                                element={InboundTrackingsPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />
                    <Route
                        path="/inbound/shipping-names"
                        element={
                            <PrivateRoute
                                element={ShippingNamesPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />

                    <Route
                        path="/received/checked-in"
                        element={
                            <PrivateRoute
                                element={ReceivedPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />

                    <Route
                        path="/received/needs-check-in"
                        element={
                            <PrivateRoute
                                element={NeedsCheckInPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />

                    <Route
                        path="/inventory/units"
                        element={
                            <PrivateRoute
                                element={InventoryUnitsPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />

                    <Route
                        path="/outbound/shipped"
                        element={
                            <PrivateRoute
                                element={OutboundShipmentsPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />

                    <Route
                        path="/outbound/requested"
                        element={
                            <PrivateRoute
                                element={OutboundRequestedPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />

                    <Route
                        path="/outbound/requested-details"
                        element={
                            <PrivateRoute
                                element={ShippingDetailsPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />

                    <Route
                        path="/outbound/trackings"
                        element={
                            <PrivateRoute
                                element={OutboundTrackingsPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />

                    <Route
                        path="/account"
                        element={
                            <PrivateRoute
                                element={SettingsPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />

                    <Route
                        path="/billing"
                        element={
                            <PrivateRoute
                                element={BillingPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />
                    <Route
                        path="/billing/:invoice_id"
                        element={
                            <PrivateRoute
                                element={InvoiceDataPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />
                    <Route
                        path="/referrals"
                        element={
                            <PrivateRoute
                                element={ReferralsPage}
                                allowedRoles={["client", "client_team_member"]}
                            />
                        }
                    />

                    {/* ADMIN ROUTES  */}
                    <Route
                        path="/admin"
                        element={
                            <PrivateRoute
                                element={AdminDashboardPage}
                                allowedRoles={["admin", "employee"]}
                            />
                        }
                    />
                    <Route
                        path="/admin/clients"
                        element={
                            <PrivateRoute
                                element={ClientsPage}
                                allowedRoles={["admin", "employee"]}
                            />
                        }
                    />

                    <Route
                        path="/admin/clients/:user_id/invoices/:invoice_id"
                        element={
                            <PrivateRoute
                                element={ClientInvoiceDataPage}
                                allowedRoles={["admin"]}
                            />
                        }
                    />

                    <Route
                        path="/admin/received"
                        element={
                            <PrivateRoute
                                element={AdminReceivedPage}
                                allowedRoles={["admin", "employee"]}
                            />
                        }
                    />

                    <Route
                        path="/admin/inbound"
                        element={
                            <PrivateRoute
                                element={AdminInboundPage}
                                allowedRoles={["admin", "employee"]}
                            />
                        }
                    />

                    <Route
                        path="/admin/inventory"
                        element={
                            <PrivateRoute
                                element={AdminInventoryPage}
                                allowedRoles={["admin", "employee"]}
                            />
                        }
                    />

                    <Route
                        path="/admin/outbound"
                        element={
                            <PrivateRoute
                                element={AdminShipmentsShippedPage}
                                allowedRoles={["admin", "employee"]}
                            />
                        }
                    />

                    <Route
                        path="/admin/settings"
                        element={
                            <PrivateRoute
                                element={ClientSettingsPage}
                                allowedRoles={["admin", "employee"]}
                            />
                        }
                    />

                    <Route
                        path="/admin/billing"
                        element={
                            <PrivateRoute
                                element={AdminBillingPage}
                                allowedRoles={["admin", "employee"]}
                            />
                        }
                    />

                    <Route
                        path="/admin/employees"
                        element={
                            <PrivateRoute
                                element={AdminEmployeesPage}
                                allowedRoles={["admin", "employee"]}
                            />
                        }
                    />
                    <Route
                        path="/admin/referrals"
                        element={
                            <PrivateRoute
                                element={AdminReferralsPage}
                                allowedRoles={["admin", "employee"]}
                            />
                        }
                    />

                    {/* AUTHENTICATION ROUTES  */}
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPasswordPage />}
                    />
                    <Route
                        path="/reset-password"
                        element={<ResetPasswordPage />}
                    />
                    <Route
                        path="/reset-password/success"
                        element={<ResetPasswordSuccessPage />}
                    />

                    <Route
                        path="/team/accept-invitation"
                        element={<AcceptInvitationPage />}
                    />

                    {/* ERROR ROUTES  */}
                    <Route path="*" element={<PageNotFound />} />
                    <Route
                        path="/unauthorized"
                        element={<UnauthorizedPage />}
                    />
                </Routes>
            </ClientProvider>
        </BrowserRouter>
    );
}

function WebsiteRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                {/* WEBSITE ROUTES */}
                <Route path="/" element={<HomePage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route
                    path="/order-fulfillment"
                    element={<OrderFulfillmentPage />}
                />
                <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                />
                <Route path="/pricing" element={<PricingPage />} />

                {/* ERROR ROUTES  */}
                <Route path="*" element={<PageNotFound />} />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
            </Routes>
        </BrowserRouter>
    );
}

function App() {
    const isDashboardDomain =
        window.location.hostname === "dashboard.urvafreight.com" ||
        window.location.hostname === "localhost";

    return (
        <NotificationProvider>
            {isDashboardDomain ? <DashboardRoutes /> : <WebsiteRoutes />}
            <StatusNotification />
        </NotificationProvider>
    );
}

export default App;
