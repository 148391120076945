/** @format */

import { useEffect, useRef, useState } from "react";
import { Box } from "../../../../models/adminDashboard";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { formatMoney } from "../../../../utils/price/formatMoney";
import { TrashIcon } from "@heroicons/react/24/solid";

interface BoxesPopupProps {
    boxes: Box[];
    selectedBoxes: Box[];
    setSelectedBoxes: React.Dispatch<React.SetStateAction<Box[]>>;
}

export default function BoxesPopup({
    boxes,
    selectedBoxes,
    setSelectedBoxes,
}: BoxesPopupProps) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = () => {
        setIsOpen((prevState) => !prevState);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleBoxClick = (box: Box) => {
        setSelectedBoxes((prev) => {
            const existingBox = prev.find((b) => b.box_id === box.box_id);

            if (existingBox) {
                // If the box is already selected, update its quantity
                return prev.map((b) =>
                    b.box_id === box.box_id
                        ? { ...b, quantity: b.quantity + 1 }
                        : b
                );
            } else {
                // If the box is not selected, add it with quantity set to 1
                return [...prev, { ...box, quantity: 1 }];
            }
        });
    };

    const handleBoxQuantityChange = (boxId: string, quantity: number) => {
        if (quantity < 1) return; // Prevent quantity from being set to less than 1
        setSelectedBoxes((prev) =>
            prev.map((b) => (b.box_id === boxId ? { ...b, quantity } : b))
        );
    };

    const handleRemoveBox = (boxId: string) => {
        setSelectedBoxes(selectedBoxes.filter((box) => box.box_id !== boxId));
    };

    const availableBoxes = boxes.filter(
        (box) =>
            !selectedBoxes.some(
                (selectedBox) => selectedBox.box_id === box.box_id
            )
    );

    return (
        <div className="flex flex-col items-start space-x-0 space-y-2">
            <div className="relative inline-block text-left whitespace-nowrap">
                {/* Button to toggle the dropdown */}
                <div
                    ref={buttonRef}
                    onClick={toggleDropdown}
                    className="flex items-center cursor-pointer"
                >
                    <span className="font-medium text-sm">Add Boxes</span>
                    <PlusCircleIcon className="w-6 h-6 text-accent cursor-pointer hover:text-accenthighlight ml-2" />
                </div>
                {isOpen && (
                    <div
                        ref={dropdownRef}
                        className="absolute max-h-64 overflow-auto pt-1 left-0 w-48 bg-white border border-gray-300 shadow-lg rounded-md z-20"
                    >
                        {availableBoxes.map((box) => (
                            <div
                                key={box.box_id}
                                onClick={() => handleBoxClick(box)}
                                className="text-sm flex flex-col py-2 space-y-0.5 pl-2 hover:bg-gray-100 transition-all cursor-pointer"
                            >
                                <span className="font-medium">{box.name}</span>
                                <span className="text-gray-700">
                                    {formatMoney(box.price)}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="w-full">
                <div className="flex flex-col space-y-3 w-full">
                    {selectedBoxes.length > 0 &&
                        selectedBoxes.map((box) => (
                            <div
                                key={box.box_id}
                                className="flex items-center space-x-2"
                            >
                                <div className="flex flex-col text-sm min-w-44">
                                    <span className="font-medium">
                                        {box.name}
                                    </span>
                                    <span className="text-gray-700">
                                        {formatMoney(box.price)}
                                    </span>
                                </div>
                                <input
                                    type="number"
                                    className="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    min="1"
                                    value={box.quantity}
                                    onChange={(e) =>
                                        handleBoxQuantityChange(
                                            box.box_id,
                                            parseInt(e.target.value)
                                        )
                                    }
                                    onWheel={(e) => {
                                        const target =
                                            e.target as HTMLInputElement;
                                        target.blur();
                                        setTimeout(() => target.focus(), 0);
                                    }}
                                />
                                <TrashIcon
                                    onClick={() => handleRemoveBox(box.box_id)}
                                    className="w-5 h-5 text-red-600 hover:text-red-500 cursor-pointer"
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}
