/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import {
    CheckIcon,
    ChevronDownIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import {
    fetchNotifications,
    removeNotification,
} from "../../pages/admin/dashboard/api/dashboardApi";
import { AdminNotification } from "../../models/adminDashboard";
import { useNavigate } from "react-router-dom";
import LoadingWheel from "../loadingWheel";
import { fetchClientNotifications } from "../../pages/client/dashboard/api/dashboardApi";
import { convertDateToEST } from "../../utils/date/date_formatter";
import { getAuthData } from "../../utils/auth/authUtils";
import AdminSearchBar from "../admin/searchBar";
import ClientSearchBar from "../client/searchBar";
import { useClient } from "../../utils/client/clientContext";

export const adminDateValues = [
    { id: 1, label: "All", value: "all" },
    {
        id: 2,
        label: "Inbound shipment created",
        value: "Inbound shipment created",
    },
    { id: 3, label: "Requested shipment", value: "Requested shipment" },
    {
        id: 4,
        label: "Requested FBA shipments",
        value: "Requested FBA shipments",
    },
    {
        id: 5,
        label: "Requested shipping name increase",
        value: "Requested shipping name increase",
    },
];

export const clientDateValues = [
    { id: 1, label: "All", value: "all" },
    {
        id: 2,
        label: "Outbound shipment created",
        value: "Outbound shipment created",
    },
    {
        id: 3,
        label: "Inbound shipment checked in",
        value: "Inbound shipment checked in",
    },
];

function NotificationsTypeDropdown({
    selected,
    setSelected,
}: {
    selected: {
        id: number;
        label: string;
        value: string;
    };
    setSelected: React.Dispatch<
        React.SetStateAction<{
            id: number;
            label: string;
            value: string;
        }>
    >;
}) {
    const clientType = getAuthData()?.userRole;

    const dateValues =
        clientType === "client" ? clientDateValues : adminDateValues;

    return (
        <Listbox value={selected} onChange={setSelected}>
            <div className="relative">
                <ListboxButton className="relative cursor-default rounded-md bg-white h-8 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6">
                    <span className="block truncate text-xs font-medium">
                        {selected.label}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                        />
                    </span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute right-0 z-20 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-xs"
                >
                    {dateValues.map((value) => (
                        <ListboxOption
                            key={value.id}
                            value={value}
                            className={({ active, selected }) =>
                                `group relative cursor-default select-none py-2 pl-3 pr-9
                            ${
                                active
                                    ? "bg-accent text-white"
                                    : selected
                                    ? "text-accent font-semibold"
                                    : "text-gray-900"
                            }
                            `
                            }
                        >
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-accent group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon
                                    aria-hidden="true"
                                    className="h-3 w-3"
                                />
                            </span>
                            <span className="block truncate font-normal group-data-[selected]:font-semibold group-data-[focus]:text-white">
                                {value.label}
                            </span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    );
}

function NotificationsModal({
    openNotificationsModal,
    setOpenNotificationsModal,
    client_type = "admin",
}: {
    openNotificationsModal: boolean;
    setOpenNotificationsModal: React.Dispatch<React.SetStateAction<boolean>>;
    client_type?: string;
}) {
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const navigate = useNavigate();
    const [notificationsData, setNotificationsData] = useState<
        AdminNotification[]
    >([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (client_type === "admin") {
                const data = await fetchNotifications();
                if (data.status === "success") {
                    setNotificationsData(data.data.notifications);
                }
            } else {
                const data = await fetchClientNotifications();
                if (data.status === "success") {
                    setNotificationsData(data.data.notifications);
                }
            }
            setLoading(false);
        };
        if (openNotificationsModal) {
            fetchData();
        }
    }, [openNotificationsModal]);

    const [deletingNotifications, setDeletingNotifications] = useState<
        string[]
    >([]);

    const handleRemove = async (
        e: React.MouseEvent<SVGSVGElement, MouseEvent>,
        notificationId: string
    ) => {
        e.stopPropagation();
        setDeletingNotifications((prev) => [...prev, notificationId]);
        const data = await removeNotification(notificationId);
        if (data.status === "success") {
            setNotificationsData((prevNotifications) =>
                prevNotifications.filter(
                    (notification) =>
                        notification.notification_id !== notificationId
                )
            );
        }
    };

    const clientType = getAuthData()?.userRole;

    const dateValues =
        clientType === "client" ? clientDateValues : adminDateValues;

    const [selectedType, setSelectedType] = useState(dateValues[0]);

    const filteredNotifications = notificationsData.filter((notification) => {
        if (selectedType.value === "all") {
            return true; // Show all notifications if 'All' is selected
        }
        return notification.title === selectedType.value;
    });

    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");

    return (
        <Dialog
            open={openNotificationsModal}
            onClose={() => setOpenNotificationsModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-3/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenNotificationsModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Notifications
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            <div className="flex flex-row items-start space-x-3">
                                {clientType === "client" ? (
                                    <ClientSearchBar
                                        setData={setNotificationsData}
                                        setLoading={setLoading}
                                        handleRefetch={() => {}}
                                        page="notifications"
                                        sub_page=""
                                    />
                                ) : (
                                    <AdminSearchBar
                                        setData={setNotificationsData}
                                        setLoading={setLoading}
                                        handleRefetch={() => {}}
                                        page="notifications"
                                        sub_page=""
                                        user_id={null}
                                        keywords={keywords}
                                        setKeywords={setKeywords}
                                        inputValue={inputValue}
                                        setInputValue={setInputValue}
                                        refetchSearchedData={false}
                                    />
                                )}
                                <NotificationsTypeDropdown
                                    selected={selectedType}
                                    setSelected={setSelectedType}
                                />
                            </div>
                            {!loading ? (
                                <div className="divide-y">
                                    {filteredNotifications.map(
                                        (notification) => (
                                            <div
                                                key={
                                                    notification.notification_id
                                                }
                                                onClick={() => {
                                                    client_type === "admin" &&
                                                        setUserId(
                                                            new URLSearchParams(
                                                                notification.href.split(
                                                                    "?"
                                                                )[1]
                                                            ).get("user_id")
                                                        );
                                                    navigate(notification.href);
                                                }}
                                                className="flex flex-row justify-between py-2 items-center hover:bg-gray-100 cursor-pointer transition-all"
                                            >
                                                <div className="flex flex-col">
                                                    <span className="text-xs font-semibold">
                                                        {notification.title}
                                                    </span>
                                                    <span className="text-xs text-gray-600">
                                                        {notification.subtitle}
                                                    </span>
                                                    <span className="text-xs text-gray-600">
                                                        {convertDateToEST(
                                                            notification.created_at
                                                        )}
                                                    </span>
                                                </div>
                                                {deletingNotifications.includes(
                                                    notification.notification_id
                                                ) ? (
                                                    <LoadingWheel
                                                        color="black"
                                                        size="small"
                                                    />
                                                ) : (
                                                    <XMarkIcon
                                                        onClick={(e) =>
                                                            handleRemove(
                                                                e,
                                                                notification.notification_id
                                                            )
                                                        }
                                                        className="w-4 h-4 text-gray-600 hover:text-gray-500"
                                                    />
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                            ) : (
                                <div className="flex flex-row items-center justify-center pt-20">
                                    <LoadingWheel />
                                </div>
                            )}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default NotificationsModal;
