/** @format */

import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Results from "../../../../../../models/results";
import {
    calculateOffset,
    getOffset,
} from "../../../../../../utils/pagination/calculateOffset";
import { formatMoney } from "../../../../../../utils/price/formatMoney";
import { formatDateFromDb } from "../../../../../../utils/date/date_formatter";
import { Order } from "../../../../../../models/order";
import { PaginationResults } from "../../../../../../utils/pagination/paginationResults";
import useCellSelection from "../../../../../../hooks/useCellSelection";
import { StatusTag } from "../../../../../../components/statusTags";
import { useCheckInDatesModal } from "../../../../../../hooks/useCheckInDates";
import CheckInDatesModal from "../../../../../../components/modals/checkInDatesModal";
import { SmallCopyButton } from "../../../../../../components/copyButton";

interface ClientOutboundTableProps {
    outboundOrders: Order[];
    results: Results;
    offset: number;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
}

function ClientOutboundTable({
    outboundOrders,
    results,
    offset,
    setOffset,
}: ClientOutboundTableProps) {
    const [searchParams] = useSearchParams();

    const [loading] = useState(false);

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        switch (columnIndex) {
            case 6:
                return order.quantity_shipped;
            case 10:
                return order.unit_cost;
            case 11:
                return order.list_price;
            case 12:
                return order.shipment_value;
            default:
                return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection([], getCellValue, [6, 10, 11, 12]);

    const {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    } = useCheckInDatesModal();

    return (
        <div className="flex flex-col">
            {!loading && outboundOrders && outboundOrders.length > 0 ? (
                <>
                    <div className="max-h-[520px] overflow-y-auto overflow-x-scroll relative scrollbar">
                        <table
                            onMouseUp={handleMouseUp}
                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                        >
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    <th className="py-2 sm:min-w-32 min-w-32">
                                        Status
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Date
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Location
                                    </th>
                                    <th className="py-2 min-w-36">Supplier</th>
                                    <th className="py-2 min-w-36">Order #</th>
                                    <th className="py-2 sm:min-w-48 min-w-48">
                                        Item Name
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Brand SKU
                                    </th>
                                    <th className="py-2 sm:min-w-24 min-w-20">
                                        Size
                                    </th>
                                    <th className="py-2 sm:min-w-44 min-w-32">
                                        Quantity shipped
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Color
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        ASIN
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Amazon SKU
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Unit cost
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        List price
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Shipment value
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Notes
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {outboundOrders &&
                                    outboundOrders.map((order, rowIndex) => (
                                        <tr
                                            key={order.order_id}
                                            className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                        >
                                            <td className="py-2.5 pr-4 whitespace-nowrap">
                                                <StatusTag
                                                    status={order.unit_status}
                                                    quantity_received={
                                                        order.quantity_received
                                                    }
                                                    quantity={order.quantity}
                                                    fulfilled_by={
                                                        order.fulfilled_by
                                                    }
                                                    handleClick={(e) => {
                                                        e.stopPropagation();
                                                        handleViewDates(order);
                                                    }}
                                                    main_status={order.status}
                                                />
                                            </td>
                                            <td className="py-2.5">
                                                {formatDateFromDb(order.date)}
                                            </td>
                                            <td className="">
                                                {Array.from(
                                                    new Set(
                                                        order.check_ins
                                                            .map(
                                                                (checkIn) =>
                                                                    checkIn.location
                                                            )
                                                            .filter(
                                                                (location) =>
                                                                    location !==
                                                                    null
                                                            )
                                                    )
                                                ).join(", ")}
                                            </td>

                                            <td className="">
                                                {order.retailer}
                                            </td>
                                            <td className="">
                                                {order.order_number}
                                            </td>
                                            <td className="">
                                                {order.item_name}
                                            </td>
                                            <td className="">
                                                {order.brand_sku}
                                            </td>
                                            <td className="">{order.size}</td>
                                            <td
                                                onMouseDown={() =>
                                                    handleMouseDown(
                                                        rowIndex,
                                                        6,
                                                        outboundOrders
                                                    )
                                                }
                                                onMouseEnter={() =>
                                                    handleMouseEnter(
                                                        rowIndex,
                                                        6,
                                                        outboundOrders
                                                    )
                                                }
                                                onClick={() =>
                                                    handleSingleClick(
                                                        rowIndex,
                                                        6,
                                                        outboundOrders
                                                    )
                                                }
                                                className={`${
                                                    selectedCells.some(
                                                        (cell) =>
                                                            cell.rowIndex ===
                                                                rowIndex &&
                                                            cell.columnIndex ===
                                                                6
                                                    )
                                                        ? "bg-blue-200 select-none"
                                                        : "select-none"
                                                }`}
                                            >
                                                {order.quantity_shipped}
                                            </td>
                                            <td className="">{order.color}</td>
                                            <td className="text-accent cursor-pointer">
                                                <div className="flex flex-row items-center">
                                                    <span
                                                        onClick={() =>
                                                            window.open(
                                                                "https://www.amazon.com/dp/" +
                                                                    order.asin,
                                                                "_blank"
                                                            )
                                                        }
                                                    >
                                                        {order.asin}
                                                    </span>
                                                    <span className="mt-1">
                                                        <SmallCopyButton
                                                            textToCopy={
                                                                order.asin
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="">
                                                {order.amazon_sku}
                                            </td>
                                            <td
                                                onMouseDown={() =>
                                                    handleMouseDown(
                                                        rowIndex,
                                                        10,
                                                        outboundOrders
                                                    )
                                                }
                                                onMouseEnter={() =>
                                                    handleMouseEnter(
                                                        rowIndex,
                                                        10,
                                                        outboundOrders
                                                    )
                                                }
                                                onClick={() =>
                                                    handleSingleClick(
                                                        rowIndex,
                                                        10,
                                                        outboundOrders
                                                    )
                                                }
                                                className={`${
                                                    selectedCells.some(
                                                        (cell) =>
                                                            cell.rowIndex ===
                                                                rowIndex &&
                                                            cell.columnIndex ===
                                                                10
                                                    )
                                                        ? "bg-blue-200 select-none"
                                                        : "select-none"
                                                }`}
                                            >
                                                {formatMoney(order.unit_cost)}
                                            </td>
                                            <td
                                                onMouseDown={() =>
                                                    handleMouseDown(
                                                        rowIndex,
                                                        11,
                                                        outboundOrders
                                                    )
                                                }
                                                onMouseEnter={() =>
                                                    handleMouseEnter(
                                                        rowIndex,
                                                        11,
                                                        outboundOrders
                                                    )
                                                }
                                                onClick={() =>
                                                    handleSingleClick(
                                                        rowIndex,
                                                        11,
                                                        outboundOrders
                                                    )
                                                }
                                                className={`${
                                                    selectedCells.some(
                                                        (cell) =>
                                                            cell.rowIndex ===
                                                                rowIndex &&
                                                            cell.columnIndex ===
                                                                11
                                                    )
                                                        ? "bg-blue-200 select-none"
                                                        : "select-none"
                                                }`}
                                            >
                                                {formatMoney(order.list_price)}
                                            </td>
                                            <td
                                                onMouseDown={() =>
                                                    handleMouseDown(
                                                        rowIndex,
                                                        12,
                                                        outboundOrders
                                                    )
                                                }
                                                onMouseEnter={() =>
                                                    handleMouseEnter(
                                                        rowIndex,
                                                        12,
                                                        outboundOrders
                                                    )
                                                }
                                                onClick={() =>
                                                    handleSingleClick(
                                                        rowIndex,
                                                        12,
                                                        outboundOrders
                                                    )
                                                }
                                                className={`${
                                                    selectedCells.some(
                                                        (cell) =>
                                                            cell.rowIndex ===
                                                                rowIndex &&
                                                            cell.columnIndex ===
                                                                12
                                                    )
                                                        ? "bg-blue-200 select-none"
                                                        : "select-none"
                                                }`}
                                            >
                                                {formatMoney(
                                                    order.shipment_value
                                                )}
                                            </td>
                                            <td className="">
                                                {order.user_notes}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <PaginationResults results={results} />
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <button
                                onClick={() =>
                                    setOffset((prevOffset) => prevOffset - 1)
                                }
                                disabled={offset === 0}
                                className={`${
                                    offset === 0
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Previous
                            </button>
                            <button
                                onClick={() =>
                                    setOffset((prevOffset) => prevOffset + 1)
                                }
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                className={`${
                                    results.showing_to >= results.total_results
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0 ml-3`}
                            >
                                Next
                            </button>
                        </div>
                    </nav>
                    {selectedCells.length > 0 && (
                        <span className="text-sm text-gray-900 flex flex-row items-center">
                            {"Sum: "}
                            {sumSelectedQuantities.map(
                                ({ columnIndex, sum }) => (
                                    <>
                                        {sum > 0 && (
                                            <p
                                                key={columnIndex}
                                                className="pl-1"
                                            >
                                                {columnIndex !== 10 &&
                                                columnIndex !== 11 &&
                                                columnIndex !== 12
                                                    ? sum
                                                    : formatMoney(sum)}
                                            </p>
                                        )}
                                    </>
                                )
                            )}
                        </span>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-10 justify-center">
                    <span className="text-sm text-gray-500">
                        User has no outbound shipments
                    </span>
                </div>
            )}

            <CheckInDatesModal
                openCheckInDatesModal={openCheckInDatesModal}
                setOpenCheckInDatesModal={setOpenCheckInDatesModal}
                selectedOrder={selectedOrder}
                handleRefetch={() => {}}
            />
        </div>
    );
}

export default ClientOutboundTable;
