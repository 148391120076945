/** @format */

import {
    BuildingOfficeIcon,
    CreditCardIcon,
    UserIcon,
    UsersIcon,
} from "@heroicons/react/20/solid";

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

interface TabSelectorProps {
    tabs: {
        name: string;
        href: string;
        icon: React.ForwardRefExoticComponent<
            Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
                title?: string;
                titleId?: string;
            } & React.RefAttributes<SVGSVGElement>
        >;
        current: boolean;
        value: string;
    }[];
    handleTabChange: (value: string) => void;
    selectedTab: string;
}

export default function TabSelector({
    tabs,
    handleTabChange,
    selectedTab,
}: TabSelectorProps) {
    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    // defaultValue={tabs.find((tab) => tab.current).name}
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                        {tabs.map((tab) => (
                            <div
                                key={tab.name}
                                onClick={() => handleTabChange(tab.value)}
                                className={`${
                                    selectedTab === tab.value
                                        ? "border-accentdark text-accent"
                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                } group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium cursor-pointer`}
                            >
                                <tab.icon
                                    aria-hidden="true"
                                    className={`${
                                        selectedTab === tab.value
                                            ? "text-accent"
                                            : "text-gray-400 group-hover:text-gray-500"
                                    } -ml-0.5 mr-2 size-5
                                    `}
                                />
                                <span>{tab.name}</span>
                            </div>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    );
}
