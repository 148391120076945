import Cookies from "js-cookie";


export const downloadShipmentPdf = async (shipmentId: string | undefined) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/shipments/requested/label/${shipmentId}`, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + Cookies.get("jwt")
            }
        });

        if (!response.ok) {
            throw new Error(`Failed to download PDF: ${response.statusText}`);
        }
        // Convert the response to a blob
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Open the PDF in a new tab
        window.open(url, '_blank');

        // Trigger download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `shipment_${shipmentId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Clean up the URL object after the download and view
        window.URL.revokeObjectURL(url);
        return true
    } catch (error) {
        return false
    }
}

export const getShipmentPdfUrl = async (shipmentId: string | undefined): Promise<string | null> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/shipments/requested/label/${shipmentId}`, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + Cookies.get("jwt")
            }
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch PDF: ${response.statusText}`);
        }

        // Convert the response to a blob
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Return the generated URL
        return url;

    } catch (error) {
        console.error("Error fetching the PDF:", error);
        return null;
    }
};

