/** @format */

import { useState } from "react";
import { InputField } from "../../../components/inputField";
import LoadingWheel from "../../../components/loadingWheel";
import { AdminAccountData } from "../../../models/accountData";
import { capitalizeFirstLetter } from "../../../utils/strings/string";
import { TrashIcon } from "@heroicons/react/24/outline";
import { addAdminAccount, deleteAdminAccount } from "../api/settingsApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import { concat } from "lodash";

function ManageAdminAccounts({
    adminAccounts,
    handleRefetch,
}: {
    adminAccounts: AdminAccountData[];
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [deleteLoadings, setDeleteLoadings] = useState<string[]>([]);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleAdd = async () => {
        setLoading(true);
        const data = await addAdminAccount(email, password);
        if (data.status === "success") {
            showNotification("Added admin account", "", "success");
            handleRefetch();
        } else {
            showNotification("Failed to add account", data.message, "error");
        }
        setLoading(false);
    };

    const handleDelete = async (emailToDelete: string) => {
        setDeleteLoadings((prev) => concat(prev, [emailToDelete]));
        const data = await deleteAdminAccount(emailToDelete);
        if (data.status === "success") {
            showNotification("Added admin account", "", "success");
            handleRefetch();
        } else {
            showNotification("Failed to add account", data.message, "error");
        }
        setDeleteLoadings((prev) =>
            prev.filter((email) => email !== emailToDelete)
        );
    };

    return (
        <div className="bg-white border border-gray-200 shadow-sm sm:rounded-lg lg:w-3/5">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Manage admin accounts
                </h3>
                <div className="flex flex-col lg:flex-row lg:items-end lg:space-x-4 pt-3">
                    <div className="flex flex-row items-center space-x-4 w-full">
                        <InputField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder=""
                        />
                        <InputField
                            label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder=""
                        />
                    </div>
                    <button
                        disabled={loading}
                        onClick={() => handleAdd()}
                        className="mt-3 lg:w-44 w-full items-center justify-center rounded-md bg-accent px-3 h-9 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                    >
                        {loading ? (
                            <LoadingWheel color="white" size="small" />
                        ) : (
                            <>Add account</>
                        )}
                    </button>
                </div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900 pt-5">
                    Manage admin accounts
                </h3>

                <div className="flex flex-col space-y-3">
                    {adminAccounts.map((account) => (
                        <div className="flex flex-row justify-between items-center">
                            <div className="flex flex-col">
                                <span>{account.email}</span>
                                <span className="text-gray-600 text-sm">
                                    {capitalizeFirstLetter(account.type)}
                                </span>
                            </div>
                            {deleteLoadings.includes(account.email) ? (
                                <LoadingWheel size="small" />
                            ) : (
                                <>
                                    {account.type != "owner" ? (
                                        <TrashIcon
                                            onClick={() =>
                                                handleDelete(account.email)
                                            }
                                            className="w-5 h-5 text-red-500 hover:text-red-400"
                                        />
                                    ) : null}
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ManageAdminAccounts;
