/** @format */

import { useEffect, useState } from "react";
import { Invoice } from "../models/invoices";
import Results from "../models/results";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useCellSelection from "../hooks/useCellSelection";
import { PaymentStatusTag } from "../components/statusTags";
import { formatDateFromDb } from "../utils/date/date_formatter";
import { formatMoney } from "../utils/price/formatMoney";
import { PaginationResults } from "../utils/pagination/paginationResults";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { useClient } from "../utils/client/clientContext";

const tableHeaders = [
    {
        key: "payment_status",
        label: "Payment status",
        className: "sm:min-w-32 min-w-32",
    },
    { key: "full_name", label: "Client", className: "min-w-32" },
    { key: "invoice_date", label: "Invoice date", className: "min-w-32" },
    {
        key: "invoice_period",
        label: "Invoice period",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "total_saved", label: "Total saved", className: "min-w-32" },
    { key: "total", label: "Total", className: "min-w-32" },
    { key: "view", label: "", className: "" },
];

function InvoicesTable({
    invoices,
    results,
    setSelectedPastInvoice,
    setTotalSum,
}: {
    invoices: Invoice[];
    results: Results;
    setSelectedPastInvoice: React.Dispatch<
        React.SetStateAction<Invoice | null>
    >;
    setTotalSum?: React.Dispatch<React.SetStateAction<string>>;
}) {
    const [searchParams] = useSearchParams();

    const { user_id } = useClient();

    const isAdmin = () => window.location.href.includes("admin");

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        switch (columnIndex) {
            case 3:
                return order.total_saved;
            case 4:
                return order.total;
            default:
                return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection([], getCellValue, [3, 4]);

    const hiddenHeaders: string[] = [];

    const displayedHeaders = tableHeaders.filter(
        (header) =>
            !hiddenHeaders.includes(header.key) &&
            (header.key !== "full_name" || (isAdmin() && !user_id))
    );

    useEffect(() => {
        const formattedTotal = sumSelectedQuantities
            .filter(({ sum }) => sum > 0)
            .map(({ columnIndex, sum }) =>
                tableHeaders &&
                [
                    "unit_cost",
                    "list_price",
                    "shipment_value",
                    "total_saved",
                    "total",
                ].includes(tableHeaders[columnIndex].key)
                    ? formatMoney(sum)
                    : sum.toString()
            )
            .join(", ");

        setTotalSum && setTotalSum(formattedTotal);
    }, [sumSelectedQuantities]);

    return (
        <div>
            {invoices && invoices.length > 0 ? (
                <>
                    <div
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar`}
                    >
                        <table
                            onMouseUp={handleMouseUp}
                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                        >
                            <thead>
                                <tr className="text-left text-sm">
                                    {displayedHeaders.map((header, index) => (
                                        <th
                                            key={index}
                                            className={`py-2 ${header.className}`}
                                        >
                                            {header.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map((invoice, rowIndex) => (
                                    <tr
                                        key={rowIndex}
                                        onClick={() =>
                                            setSelectedPastInvoice(invoice)
                                        }
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        <td className="py-2.5">
                                            <PaymentStatusTag
                                                status={invoice.payment_status}
                                                value={
                                                    !invoice.invoice_url
                                                        ? "waiting for invoice url"
                                                        : ""
                                                }
                                            />
                                        </td>
                                        {isAdmin() &&
                                            !user_id &&
                                            user_id !== "" && (
                                                <td>{invoice.full_name}</td>
                                            )}
                                        <td className="">
                                            {invoice.invoice_date
                                                ? formatDateFromDb(
                                                      invoice.invoice_date
                                                  )
                                                : "None"}
                                        </td>
                                        <td className="">
                                            {formatDateFromDb(
                                                invoice.start_date
                                            )}
                                            {" - "}
                                            {invoice.end_date
                                                ? formatDateFromDb(
                                                      invoice.end_date
                                                  )
                                                : "Today"}
                                        </td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    3,
                                                    invoices
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    3,
                                                    invoices
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    3,
                                                    invoices
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 3
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {invoice.status === "open"
                                                ? "Press to view"
                                                : formatMoney(
                                                      invoice.total_saved
                                                  )}
                                        </td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    4,
                                                    invoices
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    4,
                                                    invoices
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    4,
                                                    invoices
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 4
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {invoice.status === "open"
                                                ? "Press to view"
                                                : formatMoney(invoice.total)}
                                        </td>
                                        <td className="text-right">
                                            <a
                                                href={`/admin/clients/${invoice.user_id}/invoices/${invoice.invoice_id}`}
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                className="text-accent hover:text-accenthighlight"
                                            >
                                                View
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <PaginationResults results={results} />
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/admin/billing?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/admin/billing?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center text-sm text-gray-500">
                    No past invoices.
                </div>
            )}
        </div>
    );
}

export default InvoicesTable;
