/** @format */

import {
    PencilSquareIcon,
    PlusCircleIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import DashboardStats from "../../../models/dashboardStats";
import { formatMoney } from "../../../utils/price/formatMoney";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TaxPopup } from "./taxPopup";
import { removeClientNotification, updateTax } from "./api/dashboardApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import {
    AdminNotification,
    WarehouseAddress,
} from "../../../models/adminDashboard";
import LoadingWheel from "../../../components/loadingWheel";
import NotificationsModal from "../../../components/modals/notificationsModal";
import { convertDateToEST } from "../../../utils/date/date_formatter";
import CopyButton from "../../../components/copyButton";

export default function OverviewCards({
    dashboardOverviewStats,
    warehouseAddress,
    notificationsData,
    setNotificationsData,
    handleRefetch,
}: {
    dashboardOverviewStats: DashboardStats | null;
    warehouseAddress: WarehouseAddress | null;
    notificationsData: AdminNotification[];
    setNotificationsData: React.Dispatch<
        React.SetStateAction<AdminNotification[]>
    >;
    handleRefetch: () => void;
}) {
    const navigate = useNavigate();
    const taxButtonRef = useRef<HTMLButtonElement>(null);

    const [tax, setTax] = useState("");

    const [isTaxOpen, setIsTaxOpen] = useState<boolean>(false);

    const { showNotification } = useNotification();

    const toggleTax = () => {
        setIsTaxOpen((prevState) => !prevState);
    };

    const [taxLoading, setTaxLoading] = useState(false);

    const handleUpdateTax = async () => {
        setTaxLoading(true);
        const data = await updateTax(tax);
        if (data.status === "success") {
            handleRefetch();
            toggleTax();
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setTaxLoading(false);
    };

    const [deletingNotifications, setDeletingNotifications] = useState<
        string[]
    >([]);

    const [totalNotificationsCount, setTotalNotificationsCount] = useState(
        notificationsData.length > 0 ? notificationsData[0].total_count : 0
    );

    useEffect(() => {
        setTotalNotificationsCount(notificationsData.length);
    }, [notificationsData]);

    const handleRemove = async (
        e: React.MouseEvent<SVGSVGElement, MouseEvent>,
        notificationId: string
    ) => {
        setDeletingNotifications((prev) => [...prev, notificationId]);
        e.stopPropagation();
        const data = await removeClientNotification(notificationId);
        if (data.status === "success") {
            setNotificationsData((prevNotifications) =>
                prevNotifications.filter(
                    (notification) =>
                        notification.notification_id !== notificationId
                )
            );
            setTotalNotificationsCount((prev) => prev - 1);
        }
    };

    const [deleteAllLoading, setDeleteAllLoading] = useState(false);
    const handleRemoveAllNotifications = async () => {
        setDeleteAllLoading(true);
        const data = await removeClientNotification("delete_all", true);
        if (data.status === "success") {
            setTotalNotificationsCount(0);
            setNotificationsData([]);
        } else {
            showNotification(
                "Failed to delete all notifications",
                data.message,
                "error"
            );
        }
        setDeleteAllLoading(false);
    };

    const [openNotificationsModal, setOpenNotificationsModal] = useState(false);

    const fullAddress = [
        warehouseAddress?.street1,
        warehouseAddress?.street2,
        warehouseAddress?.city,
        warehouseAddress?.state,
        warehouseAddress?.zip,
    ]
        .filter(Boolean)
        .join(", ");

    return (
        <div className="flex flex-col sm:flex-row sm:space-x-10 sm:space-y-0 pb-10 sm:pb-0 sm:h-full space-y-4">
            <div className="flex flex-col sm:h-full">
                <div className="pb-2">
                    <span className="text-xl font-bold">Overview</span>
                </div>
                <div className="w-full h-px bg-gray-200"></div>
                <div className="flex flex-col sm:flex-row pt-4 sm:space-x-10 sm:space-y-0 space-y-4">
                    <div className="bg-gray-50 sm:rounded-lg w-full relative min-h-72">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">
                                Shipment Address
                            </h3>
                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                <div className="flex flex-col xl:flex-row ">
                                    <p className="xl:w-32 hidden xl:block">
                                        Shipping Name:
                                    </p>
                                    <p
                                        onClick={() =>
                                            navigate("/inbound/shipping-names")
                                        }
                                        className="xl:text-right text-accent cursor-pointer"
                                    >
                                        {warehouseAddress?.first_name}{" "}
                                        {warehouseAddress?.last_name}
                                    </p>
                                </div>
                                <div className="flex flex-col xl:flex-row ">
                                    <p className="xl:w-32 hidden xl:block">
                                        Street1:
                                    </p>
                                    <p>{warehouseAddress?.street1}</p>
                                </div>
                                <div className="flex xl:flex-row ">
                                    <p className="xl:w-32 hidden xl:block">
                                        Street2:
                                    </p>
                                    <p>{warehouseAddress?.street2}</p>
                                </div>
                                <div className="flex flex-col xl:flex-row ">
                                    <p className="xl:w-32 hidden xl:block">
                                        City:
                                    </p>
                                    <p>{warehouseAddress?.city}</p>
                                </div>
                                <div className="flex flex-col xl:flex-row ">
                                    <p className="xl:w-32 hidden xl:block">
                                        Zip:
                                    </p>
                                    <p>{warehouseAddress?.zip}</p>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-row items-center">
                                        <p className="xl:w-32 hidden xl:block">
                                            State:
                                        </p>
                                        <p>{warehouseAddress?.state}</p>
                                    </div>
                                    <div className="flex">
                                        <CopyButton textToCopy={fullAddress} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 absolute bottom-4 text-sm text-gray-600">
                            To ensure accurate processing and avoid any
                            misplacement, all shipments to our warehouse must be
                            addressed with your full name
                        </div>
                    </div>

                    <div className="flex flex-col space-y-4 w-full h-full">
                        <div className="bg-gray-50 sm:rounded-lg w-full h-full">
                            <div className="px-4 py-5 sm:p-6 flex justify-between flex-col h-full">
                                <div className="">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                                        Total spent
                                    </h3>

                                    <div className="text-xl pt-4">
                                        {formatMoney(
                                            dashboardOverviewStats?.total_spent
                                        )}
                                    </div>
                                </div>

                                <div className="text-sm text-gray-500 pt-4">
                                    On inventory
                                </div>
                            </div>
                        </div>

                        <div className="bg-gray-50 sm:rounded-lg w-full relative h-full">
                            <div className="px-4 py-5 sm:p-6 flex justify-between flex-col h-full">
                                <div>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                                        Total saved
                                    </h3>
                                    {dashboardOverviewStats?.tax_rate ? (
                                        <div className="text-xl pt-4">
                                            {formatMoney(
                                                dashboardOverviewStats?.total_saved
                                            )}
                                        </div>
                                    ) : (
                                        <div className="relative">
                                            <button
                                                ref={taxButtonRef}
                                                onClick={toggleTax}
                                                className="flex flex-row items-center pt-4 text-accent text-base"
                                            >
                                                <PlusCircleIcon className="w-5 h-5 pt-0.5" />
                                                <span className="pl-1">
                                                    Add your tax rate
                                                </span>
                                            </button>
                                            <TaxPopup
                                                isOpen={isTaxOpen}
                                                onClose={() =>
                                                    setIsTaxOpen(false)
                                                }
                                                setTax={setTax}
                                                tax={tax}
                                                taxButtonRef={taxButtonRef}
                                                handleUpdateTax={
                                                    handleUpdateTax
                                                }
                                                taxLoading={taxLoading}
                                                type="set"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="text-sm text-gray-500 pt-4">
                                    {dashboardOverviewStats?.tax_rate
                                        ? "Saved in sales tax by shipping to our warehouse"
                                        : "Add your state's tax rate to see your savings."}
                                </div>
                            </div>
                            {dashboardOverviewStats?.tax_rate ? (
                                <div className="absolute top-3 right-3">
                                    <div className="relative">
                                        <button
                                            ref={taxButtonRef}
                                            onClick={toggleTax}
                                            className="text-sm text-accent flex flex-row items-center"
                                        >
                                            <PencilSquareIcon className="w-4 h-4" />{" "}
                                            <span className="pl-1">
                                                Change tax rate
                                            </span>
                                        </button>
                                        <TaxPopup
                                            isOpen={isTaxOpen}
                                            onClose={() => setIsTaxOpen(false)}
                                            setTax={setTax}
                                            tax={tax}
                                            taxButtonRef={taxButtonRef}
                                            handleUpdateTax={handleUpdateTax}
                                            taxLoading={taxLoading}
                                            type="edit"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
                <NotificationsModal
                    openNotificationsModal={openNotificationsModal}
                    setOpenNotificationsModal={setOpenNotificationsModal}
                    client_type="client"
                />
            </div>
            <div className="sm:w-2/6 w-full flex flex-col sm:h-full">
                <div className="flex flex-row justify-between w-full items-center mb-2">
                    <h2 className="font-bold text-xl">Notifications</h2>
                    <div className="bg-red-500 min-w-5 h-5 px-1 text-white text-xs items-center flex justify-center font-medium rounded-md">
                        {totalNotificationsCount}
                    </div>
                </div>
                <div className="w-full h-px bg-gray-200"></div>
                {notificationsData.length > 0 ? (
                    <div className="divide-y pt-4">
                        {notificationsData.slice(0, 5).map((notification) => (
                            <div
                                key={notification.notification_id}
                                onClick={() =>
                                    (window.location.href = notification.href)
                                }
                                className="flex flex-row justify-between py-2 items-center hover:bg-gray-100 cursor-pointer transition-all"
                            >
                                <div className="flex flex-col">
                                    <span className="text-xs font-semibold">
                                        {notification.title}
                                    </span>
                                    <span className="text-xs text-gray-600">
                                        {notification.subtitle}
                                    </span>
                                    <span className="text-xs text-gray-600">
                                        {convertDateToEST(
                                            notification.created_at
                                        )}
                                    </span>
                                </div>
                                {deletingNotifications.includes(
                                    notification.notification_id
                                ) ? (
                                    <LoadingWheel color="black" size="small" />
                                ) : (
                                    <XMarkIcon
                                        onClick={(e) =>
                                            handleRemove(
                                                e,
                                                notification.notification_id
                                            )
                                        }
                                        className="w-4 h-4 text-gray-600 hover:text-gray-500"
                                    />
                                )}
                            </div>
                        ))}
                        <div className="flex flex-row justify-between pt-1">
                            <button
                                onClick={() => setOpenNotificationsModal(true)}
                                className="text-xs text-accent font-medium"
                            >
                                View all
                            </button>
                            <button
                                disabled={deleteAllLoading}
                                onClick={() => handleRemoveAllNotifications()}
                                className="text-xs text-gray-900 font-medium flex flex-row items-center"
                            >
                                {deleteAllLoading && (
                                    <span className="pr-2">
                                        <LoadingWheel />
                                    </span>
                                )}
                                Clear all
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="pt-4 h-full">
                        <div className="w-full border h-full border-dashed border-gray-300 mt-2 flex justify-center items-center rounded-md">
                            <p className="text-xs text-gray-600">
                                Nothing new here.
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
