/** @format */

import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import TabSelector from "../../../components/tabSelector";
import { useClient } from "../../../utils/client/clientContext";
import { useState } from "react";
import CheckInOrder from "./checkInOrder";
import ReceivedOrders from "./receivedOrders";

const tabs = [
    {
        name: "Mark Received",
        href: "/admin/check-in",
        icon: ClipboardDocumentCheckIcon,
        current: false,
        value: "check-in",
    },
    {
        name: "Needs Checked In",
        href: "#",
        icon: ClipboardDocumentCheckIcon,
        current: false,
        value: "received",
    },
    {
        name: "Checked In",
        href: "#",
        icon: ClipboardDocumentCheckIcon,
        current: false,
        value: "checked-in",
    },
];

function AdminReceivedPage() {
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const [selectedTab, setSelectedTab] = useState("check-in");

    const handleTabChange = (value: string) => {
        setSelectedTab(value);
    };

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center pb-1">
                    <span className="text-2xl font-bold">
                        {selectedTab === "check-in" ? (
                            <> Check In Packages</>
                        ) : (
                            <> Check In Packages </>
                        )}
                    </span>
                    {/* <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                        {orderCount?.inbound}
                    </span> */}
                </div>

                <TabSelector
                    tabs={tabs}
                    handleTabChange={handleTabChange}
                    selectedTab={selectedTab}
                />

                {selectedTab === "check-in" ? (
                    <CheckInOrder />
                ) : (
                    <ReceivedOrders selectedTab={selectedTab} />
                )}
            </div>
        </div>
    );
}

export default AdminReceivedPage;
