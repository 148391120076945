/** @format */

/** @format */

import { useEffect, useState } from "react";
import { fetchSettings } from "../api/settingsApi";
import {
    Box,
    EmailNotification,
    WarehouseAddress,
} from "../../../models/adminDashboard";
import ManageAdminAccounts from "./manageAdminAccount";
import { AdminAccountData } from "../../../models/accountData";
import LoadingWheel from "../../../components/loadingWheel";
import ManageAddress from "./manageAddress";
import ManageBoxes from "./manageBoxes";
import ManageNotifications from "./manageNotifications";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import ManageEmployeeAccounts from "./manageEmployees";
import { Employee } from "../../../models/employees";
import { getAdminType } from "../../../utils/adminTypes/actions";
import { useNotification } from "../../../utils/notification/notificationContext";
import ManageReferralCredit from "./manageReferralCredit";
import { formatMoney } from "../../../utils/price/formatMoney";

function ClientSettingsPage() {
    const isOwner = getAdminType() === "owner";
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);
    const [adminAccounts, setAdminAccounts] = useState<AdminAccountData[]>([]);
    const [warehouseAddress, setWarehouseAddress] =
        useState<WarehouseAddress | null>(null);
    const [boxes, setBoxes] = useState<Box[]>([]);
    const [emailNotifications, setEmailNotifications] = useState<
        EmailNotification[]
    >([]);

    const [employees, setEmployees] = useState<Employee[]>([]);

    const [creditAmount, setCreditAmount] = useState("");

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchSettings();
            if (data.status === "success") {
                setAdminAccounts(data.data.accounts);
                setWarehouseAddress(data.data.address);
                setBoxes(data.data.boxes);
                setEmailNotifications(data.data.email_notifications);
                setEmployees(data.data.employees);
                setCreditAmount(formatMoney(data.data.credit_amount) || "");
            } else {
            }
            setLoading(false);
        };
        if (isOwner) {
            fetchData();
        } else {
            showNotification(
                "Settings can not be viewed by employees.",
                "",
                "error"
            );
        }
    }, [refetchData]);

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header />

                <span className="text-2xl font-bold">Settings</span>

                <div className="flex pb-10 pt-5">
                    {!loading ? (
                        <div className="flex flex-col space-y-4">
                            <div className="flex flex-col lg:flex-row lg:space-x-10 lg:space-y-0 space-y-4">
                                <ManageAdminAccounts
                                    adminAccounts={adminAccounts}
                                    handleRefetch={handleRefetch}
                                />
                                <ManageBoxes
                                    boxes={boxes}
                                    handleRefetch={handleRefetch}
                                />
                            </div>
                            <div className="flex flex-col lg:flex-row lg:space-x-10 lg:space-y-0 space-y-4">
                                <ManageAddress
                                    warehouseAddress={warehouseAddress}
                                    handleRefetch={handleRefetch}
                                />
                                <ManageNotifications
                                    emailNotifications={emailNotifications}
                                    setEmailNotifications={
                                        setEmailNotifications
                                    }
                                    handleRefetch={handleRefetch}
                                />
                            </div>
                            <div className="flex flex-col lg:flex-row lg:space-x-10 lg:space-y-0 space-y-4">
                                <ManageEmployeeAccounts
                                    employees={employees}
                                    handleRefetch={handleRefetch}
                                />
                                <ManageReferralCredit
                                    creditAmount={creditAmount}
                                    setCreditAmount={setCreditAmount}
                                    handleRefetch={handleRefetch}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col flex-grow items-center justify-center pt-44">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ClientSettingsPage;
