/** @format */

import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingWheel, { SortLoading } from "../components/loadingWheel";
import { StatusTag } from "../components/statusTags";
import useCellSelection from "../hooks/useCellSelection";
import { useCheckInDatesModal } from "../hooks/useCheckInDates";
import useScrollTranslation from "../hooks/useScrollTranslation";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import { useTrackingNumbersModal } from "../hooks/useTrackingNumbers";
import {
    Order,
    OrderStatus,
    ShelfLocations,
    ShipmentQuantities,
} from "../models/order";
import Results from "../models/results";
import { formatDateFromDb } from "../utils/date/date_formatter";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import { PaginationResults } from "../utils/pagination/paginationResults";
import { formatMoney } from "../utils/price/formatMoney";
import ColumnSort from "../utils/sort/columnSort";
import { capitalizeFirstLetter } from "../utils/strings/string";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import TrackingNumbersModal from "../components/modals/trackingNumbersModal";
import CheckInDatesModal from "../components/modals/checkInDatesModal";
import { ClientType } from "../utils/clientTypes/actions";
import { EditOrderModal } from "../pages/client/inbound/inbound-data/editOrderModal";
import { useEffect, useState } from "react";
import { EditInventoryOrderModal } from "../pages/client/inventory/inventory-data/editInventoryOrder";
import { Column } from "./models";
import { TrashIcon } from "@heroicons/react/24/solid";
import { SmallCopyButton } from "../components/copyButton";
import { FaArrowsRotate } from "react-icons/fa6";
import { useClient } from "../utils/client/clientContext";

export const getTableHeaders = (orderStatus: string) => {
    const baseHeaders: Column[] = [
        {
            key: "select",
            label: "Select",
            className: "sm:min-w-10 min-w-10",
            isSortable: false,
            nonMovable: true,
        },
        {
            key: "input_shipping_quantity",
            label: "Shipping quantity",
            className: "sm:min-w-40 min-w-32",
            isSortable: false,
            nonMovable: true,
        },
        {
            key: "input_shelf_location",
            label: "Location",
            className: "sm:min-w-40 min-w-32",
            isSortable: false,
            nonMovable: true,
        },
        {
            key: "status",
            label: "Status",
            className: "sm:min-w-32 min-w-32",
            isSortable: true,
        },
        {
            key: "order_name",
            label: "Client",
            className: "sm:min-w-32 min-w-32",
            isSortable: true,
        },
        {
            key: "fulfilled_by",
            label: "Fulfilled by",
            className: "sm:min-w-32 min-w-32",
            isSortable: true,
        },
        {
            key: "quantity_remaining",
            label: "Inventory Quantity",
            className: "sm:min-w-44 min-w-44",
            isSortable: false,
            isSummable: true,
        },
        {
            key: "location",
            label: "Location",
            className: "sm:min-w-44 min-w-44",
            isSortable: false,
            isSummable: true,
        },
        {
            key: "created_at",
            label: "Date",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
        },
        {
            key: "retailer",
            label: "Supplier",
            className: "min-w-32",
            isSortable: false,
        },
        {
            key: "order_number",
            label: "Order #",
            className: "min-w-32",
            isSortable: false,
        },
        {
            key: "item_name",
            label: "Item Name",
            className: "sm:min-w-48 min-w-48",
            isSortable: false,
        },
        {
            key: "brand_sku",
            label: "Brand SKU",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
        },
        {
            key: "size",
            label: "Size",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
        },
        {
            key: "quantity",
            label: "Quantity Ordered",
            className: "sm:min-w-36 min-w-28 whitespace-nowrap",
            isSortable: false,
            isSummable: true,
        },
        {
            key: "color",
            label: "Color",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
        },
        {
            key: "asin",
            label: "ASIN",
            className: "sm:min-w-32 min-w-28 pl-2",
            isSortable: false,
        },
        {
            key: "amazon_sku",
            label: "Amazon SKU",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
        },
        {
            key: "unit_cost",
            label: "Unit cost",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
            isSummable: true,
        },
        {
            key: "list_price",
            label: "List price",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
            isSummable: true,
        },
        {
            key: "trackings",
            label: "Trackings",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
        },
        {
            key: "shipment_value",
            label: "Shipment Value",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
            isSummable: true,
        },
        {
            key: "user_notes",
            label: "Notes",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
        },
        {
            key: "remove_selected_row",
            label: "",
            className: "text-right",
            isSortable: false,
            nonMovable: true,
        },
        {
            key: "qa",
            label: "Actions",
            className: "text-right",
            isSortable: false,
            nonMovable: true,
        },
    ];

    return baseHeaders;
};

interface OrdersTableProps {
    orderStatus: OrderStatus;
    orders: Order[];
    results: Results | null;
    selectedOrders: Order[];
    setSelectedOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    client_type: ClientType;
    setTotalSum: React.Dispatch<React.SetStateAction<string>>;
    columnOrder: Column[];
    hiddenHeaders: string[];
    shipmentQuantities?: ShipmentQuantities;
    handleQuantityChange?: (orderId: string, value: string) => void;
    shipmentInputName?: string;
    shelfs?: ShelfLocations;
    handleShelfChange?: (orderId: string, value: string) => void;
    filesByOrder?: {
        [orderId: string]: File[];
    };
    handleFileChange?: (orderId: string, files: File[]) => void;
    removeFile?: (orderId: string, fileToRemove: File) => void;
    quantityName?: string;
}

function OrdersTable({
    orderStatus,
    orders,
    results,
    selectedOrders,
    setSelectedOrders,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    client_type,
    setTotalSum,
    columnOrder,
    hiddenHeaders,
    shipmentQuantities,
    handleQuantityChange,
    shipmentInputName,
    shelfs,
    handleShelfChange,
    filesByOrder,
    handleFileChange,
    removeFile,
    quantityName = "Quantity Ordered",
}: OrdersTableProps) {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { user_id } = useClient();

    const isAdmin = () => window.location.href.includes("admin");

    const {
        openTrackingNumbersModal,
        setOpenTrackingNumbersModal,
        selectedTrackingNumbers,
        handleViewTrackings,
    } = useTrackingNumbersModal();

    const {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    } = useCheckInDatesModal();

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setSelectedOrders) {
            if (e.target.checked) {
                setSelectedOrders(orders);
            } else {
                // setAllClientsSelected(false);
                setSelectedOrders([]);
            }
        }
    };

    const handleCheckboxChange = (order: Order) => {
        const orderId = order.order_id;
        if (selectedOrders && setSelectedOrders) {
            if (selectedOrders.some((o) => o.order_id === orderId)) {
                // Remove the order by its id
                setSelectedOrders(
                    selectedOrders.filter((o) => o.order_id !== orderId)
                );
            } else {
                // Add the order using its id
                setSelectedOrders([...selectedOrders, order]);
            }
        }
    };

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const displayedHeaders = columnOrder.filter(
        (header) =>
            !hiddenHeaders.includes(header.key) &&
            (header.key !== "order_name" || (isAdmin() && !user_id))
    );

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        if (displayedHeaders) {
            const key = displayedHeaders[columnIndex].key;
            if (key) return order[key];
            return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection(
        [],
        getCellValue,
        displayedHeaders
            ?.map((column, index) => (column.isSummable ? index : -1))
            .filter((index) => index !== -1) || []
    );

    useEffect(() => {
        const formattedTotal = sumSelectedQuantities
            .filter(({ sum }) => sum > 0)
            .map(({ columnIndex, sum }) =>
                displayedHeaders &&
                ["unit_cost", "list_price", "shipment_value"].includes(
                    displayedHeaders[columnIndex].key
                )
                    ? formatMoney(sum)
                    : sum.toString()
            )
            .join(", ");

        setTotalSum(formattedTotal);
    }, [sumSelectedQuantities]);

    const handleRowClick = (order: Order) => {
        if (client_type === "client") {
            setSelectedEditOrder(order);
        }
    };

    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedEditOrder, setSelectedEditOrder] = useState<Order | null>(
        null
    );

    useEffect(() => {
        if (selectedEditOrder) {
            setOpenEditModal(true);
        }
    }, [selectedEditOrder]);

    useEffect(() => {
        if (!openEditModal) {
            setSelectedEditOrder(null);
        }
        console.log(displayedHeaders);
    }, [openEditModal]);

    return (
        <div className="flex flex-col pt-3">
            {orders && orders.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table
                            onMouseUp={handleMouseUp}
                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                        >
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {displayedHeaders &&
                                        displayedHeaders.map((header) => (
                                            <th
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                            >
                                                {header.key === "select" ? (
                                                    <input
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        onChange={
                                                            handleSelectAll
                                                        }
                                                        checked={
                                                            selectedOrders &&
                                                            orders.length ===
                                                                selectedOrders.length &&
                                                            orders.length > 0
                                                        }
                                                    />
                                                ) : header.key ===
                                                  "quantity" ? (
                                                    <>{quantityName}</>
                                                ) : (
                                                    <>
                                                        {header.key ===
                                                        "input_shipping_quantity"
                                                            ? shipmentInputName
                                                            : header.label}
                                                        <ColumnSort
                                                            header={header}
                                                            sortConfig={
                                                                sortConfig
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody className="relative">
                                {orders.map((order, rowIndex) => (
                                    <tr
                                        key={order.order_id}
                                        className={`relative border-t border-gray-200 text-sm text-gray-500 ${
                                            client_type === "client" &&
                                            "hover:bg-gray-100 cursor-pointer"
                                        } ${
                                            selectedOrders.some(
                                                (o) =>
                                                    o.order_id ===
                                                    order.order_id
                                            )
                                                ? "bg-gray-100"
                                                : ""
                                        }`}
                                        onClick={() => handleRowClick(order)}
                                    >
                                        {displayedHeaders &&
                                            displayedHeaders.map(
                                                (header, colIndex) => (
                                                    <>
                                                        {header.key ===
                                                        "select" ? (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            order
                                                                        )
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        e.stopPropagation()
                                                                    }
                                                                    checked={selectedOrders.some(
                                                                        (o) =>
                                                                            o.order_id ===
                                                                            order.order_id
                                                                    )}
                                                                />
                                                            </td>
                                                        ) : header.key ===
                                                              "input_shipping_quantity" &&
                                                          handleQuantityChange &&
                                                          shipmentQuantities ? (
                                                            <td>
                                                                <input
                                                                    className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                                    type="number"
                                                                    value={
                                                                        shipmentQuantities[
                                                                            order
                                                                                .order_id
                                                                        ] || ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleQuantityChange(
                                                                            order.order_id,
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    onWheel={(
                                                                        e
                                                                    ) => {
                                                                        const target =
                                                                            e.target as HTMLInputElement;
                                                                        target.blur();
                                                                        setTimeout(
                                                                            () =>
                                                                                target.focus(),
                                                                            0
                                                                        );
                                                                    }}
                                                                ></input>
                                                            </td>
                                                        ) : header.key ===
                                                              "input_shelf_location" &&
                                                          handleShelfChange &&
                                                          shelfs ? (
                                                            <td>
                                                                <input
                                                                    className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                                    value={
                                                                        shelfs[
                                                                            order
                                                                                .order_id
                                                                        ] || ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleShelfChange(
                                                                            order.order_id,
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    onWheel={(
                                                                        e
                                                                    ) => {
                                                                        const target =
                                                                            e.target as HTMLInputElement;
                                                                        target.blur();
                                                                        setTimeout(
                                                                            () =>
                                                                                target.focus(),
                                                                            0
                                                                        );
                                                                    }}
                                                                ></input>
                                                            </td>
                                                        ) : header.key ===
                                                          "status" ? (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                            >
                                                                <StatusTag
                                                                    status={
                                                                        order.unit_status
                                                                    }
                                                                    quantity_received={
                                                                        order.quantity_received
                                                                    }
                                                                    quantity={
                                                                        order.quantity
                                                                    }
                                                                    fulfilled_by={
                                                                        order.fulfilled_by
                                                                    }
                                                                    handleClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        handleViewDates(
                                                                            order
                                                                        );
                                                                    }}
                                                                    shipping_labels={
                                                                        order.shipping_labels
                                                                    }
                                                                />
                                                            </td>
                                                        ) : header.key ===
                                                          "fulfilled_by" ? (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                            >
                                                                {capitalizeFirstLetter(
                                                                    order.fulfilled_by
                                                                )}
                                                            </td>
                                                        ) : header.key ===
                                                          "created_at" ? (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                            >
                                                                {formatDateFromDb(
                                                                    order.date
                                                                )}
                                                            </td>
                                                        ) : header.key ===
                                                          "trackings" ? (
                                                            <td
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    handleViewTrackings(
                                                                        order.trackings
                                                                    );
                                                                }}
                                                                className={`${
                                                                    order.trackings &&
                                                                    order
                                                                        .trackings
                                                                        .length >
                                                                        0
                                                                        ? "text-accent cursor-pointer"
                                                                        : "cursor-pointer"
                                                                }`}
                                                            >
                                                                {order.trackings &&
                                                                order.trackings
                                                                    .length > 0
                                                                    ? "View"
                                                                    : "None"}
                                                            </td>
                                                        ) : header.key ===
                                                          "location" ? (
                                                            <td className="">
                                                                {Array.from(
                                                                    new Set(
                                                                        order.check_ins
                                                                            .map(
                                                                                (
                                                                                    checkIn
                                                                                ) =>
                                                                                    checkIn.location
                                                                            )
                                                                            .filter(
                                                                                (
                                                                                    location
                                                                                ) =>
                                                                                    location !==
                                                                                    null
                                                                            )
                                                                    )
                                                                ).join(", ")}
                                                            </td>
                                                        ) : header.key ===
                                                          "remove_selected_row" ? (
                                                            <>
                                                                {orders &&
                                                                    setSelectedOrders && (
                                                                        <td className="sticky right-0 bottom-0 text-right bg-white cursor-pointer">
                                                                            <TrashIcon
                                                                                onClick={() => {
                                                                                    const updatedOrders =
                                                                                        orders.filter(
                                                                                            (
                                                                                                o
                                                                                            ) =>
                                                                                                o.order_id !==
                                                                                                order.order_id
                                                                                        );
                                                                                    setSelectedOrders(
                                                                                        updatedOrders
                                                                                    );
                                                                                }}
                                                                                className="h-5 w-5 text-red-500 hover:text-red-400"
                                                                            />
                                                                        </td>
                                                                    )}
                                                            </>
                                                        ) : header.key ===
                                                          "asin" ? (
                                                            <td className="text-accent cursor-pointer whitespace-nowrap">
                                                                <div className="flex flex-row items-center">
                                                                    <span
                                                                        onClick={() =>
                                                                            window.open(
                                                                                "https://www.amazon.com/dp/" +
                                                                                    order.asin,
                                                                                "_blank"
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            order.asin
                                                                        }
                                                                    </span>
                                                                    {order.asin &&
                                                                        order.asin !==
                                                                            "" && (
                                                                            <span className="mt-1">
                                                                                <SmallCopyButton
                                                                                    textToCopy={
                                                                                        order.asin
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            </td>
                                                        ) : header.key ===
                                                          "qa" ? (
                                                            <td className="w-full">
                                                                <div className="text-right items-end flex justify-end">
                                                                    <FaArrowsRotate
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            navigate(
                                                                                `/inbound/units?openModal=new_inbound_shipment&fulfilled_by=${
                                                                                    order.fulfilled_by
                                                                                }&item_name=${
                                                                                    order.item_name
                                                                                }&order_number=${
                                                                                    order.order_number
                                                                                }&size=${
                                                                                    order.size
                                                                                }&brand_sku=${
                                                                                    order.brand_sku
                                                                                }&asin=${
                                                                                    order.asin
                                                                                }&retailer=${
                                                                                    order.retailer
                                                                                }&color=${
                                                                                    order.color
                                                                                }&unit_cost=${formatMoney(
                                                                                    order.unit_cost
                                                                                )}&list_price=${formatMoney(
                                                                                    order.list_price
                                                                                )}&amazon_sku=${
                                                                                    order.amazon_sku
                                                                                }`
                                                                            );
                                                                        }}
                                                                        className="w-4 h-4 text-accent cursor-pointer hover:text-accenthighlight"
                                                                    />
                                                                </div>
                                                            </td>
                                                        ) : header.isSummable ? (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                                onMouseDown={() =>
                                                                    handleMouseDown(
                                                                        rowIndex,
                                                                        colIndex,
                                                                        orders
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    handleMouseEnter(
                                                                        rowIndex,
                                                                        colIndex,
                                                                        orders
                                                                    )
                                                                }
                                                                onClick={() =>
                                                                    handleSingleClick(
                                                                        rowIndex,
                                                                        colIndex,
                                                                        orders
                                                                    )
                                                                }
                                                                className={`py-2.5 ${
                                                                    selectedCells.some(
                                                                        (
                                                                            cell
                                                                        ) =>
                                                                            cell.rowIndex ===
                                                                                rowIndex &&
                                                                            cell.columnIndex ===
                                                                                colIndex
                                                                    )
                                                                        ? "bg-blue-200 select-none"
                                                                        : "select-none"
                                                                }`}
                                                            >
                                                                {[
                                                                    "unit_cost",
                                                                    "list_price",
                                                                    "shipment_value",
                                                                ].includes(
                                                                    String(
                                                                        header.key
                                                                    )
                                                                ) ? (
                                                                    <>
                                                                        {formatMoney(
                                                                            Number(
                                                                                order[
                                                                                    header.key as keyof Order
                                                                                ]
                                                                            )
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {String(
                                                                            order[
                                                                                header.key as keyof Order
                                                                            ]
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>
                                                        ) : header.key ===
                                                          "quantity_remaining" ? (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                            >
                                                                {order[
                                                                    header.key as keyof Order
                                                                ]
                                                                    ? String(
                                                                          order[
                                                                              header.key as keyof Order
                                                                          ]
                                                                      )
                                                                    : "0"}
                                                            </td>
                                                        ) : (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                            >
                                                                {String(
                                                                    order[
                                                                        header.key as keyof Order
                                                                    ]
                                                                )}
                                                            </td>
                                                        )}
                                                    </>
                                                )
                                            )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {results && (
                        <>
                            <nav
                                aria-label="Pagination"
                                className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                            >
                                <div className="hidden sm:block">
                                    <div className="flex flex-row items-center space-x-3">
                                        <PaginationResults results={results} />
                                        <LimitSelector />
                                    </div>
                                </div>
                                <div className="flex flex-1 justify-between sm:justify-end">
                                    <PaginationLink
                                        href={(function () {
                                            const params = new URLSearchParams(
                                                searchParams
                                            );
                                            // Set the previous page offset
                                            params.set(
                                                "offset",
                                                calculateOffset(
                                                    searchParams,
                                                    "back"
                                                ).toString()
                                            );
                                            return `/admin/${orderStatus}?${params.toString()}`;
                                        })()}
                                        disabled={getOffset(searchParams) === 0}
                                        text="Previous"
                                    />
                                    <PaginationLink
                                        href={(function () {
                                            const params = new URLSearchParams(
                                                searchParams
                                            );
                                            // Set the next page offset
                                            params.set(
                                                "offset",
                                                calculateOffset(
                                                    searchParams,
                                                    "forward"
                                                ).toString()
                                            );
                                            return `/admin/${orderStatus}?${params.toString()}`;
                                        })()}
                                        disabled={
                                            results.showing_to >=
                                            results.total_results
                                        }
                                        text="Next"
                                        className="ml-3"
                                    />
                                </div>
                            </nav>
                        </>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-10 justify-center">
                    {false ? (
                        <LoadingWheel />
                    ) : (
                        <span className="text-sm text-gray-500">
                            User has no inbound orders
                        </span>
                    )}
                </div>
            )}

            <TrackingNumbersModal
                openTrackingNumbersModal={openTrackingNumbersModal}
                setOpenTrackingNumbersModal={setOpenTrackingNumbersModal}
                trackingNumbers={selectedTrackingNumbers}
            />

            <CheckInDatesModal
                openCheckInDatesModal={openCheckInDatesModal}
                setOpenCheckInDatesModal={setOpenCheckInDatesModal}
                selectedOrder={selectedOrder}
                filesByOrder={filesByOrder}
                handleFileChange={handleFileChange}
                removeFile={removeFile}
                handleRefetch={handleRefetch}
                client_type={client_type}
                fulfilledBy={selectedOrder?.fulfilled_by}
            />

            {["client", "client_team_member"].includes(client_type) && (
                <>
                    {orderStatus === "inbound" && (
                        <EditOrderModal
                            inboundId={selectedEditOrder?.order_id}
                            openEditModal={openEditModal}
                            setOpenEditModal={setOpenEditModal}
                            handleRefetch={handleRefetch}
                        />
                    )}
                    {orderStatus === "inventory" && (
                        <EditInventoryOrderModal
                            inventoryOrder={selectedEditOrder}
                            openEditModal={openEditModal}
                            setOpenEditModal={setOpenEditModal}
                            handleRefetch={handleRefetch}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default OrdersTable;
