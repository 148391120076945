/** @format */

import { useSearchParams } from "react-router-dom";
import LoadingWheel, { SortLoading } from "../../../../components/loadingWheel";
import Results from "../../../../models/results";
import {
    calculateOffset,
    getOffset,
} from "../../../../utils/pagination/calculateOffset";
import { EmptyInventoryTable } from "../../../../components/emptyStates";
import { formatMoney } from "../../../../utils/price/formatMoney";
import { StatusTag } from "../../../../components/statusTags";
import { formatDateFromDb } from "../../../../utils/date/date_formatter";
import "../../../../index.css";
import { PaginationLink } from "../../../../utils/pagination/paginationLink";
import { PaginationResults } from "../../../../utils/pagination/paginationResults";
import { useEffect, useState } from "react";
import { EditInventoryOrderModal } from "./editInventoryOrder";
import { useCheckInDatesModal } from "../../../../hooks/useCheckInDates";
import CheckInDatesModal from "../../../../components/modals/checkInDatesModal";
import { Order, ShipmentQuantities } from "../../../../models/order";
import { capitalizeFirstLetter } from "../../../../utils/strings/string";
import LimitSelector from "../../../../utils/pagination/limitSelector";
import ColumnSort from "../../../../utils/sort/columnSort";
import useScrollTranslation from "../../../../hooks/useScrollTranslation";
import { sortableColumns } from "../../../../hooks/useSort";
import useCellSelection from "../../../../hooks/useCellSelection";

const tableHeaders = [
    { key: "status", label: "Status", className: "sm:min-w-32 min-w-32" },
    {
        key: "fulfilled_by",
        label: "Fulfilled by",
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "remaining_quantity",
        label: "Inventory Quantity",
        className: "sm:min-w-44 min-w-32",
    },
    { key: "created_at", label: "Date", className: "sm:min-w-32 min-w-28" },
    { key: "retailer", label: "Supplier", className: "min-w-32" },
    { key: "order_number", label: "Order #", className: "min-w-32" },
    {
        key: "item_name",
        label: "Item Name",
        className: "sm:min-w-48 min-w-48",
    },
    {
        key: "brand_sku",
        label: "Brand SKU",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "size", label: "Size", className: "sm:min-w-32 min-w-28" },
    {
        key: "quantity",
        label: "Quantity Ordered",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "color", label: "Color", className: "sm:min-w-32 min-w-28" },
    { key: "asin", label: "ASIN", className: "sm:min-w-32 min-w-28" },
    {
        key: "amazon_sku",
        label: "Amazon SKU",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "unit_cost",
        label: "Unit cost",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "list_price",
        label: "List price",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "shipment_value",
        label: "Shipment value",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
];

export default function InventoryTable({
    inventoryOrders,
    results,
    loading,
    selectedOrders,
    setSelectedOrders,
    handleRefetch,
    sortConfig,
    toggleSort,
    sortLoading,
    shipmentQuantities,
    handleQuantityChange,
}: {
    inventoryOrders: Order[];
    results?: Results;
    loading?: boolean;
    selectedOrders?: Order[];
    setSelectedOrders?: React.Dispatch<React.SetStateAction<Order[]>>;
    handleRefetch: () => void;
    sortConfig?: {
        key: string;
        order: string;
    };
    toggleSort?: (key: string) => void;
    sortLoading?: boolean;
    shipmentQuantities?: ShipmentQuantities;
    handleQuantityChange?: (orderId: string, value: string) => void;
}) {
    const [searchParams] = useSearchParams();
    const [openEditModal, setOpenEditModal] = useState(false);

    const [selectedInventoryOrder, setSelectedInventoryOrder] =
        useState<Order | null>(null);

    useEffect(() => {
        if (selectedInventoryOrder) {
            setOpenEditModal(true);
        }
    }, [selectedInventoryOrder]);

    useEffect(() => {
        if (!openEditModal) {
            setSelectedInventoryOrder(null);
        }
    }, [openEditModal]);

    const {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    } = useCheckInDatesModal();

    const handleCheckboxChange = (order: Order) => {
        const orderId = order.order_id;
        if (selectedOrders && setSelectedOrders) {
            if (selectedOrders.some((o) => o.order_id === orderId)) {
                // Remove the order by its id
                setSelectedOrders(
                    selectedOrders.filter((o) => o.order_id !== orderId)
                );
            } else {
                // Add the order using its id
                setSelectedOrders([...selectedOrders, order]);
            }
        }
    };

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        switch (columnIndex) {
            case 3:
                return order.quantity_remaining;
            case 10:
                return order.quantity;
            case 14:
                return order.unit_cost;
            case 15:
                return order.list_price;
            case 16:
                return order.shipment_value;
            default:
                return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection([], getCellValue, [3, 10, 14, 15, 16]);

    return (
        <div>
            {inventoryOrders && inventoryOrders.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading || false}
                            scrollOffset={scrollOffset}
                        />
                        <table
                            onMouseUp={handleMouseUp}
                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                        >
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {selectedOrders ? (
                                        <th
                                            key={"checkbox"}
                                            className={`py-2 min-w-10`}
                                        ></th>
                                    ) : null}
                                    {shipmentQuantities &&
                                        handleQuantityChange && (
                                            <th className="py-2 sm:min-w-40 min-w-32">
                                                Shipping quantity
                                            </th>
                                        )}
                                    {tableHeaders.map((header) => (
                                        <th
                                            onClick={() =>
                                                toggleSort &&
                                                toggleSort(header.key)
                                            }
                                            key={header.key}
                                            className={`group py-2 cursor-pointer ${
                                                sortableColumns.includes(
                                                    header.key
                                                )
                                                    ? "hover:bg-gray-100"
                                                    : ""
                                            } ${header.className}`}
                                        >
                                            {header.label}{" "}
                                            {sortConfig && (
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="relative">
                                {inventoryOrders.map((order, rowIndex) => (
                                    <tr
                                        onClick={
                                            selectedOrders
                                                ? () =>
                                                      setSelectedInventoryOrder(
                                                          order
                                                      )
                                                : () => {}
                                        }
                                        key={order.order_id}
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {selectedOrders && (
                                            <td className="py-3 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            order
                                                        )
                                                    }
                                                    checked={
                                                        selectedOrders &&
                                                        selectedOrders.some(
                                                            (o) =>
                                                                o.order_id ===
                                                                order.order_id
                                                        )
                                                    }
                                                    onClick={(e) =>
                                                        e.stopPropagation()
                                                    }
                                                />
                                            </td>
                                        )}
                                        {shipmentQuantities &&
                                        handleQuantityChange ? (
                                            <td>
                                                <input
                                                    className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                    type="number"
                                                    value={
                                                        shipmentQuantities[
                                                            order.order_id
                                                        ] || ""
                                                    }
                                                    onChange={(e) =>
                                                        handleQuantityChange(
                                                            order.order_id,
                                                            e.target.value
                                                        )
                                                    }
                                                    onWheel={(e) => {
                                                        const target =
                                                            e.target as HTMLInputElement;
                                                        target.blur();
                                                        setTimeout(
                                                            () =>
                                                                target.focus(),
                                                            0
                                                        );
                                                    }}
                                                ></input>
                                            </td>
                                        ) : null}
                                        <td className="py-2.5 pr-4 whitespace-nowrap">
                                            <StatusTag
                                                status={order.unit_status}
                                                quantity_received={
                                                    order.quantity_received
                                                }
                                                quantity={order.quantity}
                                                fulfilled_by={
                                                    order.fulfilled_by
                                                }
                                                handleClick={(e) => {
                                                    e.stopPropagation();
                                                    handleViewDates(order);
                                                }}
                                            />
                                        </td>
                                        <td className="">
                                            {capitalizeFirstLetter(
                                                order.fulfilled_by
                                            )}
                                        </td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    3,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    3,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    3,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 3
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {order.quantity_remaining}
                                        </td>
                                        <td className="">
                                            {formatDateFromDb(order.date)}
                                        </td>
                                        <td className="">{order.retailer}</td>
                                        <td className="">
                                            {order.order_number}
                                        </td>
                                        <td className="">{order.item_name}</td>
                                        <td className="">{order.brand_sku}</td>
                                        <td className="">{order.size}</td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    10,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    10,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    10,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 10
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {order.quantity}
                                        </td>
                                        <td className="">{order.color}</td>
                                        <td className="">{order.asin}</td>
                                        <td className="">{order.amazon_sku}</td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    14,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    14,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    14,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 14
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {formatMoney(order.unit_cost)}
                                        </td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    15,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    15,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    15,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 15
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {formatMoney(order.list_price)}
                                        </td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    16,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    16,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    16,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 16
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {formatMoney(order.shipment_value)}
                                        </td>
                                        <td className="">{order.user_notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {results ? (
                        <nav
                            aria-label="Pagination"
                            className="flex items-center justify-between border-t border-gray-200 bg-white pt-3 pb-1"
                        >
                            <div className="hidden sm:block">
                                <div className="flex flex-row items-center space-x-3">
                                    <PaginationResults results={results} />
                                    <LimitSelector />
                                </div>
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                                <PaginationLink
                                    href={(function () {
                                        const params = new URLSearchParams(
                                            searchParams
                                        );
                                        // Set the previous page offset
                                        params.set(
                                            "offset",
                                            calculateOffset(
                                                searchParams,
                                                "back"
                                            ).toString()
                                        );
                                        return `/inventory/units?${params.toString()}`;
                                    })()}
                                    disabled={getOffset(searchParams) === 0}
                                    text="Previous"
                                />
                                <PaginationLink
                                    href={(function () {
                                        const params = new URLSearchParams(
                                            searchParams
                                        );
                                        // Set the next page offset
                                        params.set(
                                            "offset",
                                            calculateOffset(
                                                searchParams,
                                                "forward"
                                            ).toString()
                                        );
                                        return `/inventory/units?${params.toString()}`;
                                    })()}
                                    disabled={
                                        results.showing_to >=
                                        results.total_results
                                    }
                                    text="Next"
                                    className="ml-3"
                                />
                            </div>
                        </nav>
                    ) : null}
                    {selectedOrders && selectedOrders?.length > 0 && (
                        <span className="text-gray-700 text-sm">
                            {selectedOrders.length} shipment
                            {selectedOrders.length > 1 && "s"} selected
                        </span>
                    )}
                    {selectedCells.length > 0 && (
                        <span className="text-sm text-gray-900 flex flex-row items-center">
                            {"Sum: "}
                            {sumSelectedQuantities.map(
                                ({ columnIndex, sum }) => (
                                    <>
                                        {sum > 0 && (
                                            <p
                                                key={columnIndex}
                                                className="pl-1"
                                            >
                                                {columnIndex !== 14 &&
                                                columnIndex !== 15 &&
                                                columnIndex !== 16
                                                    ? sum
                                                    : formatMoney(sum)}
                                            </p>
                                        )}
                                    </>
                                )
                            )}
                        </span>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    {loading ? <LoadingWheel /> : <EmptyInventoryTable />}
                </div>
            )}

            <EditInventoryOrderModal
                inventoryOrder={selectedInventoryOrder}
                openEditModal={openEditModal}
                setOpenEditModal={setOpenEditModal}
                handleRefetch={handleRefetch}
            />

            <CheckInDatesModal
                openCheckInDatesModal={openCheckInDatesModal}
                setOpenCheckInDatesModal={setOpenCheckInDatesModal}
                selectedOrder={selectedOrder}
                client_type="client"
            />
        </div>
    );
}
