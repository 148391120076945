import { SortConfig } from "../../../../hooks/useSort";
import { apiRequest } from "../../../../utils/api/apiRequest";
import { buildUrl } from "../../../../utils/url/buildUrl";

export const fetchReceivedOrders = async (
    status: "checked-in" | "received",
    offset?: number,
    limit?: number | null,
    sortConfig?: SortConfig,
) => {
    const baseUrl = `/received`;
    const params = {
        offset: offset ? String(offset) : "",
        limit: limit && limit != 0 ? String(limit) : "-1",
        sort_key: sortConfig ? sortConfig.key : "created_at",
        sort_order: sortConfig ? sortConfig.order : "desc",
        status: status
    };
    const url = buildUrl(baseUrl, params);

    return apiRequest(url, "GET")
}

export const updateReceivedOrderClientNotes = async (received_order_ids: string[], notes: string) => {
    return apiRequest("/received/notes", "PUT", {received_order_ids, notes})
}