/** @format */

import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdLocalShipping } from "react-icons/md";
import { motion } from "framer-motion";

const features = [
    {
        name: "Effortless Order Tracking:",
        description:
            "Monitor all inbound and outbound shipments in real-time with our in-house shipment management software.",
        icon: FaLocationDot,
    },
    {
        name: "Streamlined Shipment Management:",
        description:
            "From receiving to shipping, we ensure fast and efficient fulfillment at every step of the process.",
        icon: MdLocalShipping,
    },
    {
        name: "Automated Invoicing:",
        description:
            "Invoices are generated in real-time as shipments are processed, making billing simple and accurate.",
        icon: FaFileInvoiceDollar,
    },
];

export default function DashboardFeatures() {
    return (
        <div className="overflow-hidden bg-white pt-36 pb-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    {/* Left Side - Text and Features */}
                    <motion.div
                        className="lg:pr-8 lg:pt-4"
                        initial={{ opacity: 0, x: -100 }} // Animation start
                        whileInView={{ opacity: 1, x: 0 }} // Animation on scroll into view
                        transition={{ duration: 0.8 }} // Duration of the animation
                        viewport={{ once: true }} // Animate once on scroll
                    >
                        <div className="lg:max-w-lg">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Dashboard Management
                            </p>
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                {features.map((feature) => (
                                    <div
                                        key={feature.name}
                                        className="relative pl-9"
                                    >
                                        <dt className="inline font-semibold text-gray-900">
                                            <feature.icon
                                                aria-hidden="true"
                                                className="absolute left-1 top-1 h-5 w-5 text-accent"
                                            />
                                            {feature.name}
                                        </dt>{" "}
                                        <dd className="inline">
                                            {feature.description}
                                        </dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </motion.div>

                    {/* Right Side - Image */}
                    <motion.img
                        alt="Product screenshot"
                        src="/img-dashboard2.png"
                        width={2432}
                        height={1442}
                        className="w-[48rem] max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        initial={{ opacity: 0, x: 100 }} // Start position: right side
                        whileInView={{ opacity: 1, x: 0 }} // Animation on scroll into view
                        transition={{ duration: 0.8 }} // Duration of the animation
                        viewport={{ once: true }} // Animate once on scroll
                    />
                </div>
            </div>
        </div>
    );
}
