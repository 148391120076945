/** @format */

import { useState } from "react";
import { InputField } from "../../../components/inputField";
import LoadingWheel from "../../../components/loadingWheel";
import { updateEmailNotifications } from "../api/settingsApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import { EmailNotification } from "../../../models/adminDashboard";

function ManageNotifications({
    emailNotifications,
    setEmailNotifications,
    handleRefetch,
}: {
    emailNotifications: EmailNotification[];
    setEmailNotifications: React.Dispatch<
        React.SetStateAction<EmailNotification[]>
    >;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [showSave, setShowSave] = useState(false);

    const handleEdit = async () => {
        setLoading(true);
        const data = await updateEmailNotifications(emailNotifications);
        if (data.status === "success") {
            showNotification("Updated email notifications", "", "success");
            handleRefetch();
        } else {
            showNotification("Failed operation.", data.message, "error");
        }
        setLoading(false);
    };

    const toggleNotify = (id: string) => {
        setShowSave(true);
        setEmailNotifications((prevNotifications) =>
            prevNotifications.map((notification) => {
                if (notification.id === id) {
                    return {
                        ...notification,
                        notify: !notification.notify,
                    }; // Toggle notify value
                }
                return notification; // Return unchanged notification
            })
        );
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowSave(true);
        const newEmail = event.target.value;
        setEmailNotifications((prevNotifications) =>
            prevNotifications.map((notification) => ({
                ...notification,
                email_to_notify: newEmail, // Update email_to_notify for each item
            }))
        );
    };

    return (
        <div className="bg-white border border-gray-200 shadow-sm sm:rounded-lg lg:w-3/5">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Manage email notifications
                </h3>
                <div className="flex flex-col lg:flex-row lg:items-end lg:space-x-4 pt-3">
                    <div className="flex flex-row items-center space-x-4 w-full">
                        <InputField
                            label="Email"
                            value={emailNotifications[0]?.email_to_notify || ""} // Get email of the first item
                            onChange={handleEmailChange} // Update all emails on change
                            placeholder=""
                        />
                    </div>
                    {showSave && (
                        <button
                            disabled={loading}
                            onClick={() => handleEdit()}
                            className="mt-3 lg:w-44 w-full items-center justify-center rounded-md bg-accent px-3 h-9 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                        >
                            {loading ? (
                                <LoadingWheel color="white" size="small" />
                            ) : (
                                <>Save</>
                            )}
                        </button>
                    )}
                </div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900 pt-5">
                    Manage notifications
                </h3>

                <div className="grid grid-cols-2 gap-x-6 gap-y-2 pt-1">
                    {emailNotifications.map((option) => (
                        <div
                            id={option.id}
                            className="flex flex-row items-center space-x-2"
                        >
                            <input
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                onClick={(e) => e.stopPropagation()}
                                onChange={() => toggleNotify(option.id)}
                                checked={option.notify}
                            />
                            <span className="text-sm text-gray-700">
                                {option.name}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default ManageNotifications;
