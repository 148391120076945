/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNotification } from "../../../utils/notification/notificationContext";
import { useEffect, useState } from "react";
import { ReceivedOrder } from "../../../models/order";
import { retrieveUpcData } from "../api/receivedApi";
import { InputField } from "../../../components/inputField";
import LoadingWheel from "../../../components/loadingWheel";
import { ActionButton, SecondaryButton } from "../../../components/buttons";

interface UpcData {
    image_url: string;
    item_name: string;
    color: string;
    size: string;
    retailer: string;
}

interface UpcModalProps {
    openUpcModal: boolean;
    setOpenUpcModal: React.Dispatch<React.SetStateAction<boolean>>;
    upc: string;
    order: ReceivedOrder | null;
    setOrders: React.Dispatch<React.SetStateAction<ReceivedOrder[]>>;
}

function UpcModal({
    openUpcModal,
    setOpenUpcModal,
    upc,
    order,
    setOrders,
}: UpcModalProps) {
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);

    const [upcData, setUpcData] = useState<UpcData | null>(null);

    const [failedFetchingData, setFailedFetchingData] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        setFailedFetchingData(false);
        setUpcData(null);
        const data = await retrieveUpcData(order?.upc || "");
        if (data.status === "success") {
            setUpcData(data.data);
        } else {
            setFailedFetchingData(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (openUpcModal) fetchData();
    }, [openUpcModal]);

    const handleAcceptChanges = () => {
        if (upcData) {
            setOrders((prevOrders) =>
                prevOrders.map((o) =>
                    o.received_order_id === order?.received_order_id
                        ? {
                              ...o,
                              item_name: upcData.item_name,
                              color: upcData.color,
                          }
                        : o
                )
            );
            setOpenUpcModal(false);
        }
    };

    return (
        <Dialog
            open={openUpcModal}
            onClose={() => setOpenUpcModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-2xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenUpcModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            UPC Item Data
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            {!loading ? (
                                <>
                                    {!failedFetchingData && upcData ? (
                                        <>
                                            <div className="flex flex-row ">
                                                <img
                                                    src={
                                                        upcData?.image_url || ""
                                                    }
                                                    className="max-h-24"
                                                />
                                            </div>
                                            <InputField
                                                label="Item name"
                                                value={upcData?.item_name || ""}
                                                onChange={() => {}}
                                                disabled
                                            />
                                            <InputField
                                                label="Color"
                                                value={upcData?.color || ""}
                                                onChange={() => {}}
                                                disabled
                                            />
                                        </>
                                    ) : (
                                        <div className="text-sm text-gray-700 flex flex-row items-center w-full h-full justify-center">
                                            Unable to fetch data.
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="flex flex-row items-center justify-center h-full">
                                    <LoadingWheel />
                                </div>
                            )}
                        </div>

                        <div className="flex flex-row-reverse justify-between">
                            <ActionButton
                                label="Accept data"
                                handleClick={() => handleAcceptChanges()}
                                height={8}
                            />
                            <SecondaryButton
                                label="Data incorrect"
                                handleClick={() => setOpenUpcModal(false)}
                                height={8}
                            />
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default UpcModal;
