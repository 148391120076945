/** @format */

import { PlusIcon } from "@heroicons/react/24/outline";
import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import { deleteShippingName, fetchShippingNames } from "../api/inboundApi";
import { useEffect, useState } from "react";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { ShippingName } from "../../../../models/shippingNames";
import ShippingNamesTable, {
    initialShippingNamesColumnOrder,
} from "../../../../tables/shippingNamesTable";
import LoadingWheel from "../../../../components/loadingWheel";
import Results from "../../../../models/results";
import { useSort } from "../../../../hooks/useSort";
import AddShippingNameModal from "./addShippingNameModal";
import RequestNameIncreaseModal from "./requestNameIncreaseModal";
import { ActionButton } from "../../../../components/buttons";

interface ShippingNamesCount {
    max_shipping_names: number;
    requested_shipping_names_amount: number;
}

function ShippingNamesPage() {
    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [shippingNames, setShippingNames] = useState<ShippingName[]>([]);
    const [shippingNamesCount, setShippingNamesCount] =
        useState<ShippingNamesCount | null>(null);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(false);
    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => 1 + prev);
    };

    const fetchData = async () => {
        setLoading(true);
        const data = await fetchShippingNames();
        if (data.status === "success") {
            setShippingNames(data.data.shipping_names);
            setShippingNamesCount(data.data.counts);
        } else {
            showNotification("Failed to fetch data.", data.message, "error");
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [refetchData]);

    const [openAddShippingNameModal, setOpenAddShippingNameModal] =
        useState(false);

    const handleDelete = async (shipping_name: string) => {
        const data = await deleteShippingName(shipping_name);
        if (data.status === "success") {
            handleRefetch();
            showNotification("Deleted shipping name.", "", "success");
        } else {
            showNotification("Failed action.", data.message, "error");
        }
    };

    const [openRequestNameIncreaseModal, setOpenRequestNameIncreaseModal] =
        useState(false);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto pb-4">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto min-h-screen">
                    <div className="flex flex-col sm:flex-row justify-between sm:items-center">
                        <div className="flex flex-row items-center">
                            <span className="text-2xl font-bold">
                                Shipping Names
                            </span>
                            {!loading && (
                                <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                                    {shippingNames.length - 1}/
                                    {shippingNamesCount?.max_shipping_names} (
                                    {
                                        shippingNamesCount?.requested_shipping_names_amount
                                    }{" "}
                                    pending approval)
                                </span>
                            )}
                        </div>
                        <div className="flex flex-row items-center space-x-4 pt-4 sm:pt-0">
                            <ActionButton
                                label="Request Name Increase"
                                iconDisplayed={PlusIcon}
                                handleClick={() =>
                                    setOpenRequestNameIncreaseModal(true)
                                }
                            />
                            <ActionButton
                                label="Add Shipping Name"
                                iconDisplayed={PlusIcon}
                                handleClick={() =>
                                    setOpenAddShippingNameModal(true)
                                }
                            />
                        </div>
                    </div>
                    <div className="flex flex-row items-start py-4 space-x-4"></div>
                    {!loading ? (
                        <ShippingNamesTable
                            shippingNames={shippingNames}
                            results={results}
                            sortLoading={sortLoading}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            handleRefetch={handleRefetch}
                            emptyTableText="You have no shipping names added yet."
                            columnOrder={initialShippingNamesColumnOrder}
                            handleDelete={handleDelete}
                        />
                    ) : (
                        <div className="flex flex-grow items-center pb-44 justify-center">
                            {loading ? (
                                <LoadingWheel />
                            ) : (
                                <span className="text-sm text-gray-500">
                                    User has no inbound orders
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <AddShippingNameModal
                openAddShippingNameModal={openAddShippingNameModal}
                setOpenAddShippingNameModal={setOpenAddShippingNameModal}
                handleRefetch={handleRefetch}
            />

            <RequestNameIncreaseModal
                openRequestNameIncreaseModal={openRequestNameIncreaseModal}
                setOpenRequestNameIncreaseModal={
                    setOpenRequestNameIncreaseModal
                }
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default ShippingNamesPage;
