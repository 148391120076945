import { ShipmentQuantities } from "../../../../models/order"
import { apiRequest } from "../../../../utils/api/apiRequest"


export const fetchShippingDetailRequests = async () => {
    return apiRequest("/shipping-details", "GET")
}

export const fetchShippingDetailOrders = async (requested_shipping_details_id: string, user_id: string | null | undefined) => {
    return apiRequest(`/shipping-details/${requested_shipping_details_id}${user_id ? `?user_id=${user_id}` : ""}`, "GET")
}