/** @format */

import React, { useRef, useEffect, useState } from "react";
import LoadingWheel from "../../../../components/loadingWheel";
import { useSearchParams } from "react-router-dom";
import { searchData } from "../../api/clientsApi";
import Client from "../../../../models/clients";

interface ClientFilterPopupProps {
    isOpen: boolean;
    onClose: () => void;
    taxButtonRef: React.RefObject<HTMLButtonElement>; // Add this prop
    user_id: string | null;
    setUserId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ClientFilterPopup: React.FC<ClientFilterPopupProps> = ({
    isOpen,
    onClose,
    taxButtonRef,
    user_id,
    setUserId,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const taxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                taxRef.current &&
                !taxRef.current.contains(event.target as Node) &&
                taxButtonRef.current &&
                !taxButtonRef.current.contains(event.target as Node)
            ) {
                onClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose, taxButtonRef]);

    const [unappliedFilter, setUnappliedFilter] = useState("");

    const handleApply = () => {
        if (unappliedFilter !== "") {
            setUserId(unappliedFilter);
            setSearchParams({ user_id: unappliedFilter });
        }
        onClose();
    };

    useEffect(() => {
        setUnappliedFilter(user_id || "");
    }, []);

    const [clients, setClients] = useState<Client[]>([]);
    const [clientsLoading, setClientsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        if (isOpen) {
            searchData(
                unappliedFilter,
                user_id,
                "clients",
                "sub_page",
                setClients,
                setClientsLoading,
                () => {}
            );
        }

        return () => {
            if (isOpen) searchData.cancel();
        };
    }, [isOpen, unappliedFilter]);

    return (
        <div
            ref={taxRef}
            className={`left-0 top-8 absolute px-2 overflow-auto w-64 max-h-64 bg-white z-50 rounded-md border border-gray-200 shadow-sm flex flex-col justify-between py-2 transition-opacity duration-300 ease-in-out ${
                isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
        >
            <div className="font-medium text-sm">Client</div>
            <div className="relative">
                <input
                    className="block w-full rounded-md border-0 py-0.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent text-xs leading-6"
                    value={unappliedFilter}
                    onChange={(e) => setUnappliedFilter(e.target.value)}
                />
                {clientsLoading && (
                    <div className="absolute right-2 top-0 bottom-0">
                        <LoadingWheel size="xs" />
                    </div>
                )}
            </div>
            <div className="flex flex-col divide-y space-y-2 py-2 overflow-auto pb-10">
                {clients.map((client) => (
                    <div
                        key={client.user_id}
                        onClick={() => setUnappliedFilter(client.user_id)}
                        className="flex flex-col hover:bg-gray-100 cursor-pointer"
                    >
                        <span className="text-sm font-medium">
                            {client.first_name + " " + client.last_name}
                        </span>
                        <span className="text-xs text-gray-700">
                            {client.email}
                        </span>
                    </div>
                ))}
            </div>

            <button
                onClick={handleApply}
                className="absolute left-0 right-0 bottom-2  bg-accent text-xs text-white h-7 rounded-md hover:bg-accenthighlight mx-2"
            >
                Apply
            </button>
        </div>
    );
};

export const ClientInputPopup: React.FC<ClientFilterPopupProps> = ({
    isOpen,
    onClose,
    taxButtonRef,
    user_id,
    setUserId,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const taxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                taxRef.current &&
                !taxRef.current.contains(event.target as Node) &&
                taxButtonRef.current &&
                !taxButtonRef.current.contains(event.target as Node)
            ) {
                onClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose, taxButtonRef]);

    const [unappliedFilter, setUnappliedFilter] = useState("");

    const handleApply = () => {
        if (unappliedFilter !== "") {
            setUserId(unappliedFilter);
            setSearchParams({ user_id: unappliedFilter });
        }
        onClose();
    };

    useEffect(() => {
        setUnappliedFilter(user_id || "");
    }, []);

    const [clients, setClients] = useState<Client[]>([]);
    const [clientsLoading, setClientsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        if (isOpen) {
            searchData(
                unappliedFilter,
                user_id,
                "clients",
                "sub_page",
                setClients,
                setClientsLoading,
                () => {}
            );
        }

        return () => {
            if (isOpen) searchData.cancel();
        };
    }, [isOpen, unappliedFilter]);

    return (
        <div
            ref={taxRef}
            className={`left-0 top-8 absolute px-2 overflow-auto w-64 max-h-64 bg-white z-50 rounded-md border border-gray-200 shadow-sm flex flex-col justify-between py-2 transition-opacity duration-300 ease-in-out ${
                isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
        >
            <div className="font-medium text-sm">Client</div>
            <div className="relative">
                <input
                    className="block w-full rounded-md border-0 py-0.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent text-xs leading-6"
                    value={unappliedFilter}
                    onChange={(e) => setUnappliedFilter(e.target.value)}
                />
                {clientsLoading && (
                    <div className="absolute right-2 top-0 bottom-0">
                        <LoadingWheel size="xs" />
                    </div>
                )}
            </div>
            <div className="flex flex-col divide-y space-y-2 py-2 overflow-auto pb-10">
                {clients.map((client) => (
                    <div
                        key={client.user_id}
                        onClick={() => setUnappliedFilter(client.user_id)}
                        className="flex flex-col hover:bg-gray-100 cursor-pointer"
                    >
                        <span className="text-sm font-medium">
                            {client.first_name + " " + client.last_name}
                        </span>
                        <span className="text-xs text-gray-700">
                            {client.email}
                        </span>
                    </div>
                ))}
            </div>

            <button
                onClick={handleApply}
                className="absolute left-0 right-0 bottom-2  bg-accent text-xs text-white h-7 rounded-md hover:bg-accenthighlight mx-2"
            >
                Apply
            </button>
        </div>
    );
};
