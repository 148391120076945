/** @format */

import { GoPackage } from "react-icons/go";
import { IoReturnDownBack, IoStorefrontOutline } from "react-icons/io5";
import { motion } from "framer-motion";

const features = [
    {
        name: "Order Fulfillment",
        description:
            "We handle every step, from receiving and organizing inventory to packing and shipping orders, giving you a completely hands-off experience for your business.",
        href: "/order-fulfillment",
        icon: GoPackage, // Replace with appropriate icon
    },
    {
        name: "FBA Preparation",
        description:
            "We'll receive, label, and ship your inventory to Amazon fulfillment centers. Our specially trained team helps sellers minimize inbound shipping costs and avoid costly shipment splits.",
        href: "/order-fulfillment",
        icon: IoStorefrontOutline, // Replace with appropriate icon
    },
    {
        name: "Reshipping",
        description:
            "We'll receive your inventory at our tax-free Delaware warehouse and reship it to you, allowing you to bypass sales tax.",
        href: "/order-fulfillment",
        icon: IoReturnDownBack, // Replace with appropriate icon
    },
];

export default function Services() {
    return (
        <div className="relative pt-12">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                {/* Animate the section title */}
                <motion.div
                    className="flex flex-col items-center"
                    initial={{ opacity: 0, y: -50 }} // Starts from above and fades in
                    whileInView={{ opacity: 1, y: 0 }} // Animates when in view
                    transition={{ duration: 0.8 }} // Duration of the animation
                    viewport={{ once: true }} // Only animates once
                >
                    {/* <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
                            What we offer
                        </h2>
                    </div> */}
                </motion.div>

                {/* Animate the feature cards */}
                <motion.div
                    className="mx-auto  max-w-2xl lg:max-w-none"
                    initial={{ opacity: 0 }} // Cards are initially invisible
                    whileInView={{ opacity: 1 }} // Fade in when they come into view
                    transition={{ duration: 0.8, delay: 0.2 }} // Slight delay after the title
                    viewport={{ once: true }} // Only animates once
                >
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature, index) => (
                            <motion.div
                                key={feature.name}
                                className="flex flex-col border border-gray-200 p-4 rounded-md shadow-sm bg-white"
                                initial={{ opacity: 0, y: 50 }} // Start below and fade in
                                whileInView={{ opacity: 1, y: 0 }} // Animate to position
                                transition={{
                                    duration: 0.6,
                                    delay: index * 0.1, // Staggered delay for each card
                                }}
                                viewport={{ once: true }} // Animate once on scroll
                            >
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-accent">
                                        <feature.icon
                                            aria-hidden="true"
                                            className="h-6 w-6 text-white"
                                        />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                    <p className="flex-auto">
                                        {feature.description}
                                    </p>
                                    <p className="mt-6">
                                        <a
                                            href={feature.href}
                                            className="text-sm font-semibold leading-6 text-accent"
                                        >
                                            Learn more{" "}
                                            <span aria-hidden="true">→</span>
                                        </a>
                                    </p>
                                </dd>
                            </motion.div>
                        ))}
                    </dl>
                </motion.div>
            </div>
        </div>
    );
}
