/** @format */
import { useState } from "react";
import LoadingWheel from "../../../../components/loadingWheel";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPasswordRequest } from "../../api/authenticationApi";
import { useNotification } from "../../../../utils/notification/notificationContext";

export default function ResetPasswordPage() {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");

    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [verificationCode, setVerificationCode] = useState("");

    const [verificationCodeIncorrect, setVerificationCodeIncorrect] =
        useState(false);

    const { showNotification } = useNotification();

    return (
        <div className="bg-gray-50 h-screen">
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <img
                                alt="Your Company"
                                src="/branding/urva-white-name.jpeg"
                                className="mx-auto h-auto w-28"
                            />
                            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Reset your password
                            </h2>
                        </div>
                        <div className="space-y-6 mt-10">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="password"
                                        disabled={loading}
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Confirm password
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="password"
                                        disabled={loading}
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        value={confirmPassword}
                                        onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Verification Code
                                </label>
                                <div className="mt-2">
                                    <input
                                        disabled={loading}
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        value={verificationCode}
                                        onChange={(e) =>
                                            setVerificationCode(e.target.value)
                                        }
                                    />
                                </div>
                            </div>

                            {verificationCodeIncorrect ? (
                                <span className="text-red-500 text-sm">
                                    Verification code is incorrect, try again.
                                </span>
                            ) : (
                                <></>
                            )}

                            <div>
                                <button
                                    disabled={password.length < 1 || loading}
                                    onClick={() =>
                                        resetPasswordRequest(
                                            email,
                                            password,
                                            verificationCode,
                                            navigate,
                                            setLoading,
                                            setVerificationCodeIncorrect,
                                            showNotification
                                        )
                                    }
                                    type="submit"
                                    className={`${
                                        password.length >= 1 &&
                                        confirmPassword == password &&
                                        verificationCode.length >= 6 &&
                                        !loading
                                            ? "bg-accent hover:bg-accenthighlight cursor-pointer transition"
                                            : password.length >= 1 && loading
                                            ? "bg-accent cursor-default transition"
                                            : "bg-accentdim cursor-default transition"
                                    } items-center flex w-full justify-center rounded-md px-3 h-9 text-sm font-semibold leading-6 text-white shadow-sm `}
                                >
                                    {loading ? (
                                        <LoadingWheel color="white" />
                                    ) : (
                                        <span>Reset password</span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Remember your password?{" "}
                        <a
                            href="/login"
                            className="font-semibold leading-6 text-accent hover:text-accenthighlight"
                        >
                            Login
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}
