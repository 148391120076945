/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import {
    CheckIcon,
    ChevronDownIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import OrdersTable, { getTableHeaders } from "../../../../tables/ordersTable";
import { Order, ShipmentQuantities } from "../../../../models/order";
import { useSort } from "../../../../hooks/useSort";
import { Column } from "../../../../tables/models";
import { ActionButton } from "../../../../components/buttons";
import { createShippingDetailsRequest } from "../api/shippingDetailsApi";
import { useNotification } from "../../../../utils/notification/notificationContext";

function RequestShippingDetailsModal({
    openRequestShippingDetailsModal,
    setOpenRequestShippingDetailsModal,
    selectedOrders,
    setSelectedOrders,
}: {
    openRequestShippingDetailsModal: boolean;
    setOpenRequestShippingDetailsModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    selectedOrders: Order[];
    setSelectedOrders: React.Dispatch<React.SetStateAction<Order[]>>;
}) {
    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [notes, setNotes] = useState("");

    const [shipmentQuantities, setShipmentQuantities] =
        useState<ShipmentQuantities>({});

    const handleQuantityChange = (orderId: string, value: string) => {
        setShipmentQuantities((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inbound")
    );
    const hiddenHeaders: string[] = [
        "select",
        "location",
        "quick_actions",
        "input_shelf_location",
    ];

    const [loading, setLoading] = useState(false);
    const handleCreate = async () => {
        setLoading(true);
        const order_ids = selectedOrders.map((o) => o.order_id);
        const data = await createShippingDetailsRequest(
            order_ids,
            shipmentQuantities,
            notes
        );
        if (data.status === "success") {
            setOpenRequestShippingDetailsModal(false);
            setSelectedOrders([]);
            setNotes("");
            setShipmentQuantities({});
            showNotification(
                "Created shipping details request.",
                "",
                "success"
            );
        } else {
            showNotification(
                "Failed to create shipping details request",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openRequestShippingDetailsModal}
            onClose={() => setOpenRequestShippingDetailsModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-7xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenRequestShippingDetailsModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Request Shipping Details
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4 -mx-1">
                            <OrdersTable
                                orderStatus="inbound"
                                orders={selectedOrders}
                                results={null}
                                selectedOrders={[]}
                                setSelectedOrders={setSelectedOrders}
                                sortLoading={sortLoading}
                                sortConfig={sortConfig}
                                toggleSort={toggleSort}
                                handleRefetch={() => {}}
                                client_type="admin"
                                setTotalSum={setTotalSum}
                                columnOrder={unitsColumnOrder}
                                hiddenHeaders={hiddenHeaders}
                                shipmentQuantities={shipmentQuantities}
                                handleQuantityChange={handleQuantityChange}
                                shipmentInputName={"Quantity Requested"}
                            />

                            <div className="mx-1">
                                <label
                                    htmlFor="comment"
                                    className="block text-sm/6 font-medium text-gray-900 mx-1"
                                >
                                    Notes
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        id="comment"
                                        name="comment"
                                        rows={2}
                                        className="block w-full rounded-md px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-accent sm:text-sm/6"
                                        value={notes}
                                        onChange={(e) =>
                                            setNotes(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row-reverse">
                            <ActionButton
                                label="Request details"
                                handleClick={() => handleCreate()}
                                height={9}
                                loading={loading}
                            />
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default RequestShippingDetailsModal;
