/** @format */

import { useEffect, useState } from "react";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import ClientsTable from "./clientsTable";
import { fetchClients } from "./../api/clientsApi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Results from "../../../models/results";
import Client from "../../../models/clients";
import LoadingWheel from "../../../components/loadingWheel";
import AdminSearchBar from "../../../components/admin/searchBar";
import { PlClientsPageType } from "../../../models/pageSelectors";
import ClientSettings from "../settings/page";
import { useSort } from "../../../hooks/useSort";
import { useClient } from "../../../utils/client/clientContext";

const selectorsData = [
    { label: "Clients", value: "clients" },
    { label: "Settings", value: "settings" },
];

export default function ClientsPage() {
    const [searchParams] = useSearchParams();

    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "inventory",
        order: "desc",
    });

    const [selectedClients, setSelectedClients] = useState<string[]>([]);

    const [clients, setClients] = useState<Client[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(true);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        // setLoading(true);
        const fetchData = async () => {
            const data = await fetchClients(searchParams, sortConfig);
            if (data.status === "success") {
                setClients(data.data.clients);
                setResults(data.data.results);
            } else {
            }
            setLoading(false);
            setSortLoading(false);
        };
        fetchData();
    }, [refetchData, sortConfig]);

    const location = useLocation();

    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");

    return (
        <div className="flex flex-row">
            <AdminSideBar orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto">
                <Header user_id={""} />
                <div className="overflow-x-auto">
                    <div className="flex flex-row justify-between items-center w-full pt-10">
                        <span className="text-2xl font-bold">Clients</span>
                    </div>
                    <div className="py-4">
                        <AdminSearchBar
                            setData={setClients}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="clients"
                            sub_page=""
                            user_id={""}
                            keywords={keywords}
                            setKeywords={setKeywords}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            refetchSearchedData={false}
                        />
                    </div>
                    {!loading ? (
                        <ClientsTable
                            clients={clients}
                            results={results}
                            selectedClients={selectedClients}
                            setSelectedClients={setSelectedClients}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            sortLoading={sortLoading}
                        />
                    ) : (
                        <div className="flex flex-grow items-center pt-44 justify-center">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
