/** @format */

import { useEffect, useState } from "react";
import LoadingWheel from "../../../../components/loadingWheel";
import {
    acceptInvitation,
    validateInvitationToken,
} from "../api/teamInvitationApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RxShadowNone } from "react-icons/rx";
import Cookies from "js-cookie";
import { setAdminType } from "../../../../utils/adminTypes/actions";
import { clearAllData } from "../../../../utils/auth/handleLogout";

function AcceptInvitationPage() {
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const [isValidLoading, setIsValidLoading] = useState(true);
    const [isValid, setIsValid] = useState(false);

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleAcceptInvitation = async () => {
        setLoading(true);
        const data = await acceptInvitation(
            searchParams.get("invitation_token") || "",
            password
        );
        if (data.status === "success") {
            Cookies.set("jwt", data.data.jwt_token, {
                expires: 30,
                secure: true,
            });

            if (data.data.client_type === "client_team_member") {
                navigate("/");
            } else {
                navigate("/admin/clients");
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await validateInvitationToken(
                searchParams.get("invitation_token") || ""
            );
            if (data.status === "success") {
                setEmail(data.data.email);
                setIsValid(true);
            } else {
                setIsValid(false);
            }
            setIsValidLoading(false);
        };
        clearAllData();
        if (searchParams.get("invitation_token")) {
            fetchData();
        } else {
            setIsValidLoading(false);
            setIsValid(false);
        }
    }, []);

    return (
        <>
            {!isValidLoading && isValid ? (
                <div className="bg-gray-50 h-screen">
                    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 pb-20">
                        <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                    <img
                                        alt="Your Company"
                                        src="/branding/urva-white-name.jpeg"
                                        className="mx-auto h-auto w-28"
                                    />
                                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                        Join
                                    </h2>
                                </div>
                                <div className="space-y-6 mt-10">
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Email address
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                disabled
                                                type="email"
                                                autoComplete="email"
                                                className="disabled:bg-gray-100 disabled:text-gray-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                value={email}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex flex-row justify-between">
                                            <label
                                                htmlFor="password"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Password
                                            </label>
                                        </div>
                                        <div className="mt-2 pb-2">
                                            <input
                                                disabled={loading}
                                                id="password"
                                                name="password"
                                                type="password"
                                                required
                                                autoComplete="current-password"
                                                className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                value={password}
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            onClick={() =>
                                                handleAcceptInvitation()
                                            }
                                            disabled={
                                                password.length < 1 || loading
                                            }
                                            type="submit"
                                            className={`${
                                                password.length >= 1 && !loading
                                                    ? "bg-accent hover:bg-accenthighlight cursor-pointer transition"
                                                    : password.length >= 1 &&
                                                      loading
                                                    ? "bg-accent cursor-default transition"
                                                    : "bg-accentdim cursor-default transition"
                                            } items-center flex w-full justify-center rounded-md px-3 h-9 text-sm font-semibold leading-6 text-white shadow-sm `}
                                        >
                                            {loading ? (
                                                <LoadingWheel color="white" />
                                            ) : (
                                                <span>Accept invitation</span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-row items-center justify-center pt-44 text-gray-600 text-sm">
                    {isValidLoading ? (
                        <LoadingWheel />
                    ) : (
                        <span>
                            Invitation token is invalid. Refresh or contact
                            support.
                        </span>
                    )}
                </div>
            )}
        </>
    );
}

export default AcceptInvitationPage;
