/** @format */

import { useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import {
    ExclamationTriangleIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { useNotification } from "../../../utils/notification/notificationContext";
import { ReceivedOrder } from "../../../models/order";
import { deleteReceivedOrders } from "../api/receivedApi";
import { DeleteButton } from "../../../components/buttons";

interface ConfirmDeleteOrderModalProps {
    openConfirmDeleteOrderModal: boolean;
    setOpenConfirmDeleteOrderModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    selectedOrders: ReceivedOrder[];
    setSelectedOrders: React.Dispatch<React.SetStateAction<ReceivedOrder[]>>;
    handleRefetch: () => void;
}

export default function ConfirmDeleteOrderModal({
    openConfirmDeleteOrderModal,
    setOpenConfirmDeleteOrderModal,
    selectedOrders,
    setSelectedOrders,
    handleRefetch,
}: ConfirmDeleteOrderModalProps) {
    const { showNotification } = useNotification();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const handleDelete = async () => {
        setDeleteLoading(true);
        const receivedOrderIds = selectedOrders.map((o) => o.received_order_id);
        const data = await deleteReceivedOrders(receivedOrderIds);
        if (data.status === "success") {
            showNotification(data.message, "", "success");
            setSelectedOrders([]);
            handleRefetch();
            setOpenConfirmDeleteOrderModal(false);
        } else {
            showNotification(
                "Failed to delete orders.",
                data.message,
                "success"
            );
        }
        setDeleteLoading(false);
    };

    return (
        <Dialog
            open={openConfirmDeleteOrderModal}
            onClose={setOpenConfirmDeleteOrderModal}
            className="relative z-30"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-30 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenConfirmDeleteOrderModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="size-6"
                                />
                            </button>
                        </div>
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                                <ExclamationTriangleIcon
                                    aria-hidden="true"
                                    className="size-6 text-red-600"
                                />
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle
                                    as="h3"
                                    className="text-base font-semibold text-gray-900"
                                >
                                    Confirm delete order(s)
                                </DialogTitle>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Are you sure you want to delete these
                                        orders? All the data will be permanently
                                        removed from our servers forever. This
                                        action cannot be undone.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-x-4">
                            <DeleteButton
                                label="Delete"
                                handleClick={() => handleDelete()}
                                loading={deleteLoading}
                                height={9}
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenConfirmDeleteOrderModal(false)
                                }
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
