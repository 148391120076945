/** @format */

import { useState, useEffect } from "react";

const hasSevenDaysPassed = (lastTriggeredTime: any) => {
    const currentTime = new Date().getTime();
    const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
    return currentTime - lastTriggeredTime > sevenDaysInMs;
};

const useExitIntent = ({
    delayTime = 10000,
    // Function to determine if device is mobile
    isMobile = () => /Mobi|Android|iPhone/i.test(navigator.userAgent),
    // Initial disabled state
    initiallyDisabled = false,
}) => {
    const [isActive, setIsActive] = useState(false);
    const [hasTriggered, setHasTriggered] = useState(false);
    const [isDisabled, setIsDisabled] = useState(initiallyDisabled);
    const [timeoutComplete, setTimeoutComplete] = useState(false);

    useEffect(() => {
        // Don't set up listeners if on mobile or hook is disabled
        if (isMobile() || isDisabled) {
            return;
        }

        // Check if the user has triggered the intent in the past 7 days
        const lastTriggered = localStorage.getItem("exitIntentLastTriggered");
        if (lastTriggered && !hasSevenDaysPassed(lastTriggered)) {
            // Disable the intent if it's within the 7-day period
            setIsDisabled(true);
            return;
        }

        // Set up delay timer
        const delayTimer = setTimeout(() => {
            setTimeoutComplete(true);
        }, delayTime);

        const handleMouseLeave = (e: any) => {
            // Only trigger if mouse leaves through the top of the page
            // and the delay time has passed and hasn't been triggered before
            if (
                e.clientY <= 0 &&
                timeoutComplete &&
                !hasTriggered &&
                !isDisabled
            ) {
                setIsActive(true);
                setHasTriggered(true);

                // Store the current time in localStorage to track the trigger date
                const currentTime = new Date().getTime();
                localStorage.setItem(
                    "exitIntentLastTriggered",
                    String(currentTime)
                );
            }
        };

        document.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            document.removeEventListener("mouseleave", handleMouseLeave);
            clearTimeout(delayTimer);
        };
    }, [timeoutComplete, hasTriggered, isDisabled, isMobile, delayTime]);

    // Function to disable the hook permanently
    const disable = () => {
        setIsDisabled(true);
        setIsActive(false);
    };

    // Function to reset the trigger state
    const reset = () => {
        setHasTriggered(false);
        setIsActive(false);
        // Clear the stored trigger date from localStorage
        localStorage.removeItem("exitIntentLastTriggered");
    };

    return {
        isActive,
        hasTriggered,
        isDisabled,
        disable,
        reset,
    };
};

export default useExitIntent;
