/** @format */

import { useParams } from "react-router-dom";
import ClientSideBar from "../../../../components/client/sideBar";
import { fetchInvoiceData } from "../api/billingApi";
import InvoiceDetails from "./invoiceDetails";
import { useEffect, useState } from "react";
import {
    Invoice,
    ItemBilled,
    ShipmentBilled,
} from "../../../../models/invoices";
import { useNotification } from "../../../../utils/notification/notificationContext";
import LoadingWheel from "../../../../components/loadingWheel";

function InvoiceDataPage() {
    const { invoice_id } = useParams();
    const { showNotification } = useNotification();

    const [shipmentsBilled, setShipmentsBilled] = useState<ShipmentBilled[]>(
        []
    );

    const [itemsBilled, setItemsBilled] = useState<ItemBilled[]>([]);

    const [total, setTotal] = useState<number | null>(null);

    const [invoiceData, setInvoiceData] = useState<Invoice | null>(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchInvoiceData(invoice_id);
            if (data.status === "success") {
                setShipmentsBilled(data.data.shipments_billed);
                setItemsBilled(data.data.items);
                setTotal(data.data.total);
                setInvoiceData(data.data.invoice_data);
            } else {
                showNotification(
                    "Failed to fetch invoice",
                    data.message,
                    "error"
                );
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    return (
        <div className="flex flex-row">
            <ClientSideBar />

            <div className="flex flex-col flex-grow w-full px-4 sm:px-10">
                {!loading ? (
                    <InvoiceDetails
                        shipmentsBilled={shipmentsBilled}
                        itemsBilled={itemsBilled}
                        total={total}
                        invoiceData={invoiceData}
                    />
                ) : (
                    <div className="flex flex-grow items-center pt-44 justify-center">
                        <LoadingWheel />
                    </div>
                )}
            </div>
        </div>
    );
}

export default InvoiceDataPage;
