/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { OrderTracking } from "../../models/order";
import { SmallCopyButton } from "../copyButton";

function TrackingNumbersModal({
    openTrackingNumbersModal,
    setOpenTrackingNumbersModal,
    trackingNumbers,
}: {
    openTrackingNumbersModal: boolean;
    setOpenTrackingNumbersModal: React.Dispatch<React.SetStateAction<boolean>>;
    trackingNumbers: OrderTracking[] | null;
}) {
    return (
        <Dialog
            open={openTrackingNumbersModal}
            onClose={() => setOpenTrackingNumbersModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-3/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenTrackingNumbersModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Tracking numbers
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                                <thead>
                                    <tr className="text-left text-sm">
                                        <th className="py-2 sm:min-w-32 min-w-32 ">
                                            Tracking
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trackingNumbers &&
                                        trackingNumbers.map((item, index) => (
                                            <tr
                                                key={index}
                                                className="relative border-t border-gray-200 text-sm text-gray-500"
                                            >
                                                <td className="py-2.5">
                                                    <div className="flex flex-row items-center">
                                                        <span>
                                                            {item.tracking}
                                                        </span>
                                                        <span className="mt-1">
                                                            <SmallCopyButton
                                                                textToCopy={
                                                                    item.tracking
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default TrackingNumbersModal;
