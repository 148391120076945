/** @format */

import { useEffect, useState } from "react";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import EmployeeClockEventsTable from "../../../tables/employeeClockEventsTable";
import { Employee, EmployeeClockEvent } from "../../../models/employees";
import Results from "../../../models/results";
import { useSort } from "../../../hooks/useSort";
import {
    clockInEmployee,
    clockOutEmployee,
    deleteClockEvent,
    fetchClockEvents,
    fetchEmployees,
    updateClockEventPaymentStatus,
} from "../api/employeesApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import SmallSelector from "../../../components/dropdowns/smallSelector";
import MediumSelector from "../../../components/dropdowns/mediumSelector";
import LoadingWheel from "../../../components/loadingWheel";
import TimeSelector, {
    formatSelectorTimeToUTC,
} from "../../../components/dropdowns/timeSelector";
import SingleCalendar from "../../../components/singleCalendar";
import dayjs, { Dayjs } from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useClient } from "../../../utils/client/clientContext";
import { ActionButton } from "../../../components/buttons";
import { getAdminType } from "../../../utils/adminTypes/actions";

function AdminEmployeesPage() {
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();
    const isOwner = getAdminType() === "owner";

    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [clockEvents, setClockEvents] = useState<EmployeeClockEvent[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [selectedClockEvents, setSelectedClockEvents] = useState<
        EmployeeClockEvent[]
    >([]);

    const [refetchData, setRefetchData] = useState(false);
    const handleRefetch = () => {
        setRefetchData((prev) => !prev);
    };

    const [employees, setEmployees] = useState<Employee[]>([]);

    const [totalHoursWorked, setTotalHoursWorked] = useState("");
    const [totalPaidHours, setTotalPaidHours] = useState("");

    const selectorData = [
        { id: "null", name: "All", value: "all" },
        ...employees.map((employee) => ({
            id: employee.employee_id,
            name: employee.name,
            value: employee.email,
        })),
    ];

    const [tableLoading, setTableLoading] = useState(false);
    const [cardsLoading, setCardsLoading] = useState(false);
    const [clockLoading, setClockLoading] = useState(false);

    const fetchData = async () => {
        setTableLoading(true);
        const data = await fetchClockEvents(
            selectedEmployeeDetails?.employee_id || "all",
            searchParams
        );
        if (data.status === "success") {
            setClockEvents(data.data.clock_events);
            setResults(data.data.results);
        } else {
            showNotification("Failed to fetch data", data.message, "error");
        }
        setTableLoading(false);
    };

    const fetchEmployeesData = async () => {
        setCardsLoading(true);
        const data = await fetchEmployees();
        if (data.status === "success") {
            setEmployees(data.data.employees);
            setTotalHoursWorked(data.data.total_hours);
            setTotalPaidHours(data.data.total_paid_hours);
        } else {
            showNotification("Failed to fetch data", data.message, "error");
        }
        setCardsLoading(false);
    };

    const [selectedEmployee, setSelectedEmployee] = useState<{
        id: string;
        name: string;
        value: string;
    }>({
        id: "0",
        name: "Select an employee",
        value: "",
    });
    const selectedEmployeeDetails = employees.find(
        (employee) => employee.employee_id === selectedEmployee.id
    );

    useEffect(() => {
        if (selectedEmployee.id !== "0") {
            fetchData();
        }
    }, [selectedEmployee]);

    useEffect(() => {
        fetchData();
        fetchEmployeesData();
    }, [refetchData]);

    const handleClockChange = async (employee_id: string) => {
        if (selectedEmployeeDetails) {
            setClockLoading(true);
            if (selectedEmployeeDetails.is_clocked_in) {
                const data = await clockOutEmployee(
                    employee_id,
                    formatSelectorTimeToUTC(
                        selectedHour + " " + selectedPeriod,
                        selectedMinute,
                        selectedDate
                    )
                );
                if (data.status === "success") {
                    handleRefetch();
                    showNotification("Clocked out successfully", "", "success");
                } else {
                    showNotification(
                        "An error occurred",
                        data.message,
                        "error"
                    );
                }
            } else {
                const data = await clockInEmployee(
                    employee_id,
                    formatSelectorTimeToUTC(
                        selectedHour + " " + selectedPeriod,
                        selectedMinute,
                        selectedDate
                    )
                );
                if (data.status === "success") {
                    handleRefetch();
                    showNotification("Clocked in successfully", "", "success");
                } else {
                    showNotification(
                        "An error occurred",
                        data.message,
                        "error"
                    );
                }
            }
            setClockLoading(false);
        }
    };

    const handleDelete = async (event_id: string) => {
        const data = await deleteClockEvent(event_id);
        if (data.status === "success") {
            handleRefetch();
            showNotification("Deleted event", "", "success");
        } else {
            showNotification("Action failed.", data.message, "error");
        }
    };

    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
    const [selectedHour, setSelectedHour] = useState("");
    const [selectedPeriod, setSelectedPeriod] = useState("");
    const [selectedMinute, setSelectedMinute] = useState("");

    const stats = [
        {
            name: "Total hours worked",
            stat: selectedEmployeeDetails
                ? selectedEmployeeDetails.total_hours_worked
                : totalHoursWorked,
            note: `${
                selectedEmployeeDetails
                    ? selectedEmployeeDetails.total_paid_hours
                    : totalPaidHours
            } paid hours`,
            note2: `${
                selectedEmployeeDetails
                    ? (
                          Number(selectedEmployeeDetails.total_hours_worked) -
                          Number(selectedEmployeeDetails.total_paid_hours)
                      ).toFixed(2)
                    : (
                          Number(totalHoursWorked) - Number(totalPaidHours)
                      ).toFixed(2)
            } unpaid hours`,
        },
        {
            name: "Is clocked in",
            stat: selectedEmployeeDetails?.is_clocked_in ? "True" : "False",
        },
        {
            name: "Total days worked",
            stat: clockEvents.length,
            note: `${
                clockEvents.filter((event) => event.is_paid).length
            } paid days`,
            note2: `${
                clockEvents.length -
                clockEvents.filter((event) => event.is_paid).length
            } unpaid days`,
        },
    ];

    const [totalSum, setTotalSum] = useState("");

    const handleUpdatePaymentStatus = async () => {
        const eventIds = selectedClockEvents.map((e) => e.event_id);
        const data = await updateClockEventPaymentStatus(eventIds);
        if (data.status === "success") {
            handleRefetch();
            showNotification("Updated paid status.", "", "success");
        } else {
            showNotification(
                "Failed to update paid status.",
                data.message,
                "error"
            );
        }
    };

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header />

                <div className="flex flex-row items-center justify-between">
                    <span className="text-2xl font-bold">Employees</span>
                </div>

                {!tableLoading && !cardsLoading ? (
                    <>
                        <div className="flex flex-col pt-8">
                            <h1 className="font-semibold">
                                Record your clock in/out
                            </h1>
                            <div className="flex flex-row pt-4 space-x-5">
                                <MediumSelector
                                    selected={selectedEmployee}
                                    setSelected={setSelectedEmployee}
                                    selectorData={selectorData}
                                />
                                {selectedEmployeeDetails && (
                                    <>
                                        <div className="w-44">
                                            <SingleCalendar
                                                selectedDate={selectedDate}
                                                setSelectedDate={
                                                    setSelectedDate
                                                }
                                                label={""}
                                            />
                                        </div>
                                        <TimeSelector
                                            selectedHour={selectedHour}
                                            setSelectedHour={setSelectedHour}
                                            selectedMinute={selectedMinute}
                                            setSelectedMinute={
                                                setSelectedMinute
                                            }
                                            selectedPeriod={selectedPeriod}
                                            setSelectedPeriod={
                                                setSelectedPeriod
                                            }
                                        />
                                        <button
                                            onClick={() =>
                                                handleClockChange(
                                                    selectedEmployeeDetails?.employee_id ||
                                                        ""
                                                )
                                            }
                                            className="py-2 w-24 text-sm text-white bg-accent hover:bg-accenthighlight px-2 rounded-md"
                                        >
                                            {!clockLoading ? (
                                                <>
                                                    {selectedEmployeeDetails?.is_clocked_in
                                                        ? "Clock out"
                                                        : "Clock in"}
                                                </>
                                            ) : (
                                                <LoadingWheel color="white" />
                                            )}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="pb-4">
                            <div className="flex flex-row items-center justify-between pt-10">
                                <h3 className="text-base font-semibold leading-6 text-gray-900">
                                    Statistics for{" "}
                                    {selectedEmployeeDetails
                                        ? selectedEmployeeDetails.name
                                        : "employees"}
                                </h3>
                                {selectedClockEvents.length > 0 && isOwner && (
                                    <ActionButton
                                        label="Mark paid"
                                        handleClick={() =>
                                            handleUpdatePaymentStatus()
                                        }
                                        height={8}
                                    />
                                )}
                            </div>
                            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                                {stats.map((item) => (
                                    <div
                                        key={item.name}
                                        className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 border border-gray-200"
                                    >
                                        <dt className="truncate text-sm font-medium text-gray-500">
                                            {item.name}
                                        </dt>
                                        <div className="flex flex-row items-end justify-between">
                                            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                                {item.stat}
                                            </dd>
                                            {item.note && item.note2 && (
                                                <div>
                                                    <dd className="text-sm tracking-tight text-gray-500">
                                                        {item.note}
                                                    </dd>
                                                    <dd className="text-sm tracking-tight text-gray-500">
                                                        {item.note2}
                                                    </dd>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </dl>
                        </div>
                        <div className="flex flex-row-reverse items-center">
                            {totalSum !== "" && (
                                <span className="text-gray-700 text-sm">
                                    Sum: {totalSum}
                                </span>
                            )}
                        </div>
                        {!tableLoading ? (
                            <EmployeeClockEventsTable
                                clockEvents={clockEvents}
                                results={results}
                                selectedEvents={selectedClockEvents}
                                setSelectedEvents={setSelectedClockEvents}
                                sortLoading={sortLoading}
                                sortConfig={sortConfig}
                                toggleSort={toggleSort}
                                handleRefetch={() => {}}
                                handleDelete={handleDelete}
                                setTotalSum={setTotalSum}
                            />
                        ) : (
                            <div className="flex flex-row items-center justify-center pt-44">
                                <LoadingWheel />
                            </div>
                        )}
                    </>
                ) : (
                    <div className="flex flex-row items-center justify-center pt-44">
                        <LoadingWheel />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AdminEmployeesPage;
