/** @format */
import Cookies from "js-cookie";
import { buildUrl } from "../../../../utils/url/buildUrl";

export const fetchDashboardData = async (date_filter: string) => {
    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/statistics`;
        const params = {
            date_filter: date_filter,
        };

        const url = buildUrl(baseUrl, params);
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",

                Authorization: "Bearer " + Cookies.get("jwt"),
            },
        });

        const data = await response.json();

        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error) } };
    }
};

export const removeNotification = async (
    notificationId: string,
    deleteAll?: boolean
) => {
    try {
        const jsonBody = deleteAll ? { deleteAll: true } : {};
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/notifications/` +
                notificationId,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
                body: JSON.stringify(jsonBody),
            }
        );

        const data = await response.json();

        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error) } };
    }
};

export const fetchNotifications = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/notifications`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error) } };
    }
};
