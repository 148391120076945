/** @format */

import React, { useState, useEffect } from "react";
import {
    editShipmentFrequency,
    fetchAccountDetails,
    inviteTeamMember,
    removeTeamMember,
    updateAccountDetails,
} from "./api/settingsApi";
import Header from "../../../components/header";
import LoadingWheel from "../../../components/loadingWheel";
import AccountData, { ClientAccountData } from "../../../models/accountData";
import { SuccessNotification } from "../../../components/statusNotifications";
import { useNotification } from "../../../utils/notification/notificationContext";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import {
    CheckIcon,
    ChevronUpDownIcon,
    InformationCircleIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import { ClientTeamPermissions } from "../../../models/permissions";
import { getClientType } from "../../../utils/clientTypes/actions";
import { InputNumberField } from "../../../components/inputField";
import { shipmentFrequencyOptions } from "../../../models/data";
import { EmailNotification } from "../../../models/adminDashboard";
import ClientManageNotifications from "./manageEmailNotifications";

const teamMemberOptions = [
    { id: 1, label: "Can edit", value: "can_edit" },
    { id: 2, label: "Can view billing", value: "can_view_billing" },
];

export default function AccountSettings() {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(true);
    const [accountDetails, setAccountDetails] = useState<AccountData | null>(
        null
    );
    const [sectionLoading, setSectionLoading] = useState("");

    const [formValues, setFormValues] = useState<AccountData | null>(null);

    const [teamMembers, setTeamMembers] = useState<ClientAccountData[]>([]);
    const [invitedEmail, setInvitedEmail] = useState("");

    const [shipmentFrequency, setShipmentFrequency] = useState(
        shipmentFrequencyOptions[0]
    );
    const [minThreshold, setMinThreshold] = useState("");

    const [emailNotifications, setEmailNotifications] = useState<
        EmailNotification[]
    >([]);

    const [refetchData, setRefetchData] = useState(false);
    const handleRefetch = () => {
        setRefetchData((prev) => !prev);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAccountDetails();
            if (data.status === "success") {
                setAccountDetails(data.data.account); // Assuming the entire data object is the dictionary
                setFormValues(data.data.account);
                setTeamMembers(data.data.team_members);
                setEmailNotifications(data.data.email_notifications);

                const matchingShipmentFrequency = shipmentFrequencyOptions.find(
                    (option) =>
                        option.value ===
                        data.data.account.fba_shipment_frequency
                );

                setShipmentFrequency(
                    matchingShipmentFrequency || shipmentFrequencyOptions[0]
                );
                if (data.data.account.min_unit_threshold) {
                    setMinThreshold(
                        data.data.account.min_unit_threshold.toString()
                    );
                }

                setLoading(false);
            }
        };
        fetchData();
    }, [refetchData]);

    const handleUpdateAccountDetails = async (section: string) => {
        if (formValues) {
            const updatedAccountDetails = {
                ...accountDetails,
                ...formValues,
            };

            setSectionLoading(section);
            const { success, message } = await updateAccountDetails(
                section,
                updatedAccountDetails
            );
            if (success) {
                setAccountDetails(updatedAccountDetails);
                showNotification("Updated account details", "", "success");
            } else {
                if (section === "personal") {
                    handleResetPersonalInfo();
                } else {
                    handleResetIntegrations();
                }
                showNotification("Error updating details", message, "error");
            }
            setSectionLoading("");
        }
    };

    const [selectedTeamPermissions, setSelectedTeamPermissions] =
        useState<ClientTeamPermissions>({
            can_edit: false,
            can_view_billing: false,
        });

    const [addMemberLoading, setAddMemberLoading] = useState(false);
    const handleAddTeamMember = async () => {
        setAddMemberLoading(true);
        const data = await inviteTeamMember(
            invitedEmail,
            selectedTeamPermissions
        );
        if (data.status === "success") {
            setTeamMembers((prev) => [
                ...prev,
                {
                    user_id: "",
                    email: invitedEmail,
                    permissions: { can_edit: false, can_view_billing: false },
                    status: "pending",
                },
            ]);
            showNotification("Invited user", "", "success");
            setInvitedEmail("");
            setSelectedTeamPermissions({
                can_edit: false,
                can_view_billing: false,
            });
        } else {
            showNotification("Failed to invite user", data.message, "error");
        }
        setAddMemberLoading(false);
    };

    const [teamMembersLoading, setTeamMembersLoading] = useState<string[]>([]);
    const handleRemoveTeamMember = async (team_member_email: string) => {
        setTeamMembersLoading((prev) => [...prev, team_member_email]);
        const data = await removeTeamMember(team_member_email);
        if (data.status === "success") {
            setTeamMembers((prevTeamMembers) =>
                prevTeamMembers.filter(
                    (member) => member.email !== team_member_email
                )
            );
            showNotification("Removed user", "", "success");
        } else {
            showNotification("Failed to remove user", data.message, "error");
        }
        setTeamMembersLoading((prev) =>
            prev.filter((email) => email !== team_member_email)
        );
    };

    const [frequencyLoading, setFrequencyLoading] = useState(false);
    const handleShipmentsFrequency = async () => {
        setFrequencyLoading(true);
        const data = await editShipmentFrequency(
            shipmentFrequency,
            minThreshold
        );
        if (data.status === "success") {
            showNotification("Updated shipment frequency", "", "success");
        } else {
            showNotification("Failed to update value", data.message, "error");
        }
        setFrequencyLoading(false);
    };

    const getActivePermissions = () => {
        return teamMemberOptions
            .filter(
                (option) =>
                    selectedTeamPermissions[
                        option.value as keyof ClientTeamPermissions
                    ]
            )
            .map((option) => option.label)
            .join(", ");
    };

    const togglePermission = (permission: keyof ClientTeamPermissions) => {
        setSelectedTeamPermissions((prev) => ({
            ...prev,
            [permission]: !prev[permission], // Toggle the current value
        }));
    };

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues!,
            [name]: value,
        }));
    };

    if (loading) {
        return (
            <div className="flex flex-grow items-center h-screen justify-center pb-64">
                <LoadingWheel />
            </div>
        );
    }

    if (!accountDetails) {
        return <div>Account details not found</div>;
    }

    // Handle reset for personal information form
    const handleResetPersonalInfo = () => {
        setFormValues((prevValues) => ({
            ...prevValues!,
            first_name: accountDetails.first_name,
            last_name: accountDetails.last_name,
            email: accountDetails.email,
            phone_number: accountDetails.phone_number,
        }));
    };

    // Handle reset for integrations form
    const handleResetIntegrations = () => {
        setFormValues((prevValues) => ({
            ...prevValues!,
            amazon_store_name: accountDetails.amazon_store_name,
            inventory_labs_email: accountDetails.inventory_labs_email,
            inventory_labs_password: accountDetails.inventory_labs_password,
            boxem_email: accountDetails.boxem_email,
            boxem_password: accountDetails.boxem_password,
        }));
    };

    return (
        <div className="flex flex-col sm:px-10 px-4 flex-grow pb-20">
            <Header />
            <span className="text-2xl font-bold pt-10">Account Settings</span>
            {getClientType() === "client_team_member" && (
                <div className="pt-10">
                    <div className="rounded-md bg-blue-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <InformationCircleIcon
                                    aria-hidden="true"
                                    className="h-5 w-5 text-blue-400"
                                />
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                <p className="text-sm text-blue-700">
                                    You are a team member to this account. To
                                    make any updates, contact your team owner.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="space-y-10 divide-y divide-gray-900/10 py-10">
                <div className="grid grid-cols-1 gap-x-8 gap-y-8">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Personal Information
                        </h2>
                    </div>

                    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-4 py-6 sm:p-8">
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        First name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="first-name"
                                            name="first_name"
                                            type="text"
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={formValues?.first_name || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="last-name"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Last name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="last-name"
                                            name="last_name"
                                            type="text"
                                            autoComplete="family-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={formValues?.last_name || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-full">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            disabled
                                            type="email"
                                            autoComplete="email"
                                            className="disabled:bg-gray-100 disabled:text-gray-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={formValues?.email || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-full">
                                    <label
                                        htmlFor="phone"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Phone number
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="phone"
                                            name="phone_number"
                                            type="tel"
                                            autoComplete="phone"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.phone_number || ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button
                                type="button"
                                className="hover:text-gray-700 cursor-pointer text-sm font-semibold leading-6 text-gray-900"
                                onClick={handleResetPersonalInfo} // Reset personal info form
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    handleUpdateAccountDetails("personal");
                                }}
                                className="h-10 cursor-pointer rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                            >
                                {sectionLoading === "personal" ? (
                                    <div className="flex flex-row items-center space-x-1">
                                        <LoadingWheel color="white" />{" "}
                                        <span>Save</span>
                                    </div>
                                ) : (
                                    <span>Save</span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Integrations
                        </h2>
                    </div>

                    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-4 py-6 sm:p-8">
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-full">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Amazon store name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="amazon-store-name"
                                            name="amazon_store_name"
                                            type="text"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.amazon_store_name ||
                                                ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-full">
                                    <label
                                        htmlFor="inventory-labs-email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Inventory Labs Credentials
                                    </label>
                                    <div className="mt-2 flex flex-row items-center space-x-10">
                                        <input
                                            id="inventory-labs-email"
                                            name="inventory_labs_email"
                                            type="email"
                                            autoComplete="email"
                                            placeholder="Email"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.inventory_labs_email ||
                                                ""
                                            }
                                            onChange={handleInputChange}
                                        />

                                        <input
                                            id="inventory-labs-password"
                                            name="inventory_labs_password"
                                            type="text"
                                            placeholder="Password"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.inventory_labs_password ||
                                                ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-full">
                                    <label
                                        htmlFor="boxem-email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Boxem email
                                    </label>
                                    <div className="mt-2 flex flex-row items-center space-x-10">
                                        <input
                                            id="boxem-email"
                                            name="boxem_email"
                                            type="email"
                                            autoComplete="email"
                                            placeholder="Email"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.boxem_email || ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                        <input
                                            id="boxem-password"
                                            name="boxem_password"
                                            type="text"
                                            placeholder="Password"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            value={
                                                formValues?.boxem_password || ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button
                                type="button"
                                className="hover:text-color-gray-700 cursor-pointer text-sm font-semibold leading-6 text-gray-900"
                                onClick={handleResetIntegrations} // Reset integrations form
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    handleUpdateAccountDetails("integrations");
                                }}
                                className="h-10 cursor-pointer rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                            >
                                {sectionLoading === "integrations" ? (
                                    <div className="flex flex-row items-center space-x-1">
                                        <LoadingWheel color="white" />{" "}
                                        <span>Save</span>
                                    </div>
                                ) : (
                                    <span>Save</span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>

                <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Shipments Settings
                        </h2>
                    </div>

                    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-4 py-6 sm:p-8">
                            <div className="flex flex-row items-end space-x-5">
                                <div className="w-96">
                                    <Listbox
                                        value={shipmentFrequency}
                                        onChange={setShipmentFrequency}
                                    >
                                        <span className="font-medium text-sm">
                                            FBA Shipment Frequency
                                        </span>
                                        <div className="relative pt-0.5">
                                            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                                                <span className="block truncate">
                                                    {shipmentFrequency.label}
                                                </span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon
                                                        aria-hidden="true"
                                                        className="h-5 w-5 text-gray-400"
                                                    />
                                                </span>
                                            </ListboxButton>

                                            <ListboxOptions
                                                transition
                                                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                            >
                                                {shipmentFrequencyOptions.map(
                                                    (option) => (
                                                        <ListboxOption
                                                            key={option.id}
                                                            value={option}
                                                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-accent data-[focus]:text-white"
                                                        >
                                                            <div>
                                                                <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                                                    {
                                                                        option.label
                                                                    }
                                                                </span>
                                                                <p className="text-sm ">
                                                                    {
                                                                        option.description
                                                                    }
                                                                </p>
                                                            </div>

                                                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-accent group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                                <CheckIcon
                                                                    aria-hidden="true"
                                                                    className="h-5 w-5"
                                                                />
                                                            </span>
                                                        </ListboxOption>
                                                    )
                                                )}
                                            </ListboxOptions>
                                        </div>
                                    </Listbox>
                                </div>
                                {shipmentFrequency.id === 3 && (
                                    <div className="w-44">
                                        <InputNumberField
                                            label="Min unit threshold"
                                            value={minThreshold}
                                            onChange={(e) =>
                                                setMinThreshold(e.target.value)
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button
                                type="button"
                                onClick={() => {
                                    handleShipmentsFrequency();
                                }}
                                className="h-10 cursor-pointer rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                            >
                                {frequencyLoading ? (
                                    <div className="flex flex-row items-center space-x-1">
                                        <LoadingWheel color="white" />{" "}
                                        <span>Save</span>
                                    </div>
                                ) : (
                                    <span>Save</span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>

                <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Team
                        </h2>
                    </div>

                    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-4 py-6 sm:p-8">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Invite a team member
                            </h2>
                            <div className="flex flex-row items-center mt-2 space-x-3">
                                <input
                                    type="text"
                                    placeholder="Email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    value={invitedEmail}
                                    onChange={(e) =>
                                        setInvitedEmail(e.target.value)
                                    }
                                    disabled={addMemberLoading}
                                />
                                <Listbox
                                    value={selectedTeamPermissions}
                                    onChange={setSelectedTeamPermissions}
                                >
                                    <div className="relative w-full">
                                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                                            <span className="block truncate">
                                                {/* {selectedTeamOption.label} */}
                                                Select permissions:{" "}
                                                {getActivePermissions()}
                                            </span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon
                                                    aria-hidden="true"
                                                    className="h-5 w-5 text-gray-400"
                                                />
                                            </span>
                                        </ListboxButton>

                                        <ListboxOptions
                                            transition
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white border border-gray-300 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                        >
                                            {teamMemberOptions &&
                                                teamMemberOptions.map(
                                                    (option) => (
                                                        <ListboxOption
                                                            key={option.id}
                                                            value={option}
                                                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                                                        >
                                                            <div className="flex flex-row items-center space-x-2">
                                                                <input
                                                                    id={
                                                                        option.value
                                                                    }
                                                                    type="checkbox"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        togglePermission(
                                                                            option.value as keyof ClientTeamPermissions
                                                                        );
                                                                    }}
                                                                    checked={
                                                                        selectedTeamPermissions[
                                                                            option.value as keyof ClientTeamPermissions
                                                                        ]
                                                                    }
                                                                    className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0"
                                                                />
                                                                <span className="block truncate font-normal">
                                                                    {
                                                                        option.label
                                                                    }
                                                                </span>
                                                            </div>
                                                        </ListboxOption>
                                                    )
                                                )}
                                        </ListboxOptions>
                                    </div>
                                </Listbox>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleAddTeamMember();
                                    }}
                                    disabled={addMemberLoading}
                                    className="h-9 whitespace-nowrap cursor-pointer rounded-md bg-accent px-3 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                                >
                                    {addMemberLoading ? (
                                        <div className="flex flex-row items-center space-x-3">
                                            <LoadingWheel color="white" />{" "}
                                            <span>Invite member</span>
                                        </div>
                                    ) : (
                                        <span>Invite member</span>
                                    )}
                                </button>
                            </div>
                            <div className="pt-7">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                    Team members
                                </h2>
                                {teamMembers.map((member) => (
                                    <div className="flex flex-row items-center pb-2">
                                        <div className="flex flex-col min-w-80">
                                            <span>{member.email}</span>
                                            {member.status === "accepted" ? (
                                                <div className="flex flex-row items-center space-x-4">
                                                    <span className="text-sm text-gray-600">
                                                        {member.permissions
                                                            .can_edit
                                                            ? "Can edit"
                                                            : "Can not edit"}
                                                    </span>
                                                    <span className="text-sm text-gray-600">
                                                        {member.permissions
                                                            .can_view_billing
                                                            ? "Can view billing"
                                                            : "Can not view billing"}
                                                    </span>
                                                </div>
                                            ) : (
                                                <span className="text-sm text-gray-600">
                                                    Invitation pending
                                                </span>
                                            )}
                                        </div>
                                        {!teamMembersLoading.includes(
                                            member.email
                                        ) ? (
                                            <TrashIcon
                                                onClick={() =>
                                                    handleRemoveTeamMember(
                                                        member.email
                                                    )
                                                }
                                                className="w-5 h-5 text-red-500"
                                            />
                                        ) : (
                                            <LoadingWheel />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </form>
                </div>

                <ClientManageNotifications
                    emailNotifications={emailNotifications}
                    setEmailNotifications={setEmailNotifications}
                    handleRefetch={handleRefetch}
                />
            </div>
        </div>
    );
}
